import React, {
  FC, PropsWithChildren, createContext, useContext, useMemo,
} from 'react';
import { noop } from 'lodash';
import useMyAds from '../my-ads-page/use-my-ads.hook';

interface IMyInactiveAdsPageContext {
  activateAd: (id: number) => void;
}
export const MyInactiveAdsPageContext = createContext<IMyInactiveAdsPageContext>({
  activateAd: noop,
});
export const useMyInactiveAdsPageContext = () => useContext(MyInactiveAdsPageContext);

export const MyInactiveAdsPageProvider: FC<PropsWithChildren> = ({ children }) => {
  const { activateMyAd } = useMyAds();

  const activateAd = async (id: number) => {
    await activateMyAd(id);
  };

  const value = useMemo<IMyInactiveAdsPageContext>(() => ({
    activateAd,
  }), [activateAd]);

  return (
    <MyInactiveAdsPageContext.Provider value={value}>
      {children}
    </MyInactiveAdsPageContext.Provider>
  );
};

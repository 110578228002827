export const getRussianNameBySlug = (slug) => {
  const subcategoryMapping = {
    auto: 'Автомобили',
    moto: 'Мотоциклы и мототехника',
    specialvehicle: 'Грузовики и спецтехника',
    'water-vehicle': 'Водный транспорт',
    'air-vehicle': 'Воздушный транспорт',
    vacancy: 'Ищу работу',
    beauty: 'Красота и здоровье Красота и здоровье',
    Accessories: 'Аксессуары Аксессуары',

    resume: 'Ищу сотрудника',
    logistic: 'Грузоперевозки',
    building: 'Строительство',
    education: 'Обучение и курсы',
    domestic: 'Бытовые услуги',
    handyworker: 'Мастер на час',
    'business-services': 'Деловые услуги',
    holidays: 'Праздники, мероприятия',
    'passenger-transportation': 'Пассажирские перевозки',
    gardening: 'Сад, благоустройство',
    'communications-services': 'IT, интернет, телеком',
    security: 'Охрана, безопасность',
    loader: 'Грузчики, складские услуги',
    'computer-support': 'Компьютерная помощь',
    'advertising-printing': 'Реклама, полиграфия',
    'photo-video': 'Фото и видеосъемка',
    wrecker: 'Услуги эвакуатора',
    technician: 'Установка техники',
    art: 'Искусство',
    nannies: 'Няни, сиделки',
    cleaning: 'Уборка',

    'nimal-car': 'Уход за животными',
    other: 'Другое',
    other: 'Другое Ремонт и строительство',
    other: 'Другое Билеты и путешествия',
    other: 'Другое Мебель и интерьер',
    other: 'Другое  Ремонт и отделка',
    other: 'Другое Бытовая техника',
    other: 'Другое Строительство',
    other: 'Другое Сад, благоустройство',
    other: 'Другое Красота, здоровье',
    other: 'Другое Компьютерная помощь',
    other: 'Другое Оборудование, производство',
    other: 'Другое Обучение и курсы',
    other: 'Другое Деловые услуги',
    other: 'Другое IT, интернет, телеком',
    other: 'Другое Уборка',
    other: 'Другое Аудио и видео',
    // other: 'Другое Ремонт и обслуживание техники',
    other: 'Другое Ремонт и обслуживание техники',
    other: 'Другое Бытовая техника',
    other: 'Другое Бытовая техника',
    other: 'Другое Бытовая техника',
    other: 'Другое Бытовая техника',
    /// /////////////////////////////////////////////////////////////

    it: 'IT Резюме',
    'Car-business': 'Автомобильный бизнес Резюме',
    'Administrative-work': 'Административная работа Резюме',
    Without: 'Без опыта Резюме',
    Accounting: 'Бухгалтерия, финансы Резюме',
    Household: 'Домашний персонал Резюме',
    entertainment: 'Искусство, развлечения Резюме',
    Consulting: 'Консультирование Резюме',
    PR: 'Маркетинг реклама, PR Резюме',
    Education: 'Образование, наука Резюме',
    Security: 'Охрана, безопасность Резюме',
    Sales: 'Продажи Резюме',
    Tourism: 'Туризм, рестораны Резюме',
    Personnel: 'Управление персоналом Резюме',
    'Fitness,beautysalons': 'Фитнес, салоны красоты Резюме',
    Jurisprudence: 'Юриспруденция Резюме',
    Other: 'Другое Резюме',
    Other: 'Другое Игры, приставки и программы',
    Other: 'Другое Фототехника',

    clothes: 'Одежда',
    shoes: 'Обувь',
    // accessories: 'Аксессуары',

    'childrens-clothing': 'Детская одежда и обувь',
    'childrens-goods': 'Товары для детей и игрушки',
    'audio-video': 'Аудио и видео',
    jewelry: 'Часы и украшения',
    repair: 'Ремонт и строительство' || 'Ремонт и отделка',
    furniture: 'Мебель и интерьер',
    appliances: 'Бытовая техника',
    food: 'Продукты питания',
    plants: 'Растения',
    kitchenware: 'Посуда и товары для кухни',
    parts: 'Запчасти',
    tools: 'Инструменты',
    tires: 'Шины, диски и колёса',
    trailers: 'Прицепы',
    accessories: 'Аксессуары',
    oils: 'Масла и автохимия',
    tuning: 'Тюнинг',
    gps: 'GPS-навигаторы',
    luggage: 'Багажники и фаркопы',
    phones: 'Телефоны',
    photo: 'Фототехника',
    'audio-video': 'Аудио и видео',
    'audio-video': 'Аудио- и видеотехника',
    'tablets-ebooks': 'Планшеты и электронные книги',
    'computer-goods': 'Товары для компьютера',
    consumables: 'Оргтехника и расходники',
    consoles: 'Игры, приставки и программы',
    notebooks: 'Ноутбуки',
    desktops: 'Настольные компьютеры',
    tickets: 'Билеты и путешествия',
    sports: 'Спорт и отдых',
    bikes: 'Велосипеды',
    Accessories12: 'Аксессуары Запчасти и аксессуары',
    books: 'Книги и журналы',
    collecting: 'Коллекционирование',
    Other: 'Другое Товары для компьютера',
    'musical-instruments': 'Музыкальные инструменты',
    'hunting-fishing': 'Охота и рыбалка',
    dogs: 'Собаки',
    cats: 'Кошки',
    birds: 'Птицы',
    aquarium: 'Аквариум',
    'other-pets': 'Другие животные',
    'pet-supplies': 'Товары для животных',
    'ready-business': 'Готовый бизнес',
    equipment:
      'Оборудование для бизнеса'
      || 'Экипировка'
      || 'Оборудование и производство',
    buy: 'Купить жильё',
    'rent-daily': 'Снять посуточно',
    'rent-longterm': 'Снять долгосрочно',
    commercial: 'Коммерческая недвижимость',
    'mens-clothing': 'Мужская одежда',
    'womens-clothing': 'Женская одежда',
    'womens-shoes': 'Женская обувь',
    'mens-shoes': 'Мужская обувь',
    Beauty_health: 'Красота, здоровье',
    beauty: 'Красота и здоровье Красота и здоровье',
  };

  return subcategoryMapping[slug] || slug;
};

export const getRussianCategoryBySlug = (slug) => {
  const categoryPaths = {
    transport: 'Транспорт',
    job: 'Работа',
    services: 'Услуги',
    personal: 'Личные вещи',
    'at-home': 'Для дома',
    'spare-parts': 'Запчасти и аксессуары',
    electronics: 'Электроника',
    hobbies: 'Хобби и отдых',
    animals: 'Животные',
    business: 'Бизнес и оборудование',
    property: 'Недвижимость',
  };

  return categoryPaths[slug] || slug;
};

export const getRussianUnderNameBySlug = (slug) => {
  const undersubcategoryMapping = {
    rental: 'Аренда оборудования',
    'for-boys': 'Для мальчиков',
    'for-girls': 'Для девочек',
    'installation-maintenance': 'Монтаж и обслуживание оборудования',
    tvs: 'Телевизоры',
    'mobile-devices': 'Мобильные устройства',
    'photo-audio-video': 'Фото, аудио, видеотехника',
    'air-conditioning-ventilation': 'Кондиционеры, вентиляция',
    'washing-drying-machines': 'Стиральные, сушильные машины',
    dishwashers: 'Посудомоечные машины',
    'refrigerators-freezers': 'Холодильники, морозильные камеры',
    'cooktops-ovens': 'Варочные панели, духовые шкафы',
    'water-heaters': 'Водонагреватели',
    'coffee-machines': 'Кофемашины',
    'sewing-machines-overlocks': 'Швейные машины, оверлоки',
    beauty: 'Красота и здоровье Красота и здоровье',
    'mens-clothing': 'Мужская одежда',
    'womens-clothing': 'Женская одежда',
    'mens-shoes': 'Мужская обувь',
    'womens-shoes': 'Женская обувь',
    jewelry: 'Ювелирные украшения',
    watches: 'Часы',
    hats: 'Головные уборы',
    glasses: 'Очки',
    'belts-belts-suspenders': 'Ремни, пояса, подтяжки',
    'umbrellas-and-raincoats': 'Зонты и дождевики',
    'hair-accessories': 'Аксессуары для волос',
    'ties-and-bow-ties': 'Галстуки и бабочки',
    'socks-stockings-tights': 'Носки, чулки, колготки',
    'loafers-espadrilles': 'Слипоны и эспадрильи',
    other: 'Другое',
    fountains: 'Водоемы и фонтаны',
    construction: 'Дорожное строительство',
    canopies: 'Заборы, ограждения, навесы',
    excavation: 'Земляные работы',
    Landscaping: 'Озеленение, уход за садом и огородом',
    shutters: 'Рольставни и ворота',
    // Ремонт квартир и домов под ключ
    renovation: 'Ремонт квартир и домов под ключ',
    Plumbing: 'Сантехника',
    Electrics: 'Электрика',
    furniture: 'Сборка и ремонт мебели',
    painting: 'Поклейка обоев и малярные работы',
    coatings: 'Полы и напольные покрытия',
    Plastering: 'Штукатурные работы',
    'High-rise': 'Высотные работы',
    forged: 'Металлоконструкции и кованные изделия',
    Insulation: 'Изоляция',
    office: 'Ремонт офиса',
    Ventilation: 'Вентиляция',
    Other: 'Другое',

    // услуги Строительство
    Other: 'Другое',
    construction: 'Строительство домов под ключ',
    Masonrywork: 'Кладочные работы',
    Roofing: 'Кровельные работы',
    Weldingwork: 'Сварочные работы',
    Concrete: 'Бетонные работы',
    Fundamental: 'Фундаментальные работы',
    dismantling: 'Снос и демонтаж',
    Facade: 'Фасадные работы',
    Design: 'Проектирование и сметы',

    // услуги Сад, благоустройство
    fountains: 'Водоемы и фонтаны',
    construction: 'Дорожное строительство',
    canopies: 'Заборы, ограждения, навесы',
    excavation: 'Земляные работы',
    Landscaping: 'Озеленение, уход за садом и огородом',
    shutters: 'Рольставни и ворота',
    Other: 'Другое',

    // услуги Ремонт и обслуживание техники
    tvs: 'Телевизоры',
    mobile: 'Мобильные устройства',
    Photo: 'Фото, аудио, видеотехника',
    conditioners: 'Кондиционеры, вентиляция',
    Washing: 'Стиральные, сушильные машины',
    Dishwashers: 'Посудомоечные машины',
    refrigerators: 'Холодильники, морозильные камеры',
    cooktops: 'Варочные панели, духовые шкафы',
    Waterheaters: 'Водонагреватели',
    Coffeemachines: 'Кофемашины',
    Sewing: 'Швейные машины, оверлоки',
    Other: 'Другое',

    // услуги Компьютерная помощь
    Computers: 'Компьютеры',
    Printers: 'Принтеры',
    'game-1111consoles': 'Игровые приставки',
    programs: 'ОС и программы',
    Internet: 'Интернет и другие сети',
    Other: 'Другое',

    // услуги Оборудование, производство
    // услуги Деловые услуги
    finance: 'Бухгалтерия, финансы Деловые услуги',
    Translation: 'Перевод',
    Insurance: 'Страхование',
    Legal: 'Юридические услуги',
    Other: 'Другое',

    // услуги IT, маркетинг
    // услуги Реклама, полиграфия
    Marketing: 'Маркетинг, реклама, PR',
    Printing: 'Полиграфия, дизайн',

    // услуги Уборка
    'spring-cleaning': 'Генеральная уборка',
    Disinfection: 'Дезинфекция, дезинсекция',
    Window: 'Мойка окон',
    Easy: 'Простая уборка',
    renovation: 'Уборка после ремонта',
    Carpet: 'Чистка ковров',
    upholstered: 'Чиста мягкой мебели',
    Other: 'Другое',

    // услуги Бытовые услуги
    Production: 'Изготовление ключей',
    Tailoring: 'Пошив и ремонт одежды',
    Watch: 'Ремонт часов',
    washing: 'Химчистка, стирка',
    Jewelry: 'Ювелирные услуги',
    Other: 'Другое',

    // Недвижимость Купить
    all: 'Все',
    Secondary: 'Вторичка',
    'New-building': 'Новостройка',

    // Работа Резюме
    // Хобби и отдых Билеты и путешествия
    Concerts: 'Концерты',
    Trips: 'Путешествия',
    Sport: 'Спорт',

    // Хобби и отдых Спорт и отдых
    Billiards: 'Бильярд и боулинг',
    Diving: 'Дайвинг и водный спорт',
    Unity: 'Единоброства',
    'Ball-games': 'Игры с мячом',
    Board: 'Настольные игры',
    Rollerblading: 'Ролики и скейтбординг',
    Tennis: 'Теннис, бадминтон, пинг-понг',
    Tourism: 'Туризм',
    Fitness: 'Фитнес и тренажеры',
    nutrition: 'Спортивное питание',
    Other: 'Другое',

    // Электроника Телефоны
    Cell: 'Мобильные телефоны',
    Accessories: 'Аксессуары',

    // Электроника Аудио и видео
    projects: 'Телевизоры и проекторы',
    Headphones: 'Наушники',
    Acoustics: 'Акустика, колонки, сабвуферы',
    other: 'Другое',

    // Электроника Товары для компьютера
    'Accessories-computer': 'Комплектующие',
    Monitors: 'Мониторы',
    Keyboards: 'Клавиатуры и мыши',
    Other: 'Другое Настольные компьютеры',

    // Электроника Игры, приставки и программы
    Gaming: 'Игровые приставки',
    'Console-games': 'Игры для приставок',
    Computer: 'Компьютерные игры',
    Other: 'Другое',

    // Электроника Настольные компьютеры
    System: 'Системные блоки',
    Monoblocks: 'Моноблоки',
    Other: 'Другое',

    // Электроника Фототехника
    Equipment: 'Оборудование и аксессуары',
    Lenses: 'Объективы',
    Compact: 'Компактные фотоаппараты',
    Film: 'Пленочные фотоаппараты',
    Cameras: 'Зеркальные фотоаппараты',
    Binoculars: 'Бинокли и телескопы',
    Other: 'Другое',

    // Электроника Планшеты и электронные книги
    Tablets: 'Планшеты',
    Accessories: 'Аксессуары',
    'E-books': 'Электронные книги',
    jewelry: 'Ювелирные украшения',

    // Работа Резюме summary
    it: 'IT',
    'Car-business': 'Автомобильный бизнес',
    'Administrative-work': 'Административная работа',
    Without: 'Без опыта',
    Accounting: 'Бухгалтерия, финансы',
    Household: 'Домашний персонал',
    entertainment: 'Искусство, развлечения',
    Consulting: 'Консультирование',
    PR: 'Маркетинг реклама, PR',
    Education: 'Образование, наука',
    Security: 'Охрана, безопасность',
    Sales: 'Продажи',
    Tourism: 'Туризм, рестораны',
    Personnel: 'Управление персоналом',
    'Fitness,beautysalons': 'Фитнес, салоны красоты',
    Jurisprudence: 'Юриспруденция',
    Other: 'Другое',

    // Работа Вакансии vacancies
    it: 'IT',
    'Car-business': 'Автомобильный бизнес',
    'Administrative-work': 'Административная работа',
    Without: 'Без опыта',
    Accounting: 'Бухгалтерия, финансы',
    Household: 'Домашний персонал',
    entertainment: 'Искусство, развлечения',
    Consulting: 'Консультирование',
    PR: 'Маркетинг реклама, PR',
    Education: 'Образование, наука',
    Security: 'Охрана, безопасность',
    Sales: 'Продажи',
    Tourism: 'Туризм, рестораны',
    Personnel: 'Управление персоналом',
    'Fitness,beautysalons': 'Фитнес, салоны красоты',
    Jurisprudence: 'Юриспруденция',
    Other: 'Другое',

    // Для дома Ремонт и строительство
    Construction: 'Стройматериалы',
    Tools: 'Инструменты',
    'water-supply': 'Сантехника, водоснабжение и сауна',
    Doors: 'Двери',
    Garden: 'Садовая техника',
    Other: 'Другое',
    'mens-clothing': 'Мужская одежда',
    'womens-clothing': 'Женская одежда',
    'womens-shoes': 'Женская обувь',
    'mens-shoes': 'Мужская обувь',

    shoes: 'Туфли Мужская обувь',
    shoes: 'Туфли',
    sandals: 'Босоножки',
    'boots-and-low-shoes': 'Ботинки и полуботинки',
    'Sneakers-and-Canvas-Shoes': 'Кроссовки и кеды',
    'ballcitieset-shoes': 'Балетки',
    'clogs-and-mules': 'Сабо и мюли',
    'rubber-shoes': 'Резиновая обувь',
    'moccasins-and-loafers': 'Мокасины и лоферы Мужская обувь',
    sandals: 'Сандалии',
    'flip-flops-and-slates': 'Шлёпанцы и сланцы',
    'flip-flops-and-slates': 'Шлёпанцы и сланцы Мужская обувь',
    slippers: 'Домашняя обувь',
    slippers: 'Домашняя обувь Мужская обувь',
    'sport-shoes': 'Спортивная обувь',
    'sport-shoes': 'Спортивная обувь Мужская обувь',
    'slip-ons-and-espadrilles': 'Слипоны и эспадрильи',
    'slip-ons-and-espadrilles': 'Слипоны и эспадрильи Мужская обувь',
    'shoe-care': 'Уход за обувью',
    'shoe-care': 'Уход за обувью Уход за обувью Мужская обувь',

    // Женская одежда
    dresses: 'Платья',
    sandals: 'Босоножки',
    'boots-and-low-shoes': 'Ботинки и полуботинки',
    'Sneakers-and-Canvas-Shoes': 'Кроссовки и кеды',
    'ballcitieset-shoes': 'Балетки',
    'clogs-and-mules': 'Сабо и мюли',
    'rubber-shoes': 'Резиновая обувь',
    'moccasins-and-loafers': 'Мокасины и лоферы',
    sandals: 'Сандалии',
    'flip-flops-and-slates': 'Шлёпанцы и сланцы',
    slippers: 'Домашняя обувь',
    'sport-shoes': 'Спортивная обувь',
    'sport-shoes': 'Спортивная обувь Мужская обувь',
    'slip-ons-and-espadrilles': 'Слипоны и эспадрильи',
    'shoe-care': 'Уход за обувью',
    Other: 'Другое',

    // Мужская одежда
    'sweatshirts-and-t-shirts': 'Кофты и футболки',
    'jackets-and-suits': 'Пиджаки и костюмы',
    jeans: 'Джинсы',
    jeans: 'Джинсы Женская одежда',
    trousers: 'Брюки',
    trousers: 'Брюки Женская одежда',
    shirts: 'Рубашки',
    tracksuits: 'Спортивные костюмы',
    shorts: 'Шорты',
    underwear: 'Нижнее бельё',
    underwear: 'Нижнее бельё Женская одежда',
    Other: 'Другое Женская одежда',
    Other: 'Другое Женская обувь',
    Other: 'Другое Мужская одежда',
    Other: 'Другое Мужская обувь',

    // Мужская обувь
    sneakers: 'Кроссовки',
    shoes: 'Туфли',
    shoes: 'Туфли Мужская обувь',
    sneakers: 'Кеды',
    'jackets-and-suits': 'Пиджаки и костюмы Женская одежда',
    'moccasins-and-loafers': 'Мокасины и лоферы Мужская обувь',
    'sport-shoes': 'Спортивная обувь',
    'sport-shoes': 'Спортивная обувь Мужская обувь',
    sandals: 'Сандалии',
    sandals: 'Сандалии Мужская обувь',
    'flip-flops-and-slates': 'Шлёпанцы и сланцы',
    slippers: 'Домашняя обувь',
    'slip-ons-and-espadrilles': 'Слипоны и эспадрильи',
    'shoe-care': 'Уход за обувью',
    Other: 'Другое',
    accessories: 'Аксессуары',
    'Beds-sofas-and-armchairs': 'Кровати, диваны и кресла',
    homes: 'Для дома',
    Lighting: 'Освещение',
    'Wardrobes-chests': 'Шкафы, комоды и стеллажи',
    Serving: 'Сервировка стола',
    'Cooking-food': 'Приготовление пищи',
    'Food-storage': 'Хранение продуктов',
    'Preparing-drinks': 'Приготовление напитков',
    'Household-Goods': 'Хозяйственные товары',
    Kitchenware: 'Кухонные товары',
    'Console-games': 'Игры для приставок',
    Computer: 'Компьютерные игры',
    projects: 'Телевизоры и проекторы',
    Headphones: 'Наушники',
    Acoustics: 'Акустика, колонки, сабвуферы',
    'Foreign-Languages': 'Иностранные языки',
    'child-development-speech': 'Детское развитие, логопеды',
    'IT-Business': 'IT, бизнес',
    'Design-Drawing': 'Дизайн, рисование',
    'Beauty-Health': 'Красота, здоровье',
    'Sports-Dance': 'Спорт, танцы',
    Driving: 'Вождение',
    'Music-Theater': 'Музыка, театр',
    aroundtown: 'По городу',
    betweencities: 'Между городами',
    'Website-Creation-and-Promotion': 'Создание и продвижение сайтов',
    'Software-Installation-and-Configuration': 'Установка и настройка ПО',
    'Internet-and-Network-Configuration': 'Настройка интернета и сетей',
    'Advertising-Marketing-Promotion': 'Реклама, маркетинг, продвижение',
    'Programming-CRM-Configuration': 'Программирование, настройка CRM',
    'Website-and-App-Creation': 'Создание сайтов и приложений',
    Other: 'Другое',
    vacancy: 'Вакансии',
    resume: 'Резюме',
    skirts: 'Юбки',
    Other: 'Другое Товары для детей и игрушки',
    'jumpers-sweaters-cardigans': 'Джемперы, свитеры, кардиганы',
    'wedding-dresses': 'Свадебные платья',
    'shirts-and-blouses': 'Рубашки и блузки',
    'tops-and-t-shirts': 'Топы и футболки',
    swimwear: 'Купальники',
    Fishing: 'Рыбалка',
    'Website-PO': 'Установки и настройка ПО',
    Beauty_health: 'Красота, здоровье',
    beauty: 'Красота и здоровье Красота и здоровье',
    'Manicure-Pedicure': 'Маникюр, педикюр',
    'Hairdresser-Services': 'Услуги, парикмахера',
    'Eyelashes-Eyebrows': 'Ресницы, брови',
    Cosmetology: 'Косметология',
    Epilation: 'Эпиляция',
    'Spa-Massage': 'СПА-услуги, массаж',
    'Tattoo-Piercing': 'Тату, пирсинг',
    Psychology: 'Психология',
    Dietetics: 'Диетология',
    'Workspace-Rental': 'Аренда рабочего места',
    Strollers: 'Детские коляски',
    'Childrens-Furniture': 'Детская мебель',
    'Bicycles-and-Scooters': 'Велосипеды и самокаты',
    'Feeding-Goods': 'Товары для кормления',
    'Car-Seats': 'Автомобильные кресла',
    Toys: 'Игрушки',
    'Bathing-Goods': 'Товары для купания',
    'School-Goods': 'Товары для школы',
    Other: 'Другое',
    'Appliances-and-Accessories': 'Приборы и аксессуары Красота и здоровье',
    Fragrances: 'Парфюмерия Красота и здоровье',
    'Hygiene-Products': 'Средства гигиены Красота и здоровье',
    Cosmetics: 'Косметика Красота и здоровье',
    'Hair-Care-Products': 'Средства для волос Красота и здоровье',
    'Medical-Products': 'Медицинские изделия Красота и здоровье',
    Other: 'Другое Красота и здоровье',
    'Tables-and-Chairs': 'Столы и стулья',
    'Textiles-and-Carpets': 'Текстиль и ковры',
    Lighting: 'Освещение',
    'Computer-Desks-and-Chairs': 'Компьютерные столы и кресла',
    'Wardrobes-chests': 'Шкафы, комоды и стеллажи',
    homes: 'Для дома',
    Kitchen: 'Для кухни',
    'Climate-Control-Equipment': 'Климатические оборудование',
    'Personal-Care': 'Для индивидуального ухода',
  };

  return undersubcategoryMapping[slug] || slug;
};

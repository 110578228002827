import React, { useMemo } from 'react';
import FilterSubCategory from '@/components/uiComp/catalog/FilterSubCategory';
import LocationSubCate from '@/components/uiComp/catalog/LocationSubCate';
import ToggleButtons from '@/components/uiComp/createProduct/ToggleButtons';
import Colors from '@/components/uiComp/createProduct/Colors';
import MoreParametres from '@/components/uiComp/catalog/MoreParametres';
import PriceSlider from '@/components/uiComp/catalog/PriceSlider';
import DescriptionSubCate from '@/components/uiComp/catalog/DescriptionSubCate';
import FilterBtn from '@/components/uiComp/catalog/FilterBtn';
import CheckboxInputGroup from '@/components/uiComp/createProduct/CheckboxInput';
import useTranslation from 'next-translate/useTranslation';
import i18n from '@/lib/i18n';
import { useRouter } from 'next/router';
import { } from '@react-hookz/web';
import { useFilterFormState } from '@/modules/shared/hooks';
import s from './MotoFilters.module.scss';

function MotoFilters({
  category, subcategory, getFilteringData, defaultValue,
}) {
  const { locale } = useRouter();
  const { t } = useTranslation('');
  const [form] = useFilterFormState([
    'color',
    'deposit',
    'rental_type',
    'delivery',
    'child_seat',
    'search',
    'district',
    'engine_capacity',
    'max_price',
    'min_price',
    'city',
  ], defaultValue, getFilteringData);

  const engineCapacityOptions = useMemo(() => ([
    { value: '125', label: '125' },
    { value: '250', label: '250' },
    { value: '300', label: '300' },
    { value: '450', label: '450' },
    { value: '500', label: '500' },
    { value: '600', label: '600' },
    { value: '1000', label: t('common:lessThan', { number: 1000 }) },
  ]), [locale, t]);

  const engineCapacityTitle = i18n.filters.default.engine[locale];

  const handleBtnFiltering = () => {
    getFilteringData(form.query);
  };

  return (
    <section className={s.container}>
      <div className={s.filter_block}>
        <FilterSubCategory category={category} subcategory={subcategory} />
        <LocationSubCate
          selectedDistrict={form.value.district}
          onSelectDistrict={form.setter.district}
          onSelectCity={form.setter.city}
          selectedCity={form.value.city}
        />
        <MoreParametres
          title={i18n.createAdvFilds.default.rentalType[locale]}
          options={[
            i18n.filters.default.hour[locale],
            i18n.filters.default.Daily[locale],
            i18n.filters.default.Long[locale],
          ]}
          selected={form.value.rental_type}
          onSelect={form.setter.rental_type}
        />
        <ToggleButtons
          title={i18n.createAdvFilds.default.depo[locale]}
          selectedStatus={form.value.deposit}
          onSelectStatus={form.setter.deposit}
        />
        <MoreParametres
          title={engineCapacityTitle}
          options={engineCapacityOptions}
          selected={form.value.engine_capacity}
          onSelect={form.setter.engine_capacity}
        />
        <Colors selectedColor={form.value.color} onColorSelect={form.setter.color} />
        <ToggleButtons
          title={i18n.createAdvFilds.default.delivery[locale]}
          selectedStatus={form.value.delivery}
          onSelectStatus={form.setter.delivery}
        />
        <CheckboxInputGroup
          options={[
            {
              label: i18n.createAdvFilds.default.PhoneMount[locale],
              isChecked: form.value.child_seat,
              onChange: () => form.setter.child_seat(!form.value.child_seat),
            },
          ]}
        />
        <PriceSlider
          minPrice={form.value.min_price}
          maxPrice={form.value.max_price}
          setMinPrice={form.setter.min_price}
          setMaxPrice={form.setter.max_price}
        />
        <DescriptionSubCate description={form.value.search} setDescription={form.setter.search} />
        <FilterBtn handleBtnFiltering={handleBtnFiltering} />
      </div>
    </section>
  );
}

export default MotoFilters;

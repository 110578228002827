import React, { useState, useEffect } from 'react';
import CheckboxInputGroup from '@/components/uiComp/createProduct/CheckboxInput';
import { useRouter } from 'next/router';
import s from './CreateAuto.module.scss';
import i18n from '../../../lib/i18n';

export default function Transfers({ onTransfersDataChange, defaultValue }) {
  const [selectedService_type, setSelectedService_type] = useState('');
  const [selectedDrive, setSelectedDrive] = useState('');
  const [selectedVehicle_type, setSelectedVehicle_type] = useState('');
  const [childSeat, setChildSeat] = useState(false);
  const [phoneCharger, setPhoneCharger] = useState(false);
  const [audioSystem, setAudioSystem] = useState(false);
  const { locale } = useRouter();

  useEffect(() => {
    if (defaultValue) {
      setSelectedService_type(defaultValue.service_type);
      setSelectedDrive(defaultValue.drive);
      setSelectedVehicle_type(defaultValue.vehicle_type);
      setChildSeat(defaultValue.child_seat);
      setPhoneCharger(defaultValue.phone_charger);
      setAudioSystem(defaultValue.meeting_with_sign);
    }
  }, [defaultValue]);

  const getCheckboxData = () => ({
    child_seat: childSeat,
    phone_charger: phoneCharger,
    meeting_with_sign: audioSystem,
  });

  useEffect(() => {
    const formData = {
      service_type: selectedService_type,
      drive: selectedDrive,
      vehicle_type: selectedVehicle_type,
      ...getCheckboxData(),
    };

    onTransfersDataChange(formData);
  }, [
    childSeat,
    phoneCharger,
    audioSystem,
    selectedService_type,
    selectedDrive,
    selectedVehicle_type,
  ]);

  return (
    <section className={s.transfers}>
      <CheckboxInputGroup
        title={i18n.createAdvFilds.transfers.transportType[locale]}
        options={[
          {
            label: i18n.createAdvFilds.transfers.auto[locale],
            isChecked: selectedVehicle_type === 'Легковой автомобиль',
            onChange: () => setSelectedVehicle_type('Легковой автомобиль'),
          },
          {
            label: i18n.createAdvFilds.transfers.Minivan[locale],
            isChecked: selectedVehicle_type === 'Минивэн',
            onChange: () => setSelectedVehicle_type('Минивэн'),
          },
          {
            label: i18n.createAdvFilds.transfers.Minibus[locale],
            isChecked: selectedVehicle_type === 'Микроавтобус',
            onChange: () => setSelectedVehicle_type('Микроавтобус'),
          },
          {
            label: i18n.createAdvFilds.transfers.Bus[locale],
            isChecked: selectedVehicle_type === 'Автобус',
            onChange: () => setSelectedVehicle_type('Автобус'),
          },
          {
            label: i18n.createAdvFilds.transfers.other[locale],
            isChecked: selectedVehicle_type === 'Другой',
            onChange: () => setSelectedVehicle_type('Другой'),
          },
        ]}
      />
      <CheckboxInputGroup
        title={i18n.createAdvFilds.transfers.serviceType[locale]}
        options={[
          {
            label: i18n.createAdvFilds.transfers.TransferAndAirport[locale],
            isChecked: selectedService_type === 'Трансфер из аэропорта',
            onChange: () => setSelectedService_type('Трансфер из аэропорта'),
          },
          {
            label: i18n.createAdvFilds.transfers.Comfort[locale],
            isChecked:
              selectedService_type === 'Трансфер класса комфорт/бизнес',
            onChange: () => setSelectedService_type('Трансфер класса комфорт/бизнес'),
          },
          {
            label: i18n.createAdvFilds.transfers.FeedByTime[locale],
            isChecked: selectedService_type === 'Подача по времени',
            onChange: () => setSelectedService_type('Подача по времени'),
          },
        ]}
      />
      <CheckboxInputGroup
        title={i18n.createAdvFilds.transfers.poezdka[locale]}
        options={[
          {
            label: i18n.createAdvFilds.transfers.AroundTown[locale],
            isChecked: selectedDrive === 'По городу',
            onChange: () => setSelectedDrive('По городу'),
          },
          {
            label: i18n.createAdvFilds.transfers.BetweenCities[locale],
            isChecked: selectedDrive === 'Между городами',
            onChange: () => setSelectedDrive('Между городами'),
          },
        ]}
      />
      <CheckboxInputGroup
        options={[
          {
            label: i18n.createAdvFilds.default.Baby[locale],
            isChecked: childSeat,
            onChange: () => setChildSeat(!childSeat),
          },
          {
            label: i18n.createAdvFilds.default.Charger[locale],
            isChecked: phoneCharger,
            onChange: () => setPhoneCharger(!phoneCharger),
          },
          {
            label: i18n.createAdvFilds.default.Audio[locale],
            isChecked: audioSystem,
            onChange: () => setAudioSystem(!audioSystem),
          },
        ]}
      />
    </section>
  );
}

import { useState, useEffect } from 'react';
import axios from 'axios';
import useBanned from '../admin/useBanned';

const URL = process.env.NEXT_PUBLIC_API_URL;

export default function useProductData() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios
      .get(`${URL}/api/announcement/list/`)
      .then((response) => {
        setData(response.data.results);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  const { banned } = useBanned();

  return {
    data, setData, loading, error, bannedAdvs: banned,
  };
}

import React from 'react';
import s from '@/styles/uiComp/catalog/DescrSubCate.module.scss';
import i18n from '@/lib/i18n';
import { useRouter } from 'next/router';

function FilterBtn({ handleBtnFiltering }) {
  const { locale } = useRouter();

  return (
    <div className={s.button}>
      <button className={s.filter_btn_subcategory} onClick={handleBtnFiltering}>
        {i18n.filters.default.apply[locale]}
        <img src="/assets/icons/filter_restart.svg" alt="icon_error" />
      </button>
    </div>
  );
}

export default FilterBtn;

import React, { useState } from 'react';
import s from '@/styles/uiComp/TypeRent.module.scss';

function TypeRent({ title, options, onSelect }) {
  const [selectedOption, setSelectedOption] = useState([]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    onSelect(option);
  };

  return (
    <div className={s.more_parameters_subcategory}>
      <h3>{title}</h3>
      <div className={s.parameters_option}>
        {options.map((option, index) => (
          <label key={index}>
            <p>{option}</p>
            <input
              className={s.input}
              type="checkbox"
              checked={selectedOption === option}
              onChange={() => handleOptionChange(option)}
            />
          </label>
        ))}
      </div>
    </div>
  );
}
export default TypeRent;

import React from 'react';
import useCategoriesData from '@/hooks/categories/useCategoriesData';
import DinamicComponent from '@/components/catalog/dinamic/DinamicComponent';

export function DefaultFilter({ onChange, defaultValue }) {
  const { categories } = useCategoriesData();
  const categoryTitles = categories.map((category) => category.category_title);
  return (
    <DinamicComponent
      category={categoryTitles}
      getFilteringData={onChange}
      defaultValue={defaultValue}
    />
  );
}

import Colors from '@/components/uiComp/createProduct/Colors';
import SelectOptions from '@/components/uiComp/createProduct/SelectOptions';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import s from './Things.module.scss';
import i18n from '../../../lib/i18n';

export default function Jewelry({ onJewerlyDataChange, defaultValue }) {
  const [state, setState] = useState('');
  const [color, setColor] = useState('');
  const [brand, setBrand] = useState('');
  const [gender, setGender] = useState('');
  const { locale } = useRouter();
  useEffect(() => {
    if (defaultValue) {
      setState(defaultValue.state);
      setGender(defaultValue.gender);
      setColor(defaultValue.color);
      setBrand(defaultValue.brand);
    }
  }, [defaultValue]);

  useEffect(() => {
    const formData = {
      state,
      color,
      gender,
      brand,
    };
    onJewerlyDataChange(formData);
  }, [state, color, brand, gender]);

  const genders = [i18n.createAdvFilds.things.Male[locale], i18n.createAdvFilds.things.Female[locale], i18n.createAdvFilds.things.Unisex[locale]];
  const states = [i18n.createAdvFilds.default.Excellent[locale], i18n.createAdvFilds.default.Good[locale], i18n.createAdvFilds.default.Satisfactory[locale]];
  const luxuryBrands = [
    'Agatha Paris',
    'Andres Sarda',
    'Asprey',
    'Atelier de Famille',
    'Audemars Piguet',
    'Au Printemps Paris',
    'Baccarat',
    'Baume & Mercier',
    'Blancpain',
    'Bordelle',
    'Breitling',
    'Breguet',
    'Buccellati',
    'Cadolle',
    'Cartier',
    'Chanel',
    'Chantal Thomass',
    'Chopard',
    'Confidences',
    'Dinh Van',
    'David Morris',
    'David Webb',
    'De Beers',
    'Eres',
    'Fifi Chachnil',
    'Garrard',
    'Gèdre',
    'Glashütte Original',
    'Graff',
    'Graff Diamonds',
    'Gübelin',
    'Harry Winston',
    'Hermès',
    'I.D. Sarrieri',
    'IWC Schaffhausen',
    'Irène Van Ryb',
    'Jaeger-LeCoultre',
    'Jaquet Droz',
    'Lalique',
    'La Perla',
    'Le Rhöne',
    'Lise Charmel',
    'Longines',
    'Luz',
    'Mappin & Webb',
    'Marina B',
    'Marjolaine',
    'Mathon Paris',
    'Mikimoto',
    'Mikimoto Pearls',
    'Montblanc',
    'Native Union',
    'Numérologie',
    'Omega',
    'Panerai',
    'Pandora',
    'Parmigiani Fleurier',
    'Patagonia',
    'Patek Philippe',
    'Pensluxe',
    'Piaget',
    'Pierre Cardin',
    'Pointe de Penmarc’h',
    'Primark',
    'Repetto',
    'Richard Mille',
    'Rigby & Peller',
    'Romain Gauthier',
    'Roger Dubuis',
    'Sabba',
    'Salvatore Ferragamo',
    'Seaman Schepps',
    'Serge Lutens',
    'Shoemakers',
    'Sinéquanone',
    'Sisley',
    'Swarovski',
    'Swatch Group',
    'Tag Heuer',
    'Tea for Two',
    'Ted Baker',
    'The North Face',
    'Théodor',
    'Tiffany & Co.',
    'Tissot',
    'Tom Ford',
    'Tommy Hilfiger',
    'Tory Burch',
    'Ulysse Nardin',
    'UNIQLO',
    'Urban Outfitters',
    'Van Cleef & Arpels',
    'Vacheron Constantin',
    'Valentino',
    'Vera Wang',
    'Verdura',
    'Victoria’s Secret',
    'Wolford',
    'Yankee Candle',
    'Yazbukey',
    'Zack',
    'Zalando',
    'Zara',
    'Zwilling',
  ];

  return (
    <div className={s.grid}>
      <SelectOptions
        title={i18n.createAdvFilds.default.state[locale]}
        options={states}
        selectedOption={state}
        onSelectOption={setState}
      />
      <Colors selectedColor={color} onColorSelect={setColor} />
      <SelectOptions
        title={i18n.createAdvFilds.things.brand[locale]}
        options={luxuryBrands}
        selectedOption={brand}
        onSelectOption={setBrand}
      />
      <SelectOptions
        title={i18n.createAdvFilds.things.gender[locale]}
        options={genders}
        selectedOption={gender}
        onSelectOption={setGender}
      />
    </div>
  );
}

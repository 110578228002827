import React from 'react';
import s from '@/styles/shared/adv/AdvSellerCard.module.scss';
import { useRouter } from 'next/router';
import i18n from '@/lib/i18n';
import Link from 'next/link';

const URL = process.env.NEXT_PUBLIC_API_URL;

function AdvSellerCard({ data }) {
  const router = useRouter();
  const { locale } = useRouter();
  return (
    <Link href={`/users/${data?.id}`}>
      <div className={s.adv_seller_card}>
        <div className={s.adv_seller_title}>{i18n.advDetail.seller[locale]}</div>
        <div className={s.seller_card_profile}>
          <img
            src={
              data?.avatar
                ? `${data?.avatar}`
                : '/assets/images/sceleton-ava.png'
            }
            alt="error"
          />
          <div className={s.profile_name}>
            <div className={s.seller_full_name}>{data?.full_name}</div>
            <p>
              {i18n.userPage.onBazaar[locale]}
              {' '}
              <strong>{data?.created_at}</strong>
            </p>
            {data?.last_online ? (
              <p>
                {i18n.userPage.online[locale]}
                {' '}
                {data?.last_online}
              </p>
            ) : null}
            <span className={s.user_reviews}>
              <p className={s.user_rating}>{data?.average_rating}</p>
              <span>
                {[1, 2, 3, 4, 5].map((starIndex) => (
                  <img
                    key={starIndex}
                    src={`/assets/icons/${
                      starIndex <= data?.average_rating ? 'star' : 'second_star'
                    }.svg`}
                    alt="icon_error"
                  />
                ))}
              </span>
              <p className={s.quantity_reviews}>
                (
                {data?.reviews.length}
                )
              </p>
            </span>
          </div>
        </div>
        <div className={s.seller_card_more}>
          <p onClick={() => router.push(`/users/${data?.id}`)}>
            {/* <Link href={`/users/${data?.id}`}>{i18n.advDetail.allUserAdv[locale]}</Link> */}
            {i18n.advDetail.allUserAdv[locale]}
            <img src="/assets/icons/seller_card_rigth.svg" alt="error" />
          </p>
        </div>
      </div>
    </Link>
  );
}

export default AdvSellerCard;

import React, { memo } from 'react';
import s from '@/styles/uiComp/catalog/FilterSubCate.module.scss';
import { useRouter } from 'next/router';
import { useCityPrefix } from '@/context/CityPrefixContext';
import i18n from '@/lib/i18n';
import { categoryPaths } from '@/lib/categoryPaths';
import Link from 'next/link';
import { removeQueryParameters } from '@/modules/shared/helpers';
import { useCategories } from '@/modules/categories';

function FilterCategory() {
  const router = useRouter();
  const { locale } = useRouter();
  const { categories } = useCategories();

  return (
    <div className={s.filter_subcategory}>
      <h4>{i18n.filters.title[locale]}</h4>
      <span onClick={() => router.push('/')} />
      {categories.map((category) => (
        <Link
          key={category.id}
          rel="canonical"
          href={category.pathname}
        >
          <p className={s.current_subcategories}>
            <img src="/assets/icons/minus.svg" alt="" />
            {category.title}
          </p>
        </Link>
      ))}
    </div>
  );
}

export default memo(FilterCategory);

import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import FilterSubCategory from '@/components/uiComp/catalog/FilterSubCategory';
import LocationSubCate from '@/components/uiComp/catalog/LocationSubCate';
import MoreParametres from '@/components/uiComp/catalog/MoreParametres';
import PriceSlider from '@/components/uiComp/catalog/PriceSlider';
import FilterBtn from '@/components/uiComp/catalog/FilterBtn';
import { useFilterFormState } from '@/modules/shared/hooks';
import s from './TransfersFilters.module.scss';
import DescriptionSubCate from '../../uiComp/catalog/DescriptionSubCate';
import CheckboxInputFilter from '../../uiComp/catalog/CheckboxInputFilter';

function TransfersFilters({
  category, subcategory, getFilteringData, defaultValue,
}) {
  const { t } = useTranslation('adv');
  const [form] = useFilterFormState([
    'search',
    'district',
    'child_seat',
    'phone_charger',
    'meeting_with_sign',
    'min_price',
    'max_price',
    'city',
    'drive',
    'vehicle_type',
    'service_type',
  ], defaultValue, getFilteringData);

  const handleBtnFiltering = () => {
    getFilteringData(form.query);
  };

  const titleTypeTransfers = t('filters.vehicles-rental.transfer.vehicle_type.title');
  const TypeTransfers = ['car', 'minivan', 'minibus', 'bus', 'other']
    .map((el) => t(`filters.vehicles-rental.transfer.vehicle_type.${el}`));

  const titleTypeService = t('filters.vehicles-rental.transfer.service_type.title');
  const TypeService = ['airport', 'comfort', 'delivery']
    .map((el) => t(`filters.vehicles-rental.transfer.service_type.${el}`));

  const titleTypeDrive = t('filters.vehicles-rental.transfer.drive.title');
  const TypeDrive = ['city', 'between_cities'].map((el) => t(`filters.vehicles-rental.transfer.drive.${el}`));

  return (
    <section className={s.container}>
      <div className={s.filter_block}>
        <FilterSubCategory category={category} subcategory={subcategory} />
        <LocationSubCate
          selectedDistrict={form.value.district}
          onSelectDistrict={form.setter.district}
          onSelectCity={form.setter.city}
          selectedCity={form.value.city}
        />
        <MoreParametres
          title={titleTypeTransfers}
          options={TypeTransfers}
          selected={form.value.vehicle_type}
          onSelect={form.setter.vehicle_type}
        />
        <MoreParametres
          title={titleTypeService}
          options={TypeService}
          selected={form.value.service_type}
          onSelect={form.setter.service_type}
        />
        <MoreParametres
          title={titleTypeDrive}
          options={TypeDrive}
          selected={form.value.drive}
          onSelect={form.setter.drive}
        />
        <CheckboxInputFilter
          title={t('filters.vehicles-rental.transfer.title_additional')}
          options={[
            {
              label: t('filters.vehicles-rental.transfer.child_seat'),
              isChecked: form.value.child_seat,
              onChange: () => form.setter.child_seat(!form.value.child_seat),
            },
            {
              label: t('filters.vehicles-rental.transfer.phone_charger'),
              isChecked: form.value.phone_charger,
              onChange: () => form.setter.phone_charger(!form.value.phone_charger),
            },
            {
              label: t('filters.vehicles-rental.transfer.meeting_with_sign'),
              isChecked: form.value.meeting_with_sign,
              onChange: () => form.setter.meeting_with_sign(!form.value.meeting_with_sign),
            },
          ]}
        />
        <PriceSlider
          minPrice={form.value.min_price}
          maxPrice={form.value.max_price}
          setMinPrice={form.setter.min_price}
          setMaxPrice={form.setter.max_price}
        />
        <DescriptionSubCate description={form.value.search} setDescription={form.setter.search} />
        <FilterBtn handleBtnFiltering={handleBtnFiltering} />
      </div>
    </section>
  );
}

export default TransfersFilters;

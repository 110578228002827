import React, { FC } from 'react';
import { Tooltip } from '@mui/material';
import { useTranslateLocation } from '@/modules/locations';
import { useFormatDataPrice } from '@/modules/shared/hooks';
import { AdImage } from '../ad-image';
import { AdLink } from '../ad-link';
import styles from './ad-card.module.scss';
import { IAd } from '../../types';

type AdCardProps = {
  ad: IAd;
};
export const AdCard: FC<AdCardProps> = ({ ad }) => {
  const {
    city, district, price, price_type,
  } = ad;
  const priceDisplayFormat = useFormatDataPrice();
  const translatedLocation = useTranslateLocation(
    city?.codename,
    district?.codename,
  );

  return (
    <div className={styles.a}>
      <div className={styles.adv_card}>
        <div className={styles.adv_img}>
          <AdLink ad={ad}>
            <AdImage ad={ad} />
          </AdLink>
        </div>
        <div className={styles.adv_text}>
          <AdLink ad={ad}>
            <Tooltip title={ad.title}>
              <h4>{ad.title}</h4>
            </Tooltip>
          </AdLink>
          <span>{priceDisplayFormat({ priceType: price_type, price })}</span>
          <div className={styles.location}>
            <img src="/assets/icons/gps.svg" alt="location_icons" />
            <div>
              <p>{translatedLocation}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  FC,
} from 'react';
import { useSwipeable } from 'react-swipeable';
import { ImageViewModal } from '@/modules/shared/components';
import s from './images.module.scss';

type ImagesProps = {
  images: { id: number; image: string }[];
  adTitle: string;
};
export const Images: FC<ImagesProps> = ({ images, adTitle }) => {
  const [currentImgIndex, setCurrentImgIndex] = useState(0);
  const [currentModalImgIndex, setCurrentModalImgIndex] = useState(currentImgIndex);
  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const handleMoveModalLeft = useCallback(() => {
    setCurrentModalImgIndex((current) => (current - 1 + images.length) % images.length);
  }, [setCurrentModalImgIndex, images.length]);

  const handleMoveModalRight = useCallback(() => setCurrentModalImgIndex(
    (current) => (current + 1) % images.length,
  ), [setCurrentModalImgIndex, images.length]);

  const refImgBlock = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const handleMoveLeft = useCallback(() => {
    setCurrentImgIndex((current) => (current - 1 + images.length) % images.length);
  }, [setCurrentImgIndex, images.length]);
  const handleMoveRight = useCallback(() => {
    setCurrentImgIndex((current) => (current + 1) % images.length);
  }, [setCurrentImgIndex, images.length]);

  const mainImageHandlers = useSwipeable({
    onSwipedRight: handleMoveLeft,
    onSwipedLeft: handleMoveRight,
  });

  const modalImageHandlers = useSwipeable({
    onSwipedRight: handleMoveModalLeft,
    onSwipedLeft: handleMoveModalRight,
  });

  const handleClose = useCallback(() => {
    setImageModalOpen(false);
  }, [setImageModalOpen]);

  const handleUpdateCurrentModalImgIndex = useCallback(() => {
    setCurrentModalImgIndex(currentImgIndex);
  }, [setCurrentModalImgIndex, currentImgIndex]);

  useEffect(() => {
    const handleResize = () => {
      if (refImgBlock.current) {
        setContainerWidth(refImgBlock.current.offsetWidth);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // calculate the block width

  /*
   * if the distance from the current image mark to the block border
   * is less than 1/3 of the block width, scroll to one side of the block
   */
  useEffect(() => {
    const container = refImgBlock.current;
    if (!container || containerWidth === 0) return;

    const currentImg = container.children[currentImgIndex];
    const imgLeftOffset = currentImg.offsetLeft;
    const imgWidth = 16;
    const scrollThreshold = containerWidth / 3;

    if (
      imgLeftOffset + imgWidth
      > container.scrollLeft + containerWidth - scrollThreshold
    ) {
      container.scrollLeft = imgLeftOffset + imgWidth - containerWidth + scrollThreshold;
    } else if (imgLeftOffset < container.scrollLeft + scrollThreshold) {
      container.scrollLeft = imgLeftOffset - scrollThreshold;
    }
  }, [currentImgIndex, containerWidth]);

  useEffect(() => {
    setCurrentModalImgIndex(currentImgIndex);
  }, [currentImgIndex]);

  return (
    <>
      <div
        className={s.adv_images}
        {...mainImageHandlers}
      >
        {images.map((img, index) => (
          <button
            className={s.adv_images__image}
            type="button"
            style={{
              display: index === currentImgIndex ? 'block' : 'none',
            }}
            onClick={() => setImageModalOpen(true)}
            key={img.id}
          >
            <img
              src={img.image}
              alt="error"
            />
          </button>
        ))}
        <button type="button" className={s.adv_images_left} onClick={handleMoveLeft}>
          <img src="/assets/icons/btn_left.svg" alt="err" />
        </button>
        <button type="button" className={s.adv_images_right} onClick={handleMoveRight}>
          <img src="/assets/icons/btn_rigth.svg" alt="err" />
        </button>
        <div className={s.adv_images_option} ref={refImgBlock}>
          {images.map((img, index) => (
            <button
              key={img.id}
              onClick={() => setCurrentImgIndex(index)}
              type="button"
            >
              <img
                src={
                    index === currentImgIndex
                      ? '/assets/icons/img_selected.svg'
                      : '/assets/icons/img_option.svg'
                  }
                alt="err"
              />
            </button>
          ))}
        </div>
      </div>
      {isImageModalOpen && (
        <ImageViewModal
          isOpen={isImageModalOpen}
          onClose={handleClose}
          updateCurrentModalImgIndex={handleUpdateCurrentModalImgIndex}
        >
          <div
            className={s.selected_adv_images}
            {...modalImageHandlers}
          >
            {images.map((img, index) => (
              <img
                className={s.modalContainer_img}
                key={img.id}
                src={`${img.image}`}
                alt={`${adTitle} - ${index}`}
                style={{
                  display: index === currentModalImgIndex ? 'block' : 'none',
                }}
              />
            ))}
            <button
              className={s.adv_images_left}
              onClick={handleMoveModalLeft}
              type="button"
            >
              <img src="/assets/icons/button_left.svg " alt="left" />
            </button>
            <button
              className={s.adv_images_right}
              onClick={handleMoveModalRight}
              type="button"
            >
              <img src="/assets/icons/button_right.svg " alt="right" />
            </button>

            <div className={s.adv_images_option}>
              {images.map((img, index) => (
                <button
                  key={img.id}
                  aria-label={`Image option ${index + 1}`}
                  onClick={() => setCurrentModalImgIndex(index)}
                  type="button"
                >
                  <img
                    src={
                      index === currentModalImgIndex
                        ? '/assets/icons/img_selected.svg'
                        : '/assets/icons/img_option.svg'
                    }
                    alt={`${adTitle} - ${index}`}
                  />
                </button>
              ))}
            </div>
          </div>
        </ImageViewModal>
      )}
    </>
  );
};

import i18n from '@/lib/i18n';
import {
  MAX_DESCRIPTION_LENGTH,
  MAX_TITLE_LENGTH,
  MIN_DESCRIPTION_LENGTH,
  MIN_TITLE_LENGTH,
  MAX_IMAGES_AMOUNT,
} from './consts';

interface IAdFormValues {
  title: string;
  titleStrippedUrls: string[];
  description: string;
  descriptionStrippedUrls: string[];
  price: string;
  price_type: string;
  selectedCityId: string;
  category: string;
  subCategory: string;
  images: File[];
}
export const validate = (data: IAdFormValues) => {
  // once the next-intl supporting is added, replace any with the commented type to
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errors: any /* Partial<IAdFormValues> */ = {};

  ['title', 'description', 'price', 'selectedCityId', 'category'].forEach(
    (field) => {
      if (field !== 'price' && !data[field]) {
        errors[field] = i18n.Form.errors.required;
      }
    },
  );
  if (errors.category) {
    errors.category = i18n.Form.errors.required;
  }

  if (!errors.title) {
    if (data.title.length > MAX_TITLE_LENGTH) {
      errors.title = i18n.Form.errors.maxLength(MAX_TITLE_LENGTH);
    } else if (data.title.length < MIN_TITLE_LENGTH) {
      errors.title = i18n.Form.errors.minLength(MIN_TITLE_LENGTH);
    } else if (data.titleStrippedUrls.length) {
      errors.title = i18n.Form.errors.externalLinks(data.titleStrippedUrls);
    }
  }

  if (!errors.description) {
    if (data.description.length > MAX_DESCRIPTION_LENGTH) {
      errors.description = i18n.Form.errors.maxLength(MAX_DESCRIPTION_LENGTH);
    } else if (data.description.length < MIN_DESCRIPTION_LENGTH) {
      errors.description = i18n.Form.errors.minLength(MIN_DESCRIPTION_LENGTH);
    } else if (data.descriptionStrippedUrls.length) {
      errors.description = i18n.Form.errors.externalLinks(data.descriptionStrippedUrls);
    }
  }
  if (!data.images?.length) {
    errors.images = i18n.Form.errors.imageRequired;
  } else if (data.images.length > MAX_IMAGES_AMOUNT) {
    errors.images = i18n.Form.errors.maxItemsAmount(MAX_IMAGES_AMOUNT);
  }

  if (!errors.price) {
    const priceValue = Number(data.price);
    const parts = `${data.price}`.split('.');

    if (data.price_type !== 'for_free' && data.price_type !== 'by_agreement') {
      if (Number.isNaN(priceValue) || priceValue < 0) {
        errors.price = i18n.Form.errors.invalidPrice;
      } else if (parts[1]?.length > 2) {
        errors.price = i18n.Form.errors.invalidDecimal;
      }
    }
  }

  return errors;
};

import React from 'react';
import s from '@/styles/uiComp/catalog/MoreParameters.module.scss';

function MoreParametresState({
  title,
  selected,
  options,
  onSelect,
}) {
  const handleOptionChange = (option) => {
    const updatedOptions = selected?.includes(option)
      ? selected.filter((selectedOption) => selectedOption !== option)
      : [...selected, option];

    onSelect(updatedOptions);
  };

  return (
    <div className={s.more_parameters_subcategory}>
      <h3>{title}</h3>
      <div className={s.parameters_optionone}>
        {options?.map((option, index) => (
          <button
            key={index}
            onClick={() => handleOptionChange(option)}
            className={selected?.includes(option) ? s.selected : ''}
          >
            <p>{option}</p>
          </button>
        ))}
      </div>
    </div>
  );
}
export default MoreParametresState;

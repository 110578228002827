import React from 'react';
import s from '@/styles/uiComp/catalog/MoreParameters.module.scss';

function MoreParameters({
  title,
  selected,
  options,
  onSelect,
}) {
  const handleOptionChange = (option) => {
    const updatedOptions = selected?.includes(option)
      ? selected.filter((selectedOption) => selectedOption !== option)
      : [...selected, option];

    onSelect(updatedOptions);
  };

  return (
    <div className={s.more_parameters_subcategory}>
      <h3>{title}</h3>
      <div className={s.parameters_option}>
        {options.map((option, index) => {
          const isStr = typeof option === 'string';
          return (
            <label key={index}>
              <p>{isStr ? option : option.label}</p>
              <input
                className={s.input}
                type="checkbox"
                value={isStr ? option : option.value}
                checked={selected?.includes(isStr ? option : option.value)}
                onChange={() => handleOptionChange(isStr ? option : option.value)}
              />
            </label>
          );
        })}
      </div>
    </div>
  );
}

export default MoreParameters;

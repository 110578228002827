import React from 'react';
import s from '@/styles/uiComp/catalog/FilterSubCate.module.scss';
import { useRouter } from 'next/router';
import i18n from '@/lib/i18n';
import Link from 'next/link';


const FilterSubCategory = ({ category, subcategory }) => {
  const router = useRouter();
  const { locale } = router;
  const subcategories = category.sub_categories;

  const displaySubCategories = (subcategory) => (subcategory
      && subcategory.under_sub_categories.length > 0
    ? subcategory.under_sub_categories
    : subcategories.filter((sub) => sub.codename !== subcategory?.codename));

  const displayedCategories = displaySubCategories(subcategory);

  return (
    <div className={s.filter_subcategory}>
      <h4>{i18n.filters.title[locale]}</h4>
      <Link
        href={category.pathname}
      >
        <span>
          <img src="/assets/icons/arrow.svg" alt="icon_error" />
          <p>
            {category.title}
          </p>
        </span>
      </Link>
      <p className={s.current_subcategory}>
        {subcategory?.title}
      </p>
      {displayedCategories.map((sub) => (
        <Link
          key={sub.id}
          href={sub.pathname}
        >
          <p className={s.current_subcategories}>
            <img src="/assets/icons/minus.svg" alt="" />
            {sub.title}
          </p>
        </Link>
      ))}
    </div>
  );
};

export default FilterSubCategory;

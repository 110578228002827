import ToggleButtons from '@/components/uiComp/createProduct/ToggleButtons';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import i18n from '../../../lib/i18n';

export default function Transportation({
  onTransportationDataChange,
  defaultValue,
}) {
  const { locale } = useRouter();
  const [availability_of_loaders, setAvailability_of_loaders] = useState(false);
  useEffect(() => {
    if (defaultValue) {
      setAvailability_of_loaders(defaultValue.availability_of_loaders);
    }
  }, [defaultValue]);
  useEffect(() => {
    const formData = {
      availability_of_loaders,
    };

    onTransportationDataChange(formData);
  }, [availability_of_loaders]);
  return (
    <section>
      <ToggleButtons
        title={i18n.createAdvFilds.services[locale]}
        selectedStatus={availability_of_loaders}
        onSelectStatus={setAvailability_of_loaders}
      />
    </section>
  );
}

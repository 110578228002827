import React, { useMemo } from 'react';
import s from '@/styles/layout/ComplaintModal.module.scss';
import { Dropdown, useDropdownOnChange, useDropdownValue } from '@/modules/shared/components';
import useTranslation from 'next-translate/useTranslation';


function ComplaintModal({
  onClose,
  selectedReason,
  setSelectedReason,
  sendComplaint,
  setComplaintModal,
}) {
  const { t } = useTranslation('');

  const handleSelectReason = (reason) => setSelectedReason(reason);

  const options = useMemo(() => [
    {
      value: null,
      label: t('common:firstOption'),
    },
    {
      value: 'Некорректное объявление',
      label: t('adv:complaintModal.reasons.incorrectAd'),
    },
    {
      value: 'Запрещенный товар',
      label: t('adv:complaintModal.reasons.prohibitedGoods'),
    },
    {
      value: 'Неподобающее поведение, спам',
      label: t('adv:complaintModal.reasons.spam'),
    },
    {
      value: 'Нарушение авторских прав',
      label: t('adv:complaintModal.reasons.copyright'),
    },
    {
      value: 'Проблемы с возвратом товара или качеством услуги',
      label: t('adv:complaintModal.reasons.goodsQuality'),
    },
    {
      value: 'Другое',
      label: t('adv:complaintModal.reasons.other'),
    },
  ], [t]);

  return (
    <section className={s.container_modal_complaint} onClick={() => setComplaintModal(false)}>
      <div className={s.complaint_form} onClick={(event) => event.stopPropagation()}>
        <div className={s.form_title}>
          <img
            src="/assets/icons/filter_arrow_left.svg"
            alt="icon_error"
            onClick={onClose}
          />
          <h4>{t('adv:complaintModal.title')}</h4>
        </div>
        <div className={s.form_reason}>
          <p>{t('adv:complaintModal.description')}</p>
          <Dropdown
            options={options}
            value={useDropdownValue(selectedReason, options)}
            onChange={useDropdownOnChange(handleSelectReason)}
            disabled
          />
        </div>
        <button
          className={s.submit}
          onClick={sendComplaint}
          disabled={!selectedReason}
        >
          {t('common:submit')}
        </button>
      </div>
    </section>
  );
}

export default ComplaintModal;

import React, { useState, useEffect, useMemo } from 'react';
import ToggleButtons from '@/components/uiComp/createProduct/ToggleButtons';
import SelectOptions from '@/components/uiComp/createProduct/SelectOptions';
import useTranslation from 'next-translate/useTranslation';
import s from './Estate.module.scss';

export default function RentEstate({ onRentEstateDataChange, defaultValue }) {
  const { t } = useTranslation('');
  const [typeHousing, setTypeHousing] = useState('');
  const [numberOfRooms, setNumberOfRooms] = useState('');
  const [deposit, setDeposit] = useState(false);
  const [swimming, setSwimming] = useState(false);
  const [rules, setRules] = useState('');

  useEffect(() => {
    if (defaultValue) {
      setTypeHousing(defaultValue.type_housing);
      setNumberOfRooms(defaultValue.number_of_rooms);
      setDeposit(defaultValue.deposit);
      setSwimming(defaultValue.swimming_pool);
      setRules(defaultValue.rules);
    }
  }, [defaultValue]);

  useEffect(() => {
    const formData = {
      type_housing: typeHousing,
      number_of_rooms: numberOfRooms,
      deposit,
      swimming_pool: swimming,
      rules,
    };

    onRentEstateDataChange(formData);
  }, [
    typeHousing,
    numberOfRooms,
    deposit,
    swimming,
    rules,
  ]);

  // Тип жилья
  const typeHouse = useMemo(() => [
    'other', 'town', 'apartment', 'villa']
    .map((el) => t(`category-parameters:real-estate.type_housing.values.${el}`)), [t]);

  // Количество комнат
  const typeRoom = useMemo(() => [
    'studio', 'one_room', 'two_rooms', 'three_rooms', 'four_rooms', 'five_or_more_rooms']
    .map((el) => t(`category-parameters:real-estate.number_of_rooms.values.${el}`)), [t]);

  // Правила
  const typeRule = useMemo(() => [
    'children', 'pets', 'smoking']
    .map((el) => t(`category-parameters:real-estate.rules.values.${el}`)), [t]);

  return (
    <div className={s.container}>
      {/* Тип жилья */}
      <SelectOptions
        title={t('category-parameters:real-estate.type_housing.label')}
        options={typeHouse}
        selectedOption={typeHousing}
        onSelectOption={setTypeHousing}
      />
      {/* Количество комнат */}
      <SelectOptions
        title={t('category-parameters:real-estate.number_of_rooms.label')}
        options={typeRoom}
        selectedOption={numberOfRooms}
        onSelectOption={setNumberOfRooms}
      />
      {/* Депозит */}
      <ToggleButtons
        title={t('category-parameters:real-estate.deposit.label')}
        selectedStatus={deposit}
        onSelectStatus={setDeposit}
      />
      {/* Наличие бассейна */}
      <ToggleButtons
        title={t('category-parameters:real-estate.swimming_pool.label')}
        selectedStatus={swimming}
        onSelectStatus={setSwimming}
      />
      {/* Правила */}
      <SelectOptions
        title={t('category-parameters:real-estate.rules.label')}
        options={typeRule}
        selectedOption={rules}
        onSelectOption={setRules}
      />
    </div>
  );
}

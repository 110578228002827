import React from 'react';
import s from '@/styles/uiComp/State.module.scss';
import { useRouter } from 'next/router';
import i18n from '../../../lib/i18n';

function SelectOptions({
  title, options, selectedOption, onSelectOption,
}) {
  const { locale } = useRouter();
  return (
    <div className={s.block}>
      <div className={s.block_box}>
        <h2>{title}</h2>
        <select
          value={selectedOption || ''}
          onChange={(e) => onSelectOption(e.target.value)}
        >
          <option value="" disabled>
            {i18n.createAdvFilds.default.notIndicated[locale]}
          </option>
          {options.map((option, index) => (
            <option key={option.id} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default SelectOptions;

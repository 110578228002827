import React, { useState } from 'react';
import s from '@/styles/uiComp/ToggleButtons.module.scss';
import { useRouter } from 'next/router';
import i18n from '../../../lib/i18n';

export default function TypeButtons({ title, selectedStatus, onSelectStatus }) {
  const { locale } = useRouter();
  return (
    <div className={s.form_status_event}>
      <h4>{title}</h4>
      <div className={s.status_event_btns}>
        <button
          type="button"
          className={selectedStatus === 'Новый' ? s.btn_selected : s.btn_adult}
          onClick={() => onSelectStatus('Новый')}
        >
          {i18n.createAdvFilds.default.new[locale]}
        </button>
        <button
          type="button"
          className={
            selectedStatus === 'Б/у' ? s.btn_child_selected : s.btn_child
          }
          onClick={() => onSelectStatus('Б/у')}
        >
          {i18n.createAdvFilds.default.old[locale]}
        </button>
      </div>
    </div>
  );
}

import React from 'react';
import FilterSubCategory from '@/components/uiComp/catalog/FilterSubCategory';
import LocationSubCate from '@/components/uiComp/catalog/LocationSubCate';
import MoreParametres from '@/components/uiComp/catalog/MoreParametres';
import PriceSlider from '@/components/uiComp/catalog/PriceSlider';
import FilterBtn from '@/components/uiComp/catalog/FilterBtn';
import { useRouter } from 'next/router';
import i18n from '@/lib/i18n';
import { useFilterFormState } from '@/modules/shared/hooks';
import s from '../auto/AutoFilters.module.scss';
import DescriptionSubCate from '../../uiComp/catalog/DescriptionSubCate';
import SelectOptions from '../../uiComp/createProduct/SelectOptions';
import Colors from '../../uiComp/createProduct/Colors';

function ChildClothes({
  category,
  subcategory,
  underSubCategory: undersubcategory,
  getFilteringData,
  defaultValue,
}) {
  const [form] = useFilterFormState([
    'color',
    'district',
    'state',
    'search',
    'size',
    'brand',
    'min_price',
    'max_price',
    'city',
  ], defaultValue, getFilteringData);
  const { locale } = useRouter();

  const TypeClothes = [
    i18n.filters.default.Excellent[locale],
    i18n.filters.default.good[locale],
    i18n.filters.default.Satisfactory[locale],
  ];
  const titleClothes = i18n.filters.default.States[locale];

  const sizeTitle = i18n.createAdvFilds.things.size[locale];

  const sizeArray = [
    `50-56 (3 ${i18n.createAdvFilds.default.month[locale]})`,
    `62-68 (6 ${i18n.createAdvFilds.default.months[locale]})`,
    `74-80 (9 ${i18n.createAdvFilds.default.months[locale]})`,
    `86-92 (1-3 ${i18n.createAdvFilds.default.year[locale]})`,
    `98-104 (4 ${i18n.createAdvFilds.default.year[locale]})`,
    `110-122 (5-7 ${i18n.createAdvFilds.default.years[locale]})`,
    `128-152 (8-12 ${i18n.createAdvFilds.default.years[locale]})`,
    `158-164 (14-17 ${i18n.createAdvFilds.default.years[locale]})`,
  ];
  const luxuryBrands = [
    i18n.createAdvFilds.things.Otherbrand[locale],
    'Adidas Kids',
    'Agatha Paris',
    'Andres Sarda',
    'Asprey',
    'Atelier de Famille',
    'Audemars Piguet',
    'Au Printemps Paris',
    'Baccarat',
    'Baume & Mercier',
    'Blancpain',
    'Bordelle',
    'Boden',
    'Breitling',
    'Breguet',
    'Buccellati',
    'Cadolle',
    'Cartier',
    'Cat & Jack',
    'Chanel',
    'Carter\'s',
    'Chantal Thomass',
    'Chopard',
    'Confidences',
    'Dinh Van',
    'David Morris',
    'David Webb',
    'De Beers',
    'Eres',
    'Fifi Chachnil',
    'Garrard',
    'Gèdre',
    'Glashütte Original',
    'Gap Kids',
    'Graff',
    'Graff Diamonds',
    'Gübelin',
    'Gymboree',
    'Harry Winston',
    'H&M Kids',
    'Hanna Andersson',
    'Hermès',
    'I.D. Sarrieri',
    'IWC Schaffhausen',
    'Irène Van Ryb',
    'Jaeger-LeCoultre',
    'Jaquet Droz',
    'Janie and Jack',
    'Lalique',
    'La Perla',
    'Le Rhöne',
    'Lise Charmel',
    'Longines',
    'Luz',
    'Mappin & Webb',
    'Marina B',
    'Marjolaine',
    'Mathon Paris',
    'Mikimoto',
    'Mikimoto Pearls',
    'Montblanc',
    'Mini Boden',
    'Mini Melissa',
    'Nike Kids',
    'Native Union',
    'Numérologie',
    'Omega',
    'Old Navy Kids',
    'OshKosh B\'gosh',
    'Panerai',
    'Pandora',
    'Parmigiani Fleurier',
    'Patagonia',
    'Patek Philippe',
    'Patagonia Kids',
    'Pensluxe',
    'Piaget',
    'Pierre Cardin',
    'Pointe de Penmarc’h',
    'Primark',
    'Repetto',
    'Richard Mille',
    'Rigby & Peller',
    'Romain Gauthier',
    'Roger Dubuis',
    'Ralph Lauren Kids',
    'Sabba',
    'Salvatore Ferragamo',
    'Seaman Schepps',
    'Serge Lutens',
    'Shoemakers',
    'Sinéquanone',
    'Sisley',
    'Swarovski',
    'Swatch Group',
    'Tag Heuer',
    'Tea for Two',
    'Tea Collection',
    'Ted Baker',
    'The North Face',
    'The North Face Kids',
    'Théodor',
    'Tiffany & Co.',
    'Tissot',
    'Tom Ford',
    'Tommy Hilfiger',
    'Tory Burch',
    'Ulysse Nardin',
    'UNIQLO',
    'Urban Outfitters',
    'Under Armour Kids',
    'Van Cleef & Arpels',
    'Vacheron Constantin',
    'Valentino',
    'Vera Wang',
    'Verdura',
    'Victoria’s Secret',
    'Wolford',
    'Yankee Candle',
    'Yazbukey',
    'Zack',
    'Zalando',
    'Zara',
    'Zara Kids',
    'Zwilling',
  ];

  const handleBtnFiltering = () => {
    getFilteringData(form.query);
  };

  return (
    <section className={s.container}>
      <div className={s.filter_block}>
        <FilterSubCategory
          category={category}
          subcategory={subcategory}
          undersubcategory={undersubcategory}
        />
        <LocationSubCate
          selectedDistrict={form.value.district}
          onSelectDistrict={form.setter.district}
          onSelectCity={form.setter.city}
          selectedCity={form.value.city}
        />
        <MoreParametres
          title={titleClothes}
          options={TypeClothes}
          selected={form.value.state}
          onSelect={form.setter.state}
        />
        <MoreParametres
          title={sizeTitle}
          options={sizeArray}
          selected={form.value.size}
          onSelect={form.setter.size}
        />
        <Colors selectedColor={form.value.color} onColorSelect={form.setter.color} />
        <SelectOptions
          title={i18n.createAdvFilds.things.brand[locale]}
          options={luxuryBrands}
          selectedOption={form.value.brand}
          onSelectOption={form.setter.brand}
        />
        <PriceSlider
          minPrice={form.value.min_price}
          maxPrice={form.value.max_price}
          setMinPrice={form.setter.min_price}
          setMaxPrice={form.setter.max_price}
        />
        <DescriptionSubCate description={form.value.search} setDescription={form.setter.search} />
        <FilterBtn handleBtnFiltering={handleBtnFiltering} />
      </div>
    </section>
  );
}

export default ChildClothes;

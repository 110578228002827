import React, {
  useCallback, useEffect, useState, useMemo, useRef,
} from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import CategoryModal from '@/components/shared/layout/AdsModal/CategoryModal';
import SubCategories from '@/components/shared/layout/AdsModal/SubCategories';
import CategoryComponent from '@/components/shared/CreateProduct/CategoryComponent';
import UnderSubCategory from '@/components/shared/layout/AdsModal/UnderSubCategories';
import i18n from '@/lib/i18n';
import 'react-quill/dist/quill.snow.css';
import { NextSeo } from 'next-seo';
import { useAuthenticated } from '@/modules/auth';
import { useCleanTextWithLinks } from '@/modules/shared/hooks';
import { cleanPriceValue } from '@/modules/shared/helpers';
import { CitiesDropdown, DistrictsDropdown, useLocationsListQuery } from '@/modules/locations';
import { saveEntityImages } from '@/modules/shared/api/helpers';
import { useCategories } from '@/modules/categories';
import useTranslation from 'next-translate/useTranslation';
import { useTrackEvent } from '@/modules/events-tracking';
import { AdsApi } from '../../api';
import s from './ad-form.module.scss';
import { validate } from './validate';
import {
  MAX_DESCRIPTION_LENGTH,
  MAX_TITLE_LENGTH,
  MIN_DESCRIPTION_LENGTH,
} from './consts';
import { ImagesField } from './images-field.component';
import { useSaveAd } from './use-save-ad.hook';

const allowedLinkDomains = process.env.NEXT_PUBLIC_ALLOWED_AD_LINK_DOMAINS?.split(',') || [];

export function NewAd() {
  useAuthenticated();

  const [selectedCityId, setCityId] = useState();
  const handleCityChange = useCallback((id) => {
    setCityId(id);
  }, [setCityId]);
  const [selectedDistrictId, setDistrictId] = useState();
  const handleDistrictChange = useCallback((id) => {
    setDistrictId(id);
  }, [setDistrictId]);

  const [choosedImgs, setChoosedImgs] = useState([]);
  const [categoriesMod, setCategoriesMod] = useState('');
  const { createProduct, loading } = useSaveAd();
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState({ price: '', priceType: 'for_money' });
  const { categories } = useCategories();
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [underSubCategoryId, setUnderSubCategoryId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [subCategoryId, setSubCategoryId] = useState(null);
  const currentCategory = categories.find((cat) => cat.id === categoryId);
  const currentSubCategory = currentCategory?.sub_categories.find(
    (subCat) => subCat.id === subCategoryId,
  );
  const currentUnderSubCategory = currentSubCategory?.under_sub_categories?.find(
    (underSubCat) => underSubCat.id === underSubCategoryId,
  );
  const [saving, setSaving] = useState(false);
  const [autoData, setAutoData] = useState({});
  const [mopedData, setMopedData] = useState({});
  const [motoData, setMotoData] = useState({});
  const [rentEstateData, setRentEstateData] = useState({});
  const [clothesData, setClothesData] = useState({});
  const [shoesData, setShoesData] = useState({});
  const [accessoriesData, setAccessoriesData] = useState({});
  const [watchesData, setWatchesData] = useState({});
  const [jewerlyData, setJewerlyData] = useState({});
  const [childClothesData, setChildClothesData] = useState({});
  const [toysAndBeautyData, setToysAndBeautyData] = useState({});
  const [byEstateData, setByEstateData] = useState({});
  const [transfersData, setTransfersData] = useState({});
  const [searchWorkData, setSearchWorkData] = useState({});
  const [searchWorkerData, setSearchWorkerData] = useState({});
  const [transportationData, setTransportationData] = useState({});
  const [electronicData, setElectronicData] = useState({});
  const [telephoneData, setTelephoneData] = useState({});
  const { locale } = useRouter();
  const [address] = useState('');
  const [errors, setErrors] = useState({});
  const [draft, setDraft] = useState({});
  const [priceInputReadonly, setPriceInputReadonly] = useState('for_money');
  const priceInputRef = useRef(null);
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();

  useEffect(() => {
    const newDraft = JSON.parse(localStorage.getItem('draft'));
    setDraft(newDraft);
  }, [setDraft]);

  useEffect(() => {
    const handleUnload = () => {
      const productData = {
        title,
        description,
        price: price.price,
        images: choosedImgs,
        address,
      };

      const dataToSave = {
        ...productData,
        selectedCityId,
        selectedDistrictId,
        category: categoryId,
        subCategory: subCategoryId,
      };

      const draftString = JSON.stringify(dataToSave);
      localStorage.setItem('draft', draftString);
    };

    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener('unload', handleUnload);
    };
  }, [
    title,
    description,
    price,
    choosedImgs,
    address,
    selectedCityId,
    selectedDistrictId,
    categoryId,
    subCategoryId,
  ]);

  const getSubCategoriesById = useMemo(() => {
    const category = categories.find((cat) => cat.id === categoryId);
    return category ? category.sub_categories : [];
  }, [categories, categoryId]);

  const openDraft = () => {
    setTitle(draft?.title);
    setPrice((prev) => ({ ...prev, price: draft?.price }));
    setDescription(draft?.description);
    setDistrictId(draft?.selectedDistrictId);
    setCityId(draft?.selectedCityId);
    setCategoryId(draft?.categoryId);
    setSubCategoryId(draft?.subCategoryId);
  };

  const handleAutoDataChange = (data) => {
    setAutoData(data);
  };
  const handleMotoDataChange = (data) => {
    setMotoData(data);
  };
  const handleMopedDataChange = (data) => {
    setMopedData(data);
  };
  const handleRentEstateDataChange = (data) => {
    setRentEstateData(data);
  };
  const handleClothesDataChange = (data) => {
    setClothesData(data);
  };
  const handleShoesDataChange = (data) => {
    setShoesData(data);
  };
  const handleAccessoriesDataChange = (data) => {
    setAccessoriesData(data);
  };
  const handleWatchesDataChange = (data) => {
    setWatchesData(data);
  };
  const handleJewerlyDataChange = (data) => {
    setJewerlyData(data);
  };
  const handleChildClothesDataChange = (data) => {
    setChildClothesData(data);
  };
  const handleToysAndBeautyDataChange = (data) => {
    setToysAndBeautyData(data);
  };

  const handleByEstateDataChange = (data) => {
    setByEstateData(data);
  };
  const handleTransfersDataChange = (data) => {
    setTransfersData(data);
  };
  const handleSearchWorkDataChange = (data) => {
    setSearchWorkData(data);
  };
  const handleSearchWorkerDataChange = (data) => {
    setSearchWorkerData(data);
  };
  const handleTransportationDataChange = (data) => {
    setTransportationData(data);
  };
  const handleElectronicDataChange = (data) => {
    setElectronicData(data);
  };
  const handleTelephoneDataChange = (data) => {
    setTelephoneData(data);
  };

  const handleChangePriceType = (newType) => {
    setPrice((prev) => ({
      ...prev,
      priceType: newType,
      price: '',
    }));
    setPriceInputReadonly(newType);

    if (newType === 'for_money' && priceInputRef.current) {
      priceInputRef.current.focus();
    }
  };

  const handleChangePrice = (event) => {
    const { value } = cleanPriceValue(event);
    if (value === 0) {
      setPrice((prev) => ({ ...prev, price: '', priceType: 'for_free' }));
      setPriceInputReadonly('for_free');
    } else {
      setPrice((prev) => ({ ...prev, price: value, priceType: 'for_money' }));
      setPriceInputReadonly('for_money');
    }
  };

  const handlePriceFocus = () => {
    setPrice((prev) => ({
      ...prev,
      priceType: 'for_money',
    }));
    setPriceInputReadonly('for_money');
  };


  const onPriceInputKeyPress = useCallback((event) => {
    // prevent e (scientific notation), - (negative number) and + (positive number)
    // all these aren't handled by onChange event
    if (event.key === 'e' || event.key === '-' || event.key === '+') {
      event.preventDefault();
    }
  }, []);

  const { strippedUrls: descriptionStrippedUrls } = useCleanTextWithLinks(description, allowedLinkDomains);
  const { strippedUrls: titleStrippedUrls } = useCleanTextWithLinks(title, allowedLinkDomains);

  const createImageApi = useCallback(async (formats) => AdsApi.createImages({ formats }), []);

  const handleIsCategoriesMod = (
    mod,
    subcategories,
    currentCategoryId,
    currentSubCategoryId,
    currentUnderSubCategoryId,
  ) => {
    setCategoriesMod(mod);
    setSelectedSubcategories(subcategories || []);

    if (currentCategoryId !== null && currentCategoryId !== undefined) {
      setCategoryId(currentCategoryId);
    }

    if (currentSubCategoryId !== null && currentSubCategoryId !== undefined) {
      setSubCategoryId(currentSubCategoryId);
    }

    if (
      currentUnderSubCategoryId !== null
      && currentUnderSubCategoryId !== undefined
    ) {
      setUnderSubCategoryId(currentUnderSubCategoryId);
    }
  };

  const handleTitleTextareaChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length <= 25) {
      setTitle(inputValue);
    }
    setTitle(event.target.value);
  };

  const handleDescriptionBlur = () => {
    if (errors.description
      && (description.length < MAX_DESCRIPTION_LENGTH
        && description.length > MIN_DESCRIPTION_LENGTH
        && !descriptionStrippedUrls.length)) {
      setErrors({
        ...errors,
        description: '',
      });
    }
  };

  const save = async () => {
    const productData = {
      title,
      description,
      images: choosedImgs,
      address,
      ...(price.price === '' ? { } : { price: price.price }),
      price_type: price.priceType,
    };
    const newErrors = validate({
      ...productData,
      titleStrippedUrls,
      descriptionStrippedUrls,
      selectedCityId,
      category: currentCategory?.title || '',
      subCategory: subCategoryId,
    });
    setErrors(newErrors);

    if (!Object.keys(newErrors).length) {
      try {
        const images = await saveEntityImages(choosedImgs, createImageApi);
        await createProduct(
          {
            ...productData,
            images: images.sort((a, b) => a.id - b.id),
          },
          categoryId,
          subCategoryId,
          underSubCategoryId,
          selectedCityId,
          selectedDistrictId,
          autoData,
          motoData,
          mopedData,
          rentEstateData,
          clothesData,
          shoesData,
          accessoriesData,
          watchesData,
          jewerlyData,
          childClothesData,
          toysAndBeautyData,
          byEstateData,
          transfersData,
          searchWorkData,
          searchWorkerData,
          transportationData,
          electronicData,
          telephoneData,
        );
        trackEvent('publishAd');
      } catch (error) {
        console.error('Product creation failed:', error);
      }
    }
    setSaving(false);
  };

  const handleSubmit = (e) => {
    setSaving(true);
    e.preventDefault();
    save();
  };

  const handleCategoryClick = () => {
    if (currentUnderSubCategory) {
      handleIsCategoriesMod(
        'UNDER_SUB_CATEGORIES',
        currentSubCategory.under_sub_categories,
        categoryId,
        subCategoryId,
        underSubCategoryId,
      );
    } else if (currentSubCategory) {
      handleIsCategoriesMod(
        'SUB_CATEGORIES',
        currentCategory.sub_categories,
        categoryId,
        subCategoryId,
      );
    } else {
      handleIsCategoriesMod('CATEGORIES');
    }
    setUnderSubCategoryId(null);
  };

  return (
    <>
      <NextSeo title={i18n.createAdv.Title[locale]} description={i18n.createAdv.Description[locale]} />
      <section className={s.block_create_ads}>
        <div className={s.create_ads_title}>
          <h2>{t('forms:createAd.title')}</h2>
          {/* UI for the draft confirmation modal */}
          {draft ? (
            <div className={s.container_draft}>
              <p>You have draft, do you want change?</p>
              <button onClick={openDraft} type="button">Change</button>
            </div>
          ) : null}
          {/* end of the UI */}
          <Link href="/" locale={locale}>
            <img src="/assets/icons/long_arrow_left.svg" alt="icon_error" />
            {t('common:comeBack')}
          </Link>
        </div>
        <form className={s.create_ads_form} onSubmit={handleSubmit}>
          <div className={errors.title && s.error}>
            <div className={s.form_title_cate}>
              <h4>{t('forms:createAd.describeDetail')}</h4>
              <label htmlFor="title">
                {t('forms:createAd.adTitle')}
                <input
                  required
                  type="text"
                  id="title"
                  name="title"
                  placeholder={t('forms:createAd.adName')}
                  maxLength={MAX_TITLE_LENGTH}
                  value={title}
                  onChange={handleTitleTextareaChange}
                />
                {errors.title && (
                  <p style={{ color: 'red' }}>{errors.title[locale]}</p>
                )}
              </label>
              <div className={s.form_choose_cate}>
                {!errors.category && currentCategory ? null : (
                  <>
                    <p style={{ color: errors.category ? 'red' : undefined }}>
                      {t('forms:createAd.selectCategory')}
                    </p>
                    <button
                      type="button"
                      onClick={() => handleIsCategoriesMod('CATEGORIES')}
                    >
                      {t('forms:createAd.chooseCategory')}
                      <img
                        src="/assets/icons/mini_arrow_right.svg"
                        alt="icon_error"
                      />
                    </button>
                  </>
                )}

                {currentCategory && (
                  <div
                    className={s.select_category}
                    onClick={handleCategoryClick}
                  >
                    <img
                      src={currentCategory.icon}
                      alt="category_icons"
                    />
                    <p>
                      {currentCategory.title}
                    </p>
                    {currentSubCategory && (
                      <p>
                        -
                        {' '}
                        {currentSubCategory.title}
                      </p>
                    )}
                    {currentUnderSubCategory && (
                      <p>
                        {`- ${currentUnderSubCategory.title}`}
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <ImagesField
            onChange={setChoosedImgs}
            value={choosedImgs}
            error={errors.images}
          />

          <CategoryComponent
            currentCategory={currentCategory}
            currentSubCategory={currentSubCategory}
            currentUnderSubCategory={currentUnderSubCategory}
            onAutoDataChange={handleAutoDataChange}
            onMotoDataChange={handleMotoDataChange}
            onMopedDataChange={handleMopedDataChange}
            onRentEstateDataChange={handleRentEstateDataChange}
            onClothesDataChange={handleClothesDataChange}
            onShoesDataChange={handleShoesDataChange}
            onAccessoriesDataChange={handleAccessoriesDataChange}
            onWatchesDataChange={handleWatchesDataChange}
            onJewerlyDataChange={handleJewerlyDataChange}
            onChildClothesDataChange={handleChildClothesDataChange}
            onToysAndBeautyDataChange={handleToysAndBeautyDataChange}
            onByEstateDataChange={handleByEstateDataChange}
            onTransfersDataChange={handleTransfersDataChange}
            onSearchWorkDataChange={handleSearchWorkDataChange}
            onSearchWorkerDataChange={handleSearchWorkerDataChange}
            onTransportationDataChange={handleTransportationDataChange}
            onElectronicDataChange={handleElectronicDataChange}
            onTelephoneDataChange={handleTelephoneDataChange}
          />
          <div className={errors.description ? s.error : ''}>
            <div className={s.form_description}>
              <h4>{t('forms:createAd.descriptionTitle')}</h4>
              <div className="text-editor-container">
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  maxLength={5000}
                  placeholder={t('forms:createAd.descriptionPlaceholder')}
                  onBlur={handleDescriptionBlur}
                />
              </div>

              <span className={s.span}>
                {description?.length < MIN_DESCRIPTION_LENGTH && (
                  <p>
                    {t('forms:createAd.descriptionCount')}
                    {' '}
                    {MIN_DESCRIPTION_LENGTH - description.length}
                    {' '}
                    {t('forms:createAd.descSymbolCount')}
                  </p>
                )}
                <p>
                  {description?.length}
                  /
                  {MAX_DESCRIPTION_LENGTH}
                </p>
              </span>
              {errors.description && (
                <p style={{ color: 'red' }}>{errors.description[locale]}</p>
              )}
            </div>
          </div>

          <div className={errors.selectedCityId && s.error}>
            <div className={s.form_location}>
              <h4>{t('forms:createAd.locations')}</h4>

              <div className={s.select_new_ad}>
                <div className={s.select_city}>
                  <CitiesDropdown value={selectedCityId} onChange={handleCityChange} />
                </div>
                <div className={s.select_district}>
                  <DistrictsDropdown
                    cityId={selectedCityId}
                    value={selectedDistrictId}
                    onChange={handleDistrictChange}
                  />
                </div>
                {errors.selectedCityId && (
                  <p style={{ color: 'red' }}>
                    {errors.selectedCityId[locale]}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className={errors.price && s.error}>
            <div className={s.form_price}>
              <h4>{t('forms:createAd.price')}</h4>
              <div className={s.price_container}>
                <label className={s.price_input}>
                  <input
                    type="radio"
                    name="priceType"
                    checked={price.priceType === 'for_money'}
                    onChange={() => handleChangePriceType('for_money')}
                    className={s.price_input_radio}
                  />
                  <input
                    type="number"
                    min="0"
                    name="price"
                    value={price.price === null ? '' : price.price}
                    ref={priceInputRef}
                    placeholder={t('forms:createAd.inputData')}
                    readOnly={priceInputReadonly !== 'for_money'}
                    onKeyDown={onPriceInputKeyPress}
                    onChange={handleChangePrice}
                    onWheel={(e) => e.target.blur()}
                    onFocus={handlePriceFocus}
                  />
                </label>
                {errors.price && (
                <p style={{ color: 'red' }}>{errors.price[locale]}</p>
                )}

                <div className={s.price_types}>
                  <label className={s.checkbox_container}>
                    <input
                      type="radio"
                      name="priceType"
                      checked={price.priceType === 'for_free'}
                      onChange={() => handleChangePriceType('for_free')}
                    />
                    {t('common:forFree')}
                  </label>
                  <label className={s.checkbox_container}>
                    <input
                      type="radio"
                      name="priceType"
                      checked={price.priceType === 'by_agreement'}
                      onChange={() => handleChangePriceType('by_agreement')}
                    />
                    {t('common:byAgreement')}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className={s.form_btns}>
            <button type="submit" className={s.btn_publish} disabled={saving}>
              {loading
                ? t('forms:labels.submitting')
                : t('common:submit')}
            </button>
          </div>
        </form>

        {categoriesMod === 'CATEGORIES' && (
          <CategoryModal
            categories={categories}
            onClose={handleIsCategoriesMod}
          />
        )}
        {categoriesMod === 'SUB_CATEGORIES' && (
          <SubCategories
            subcategories={selectedSubcategories}
            onClose={handleIsCategoriesMod}
            categoryId={categoryId}
          />
        )}
        {categoriesMod === 'UNDER_SUB_CATEGORIES'
          && currentSubCategory?.under_sub_categories?.length > 0 && (
            <UnderSubCategory
              underSubCategories={currentSubCategory.under_sub_categories}
              onClose={handleIsCategoriesMod}
              categoryId={categoryId}
              subCategoryId={subCategoryId}
              subCategories={getSubCategoriesById}
            />
        )}
      </section>
    </>
  );
}

import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import { useRouter } from 'next/router';
import { IAd } from '@/modules/ads/types';
import s from '@/styles/pages/UserAdvs.module.scss';
import useTranslation from 'next-translate/useTranslation';
import { useFormatDataPrice } from '@/modules/shared/hooks';
import useMyAds from '../my-ads-page/use-my-ads.hook';
import { ActionColumnProps } from '../my-ads-page/action-column.component';

interface IUserAdsTableProps {
  search: string;
  mode: 'active' | 'inactive';
  ActionColumnComponent: FC<ActionColumnProps>;
}

export const UserAdsTable: FC<IUserAdsTableProps> = ({
  search,
  mode,
  ActionColumnComponent,
}) => {
  const { userData } = useMyAds();
  const router = useRouter();
  const priceDisplayFormat = useFormatDataPrice();
  const [userAds, setUserAds] = useState<IAd[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (!userData) return;

    setUserAds(
      mode === 'active'
        ? userData.announcements
        : userData.not_active_announcements,
    );
  }, [mode, userData]);

  const filteredAds = useMemo(
    () => [...userAds]
      .reverse()
      .filter((name) => name.title.toLowerCase().includes(search.toLowerCase())),
    [userAds, search],
  );

  return (
    <table className={s.personal_advs_table}>
      <thead>
        <tr>
          <th>ID</th>
          <th>{t('userTable:title')}</th>
          <th className={s.mobile}>{t('userTable:price')}</th>
          <th className={s.mobile}>{t('userTable:views')}</th>
          <th>{t('userTable:actions')}</th>
        </tr>
      </thead>

      <tbody>
        {filteredAds.map((adv) => (
          <tr key={adv.id}>
            <td className={s.personal_id}>{adv.id}</td>
            <td
              style={{ cursor: 'pointer' }}
              onClick={() => router.push(`/adv/${adv.id}`)}
            >
              {adv.title}
            </td>
            <td className={s.mobile}>{priceDisplayFormat({ priceType: adv?.price_type, price: adv.price })}</td>
            <td className={s.mobile}>
              <div className={s.personal_card_rating}>
                {Math.round(adv.views / 2)}
              </div>
            </td>
            <td>
              <div className={s.personal_ads_card_actions}>
                <ActionColumnComponent ad={adv} setAds={setUserAds} />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

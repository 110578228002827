import React from 'react';
import s from '@/styles/shared/adv/CategoryModal.module.scss';
import { useRouter } from 'next/router';
import i18n from '@/lib/i18n';

function CategoryModal({ categories, onClose }) {
  const { locale } = useRouter();
  return (
    <div className={s.block_modal_category}>
      <div className={s.modal_category}>
        <div className={s.category_title}>
          <img
            src="/assets/icons/filter_arrow_left.svg"
            alt="close"
            onClick={() => onClose('')}
          />
          <h4>{i18n.selectCategory[locale]}</h4>
        </div>
        <div className={s.category_list}>
          {categories.map((category) => (
            <div
              key={category.id}
              className={s.category}
              onClick={() => onClose('SUB_CATEGORIES', category.sub_categories, category.id)}
            >
              <img src={category.icon} alt="icon_error" />

              <p>{category.title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CategoryModal;

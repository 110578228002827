import React, { useEffect, useState } from 'react';
import SelectOptions from '@/components/uiComp/createProduct/SelectOptions';
import CheckboxInputGroup from '@/components/uiComp/createProduct/CheckboxInput';
import { useRouter } from 'next/router';
import s from './Work.module.scss';
import i18n from '../../../lib/i18n';

export default function SearchWork({ onSearchWorkDataChange, defaultValue }) {
  const [selectedSchedule, setSelectedSchedule] = useState('');
  const [experience, setExperience] = useState('');
  const { locale } = useRouter();
  useEffect(() => {
    if (defaultValue) {
      setSelectedSchedule(defaultValue.schedule);
      setExperience(defaultValue.experience);
    }
  }, [defaultValue]);
  useEffect(() => {
    const formData = {
      experience,
      schedule: selectedSchedule,
    };
    onSearchWorkDataChange(formData);
  }, [experience, selectedSchedule]);

  return (
    <div className={s.container}>
      <div className={s.first_block}>
        <SelectOptions
          title={i18n.categories.workExperience[locale]}
          options={[
            i18n.categories.withoutExperienceJob[locale],
            i18n.categories.moreThanOneYear[locale],
            i18n.categories.moreThan3Years[locale],
            i18n.categories.moreThan5Years[locale],
            i18n.categories.moreThan10Years[locale],
          ]}
          selectedOption={experience}
          onSelectOption={setExperience}
        />
      </div>
      <CheckboxInputGroup
        title={i18n.categories.workSchedule[locale]}
        options={[
          {
            label: i18n.categories.fullDay[locale],
            isChecked: selectedSchedule === 'Полный день',
            onChange: () => setSelectedSchedule('Полный день'),
          },
          {
            label: i18n.categories.partTime[locale],
            isChecked: selectedSchedule === 'Неполный день',
            onChange: () => setSelectedSchedule('Неполный день'),
          },
          {
            label: i18n.categories.flexible[locale],
            isChecked: selectedSchedule === 'Плавающий',
            onChange: () => setSelectedSchedule('Плавающий'),
          },
          {
            label: '5/2',
            isChecked: selectedSchedule === '5/2',
            onChange: () => setSelectedSchedule('5/2'),
          },
          {
            label: '6/1',
            isChecked: selectedSchedule === '6/1',
            onChange: () => setSelectedSchedule('6/1'),
          },
        ]}
      />
    </div>
  );
}

import React, { FC, ReactNode, useMemo } from 'react';
import Link from 'next/link';
import slugify from '@sindresorhus/slugify';
import { useCategories } from '@/modules/categories';
import { IAd } from '@/modules/ads/types';


interface IAdLinkProps {
  ad: IAd;
  children: ReactNode;
  className?: string;
}
export const AdLink: FC<IAdLinkProps> = ({ ad, children, className }) => {
  const { getCategoryByCode } = useCategories();

  const { category_codename: category, sub_category_codename: subcategory } = ad;
  const categoryItem = useMemo(() => getCategoryByCode(category), [category]);
  const subCategoryItem = useMemo(() => getCategoryByCode(category, subcategory), [category, subcategory]);


  const categoryPath = categoryItem?.codename;
  const subCategoryPath = subCategoryItem?.codename;
  const adSlug = ad.title ? slugify(ad.title) : null;

  const href = `/adv/${categoryPath}${subCategoryPath ? `/${subCategoryPath}` : ''}/${adSlug}_${ad.id}`;

  return (
    <Link className={className} href={href}>
      {children}
    </Link>
  );
};

import React, { FC } from 'react';
import { useRouter } from 'next/router';
import i18n from '@/lib/i18n';
import { IAd } from '@/modules/ads/types';
import { SvgIcon } from '@/modules/shared/components';
import { useMyInactiveAdsPageContext } from './my-inactive-ads-page.provider';

type ActionColumnProps = {
  ad: IAd;
  setAds: React.Dispatch<React.SetStateAction<IAd[]>>;
};

export const ActionColumn: FC<ActionColumnProps> = ({ ad, setAds }) => {
  const { locale } = useRouter();
  const activateIconTooltip = i18n.modal.delete.Activate[locale];
  const { activateAd } = useMyInactiveAdsPageContext();

  const handleClick = () => {
    activateAd(ad.id);
    setAds((prev) => prev.filter((el) => el.id !== ad.id));
  };

  return (
    <button
      type="button"
      title={activateIconTooltip}
      data-tippy-interactive
      onClick={handleClick}
      style={{ fontSize: '24px' }}
    >
      <SvgIcon name="eye-opened" />
    </button>
  );
};

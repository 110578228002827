import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { invert, last, omit } from 'lodash';
import { Box } from '@mui/material';
import s from '@/styles/pages/catalog/SubCategory.module.scss';
import MenuPaths from '@/components/shared/layout/Buttons/MenuPaths';
import { useRouter } from 'next/router';
import { subCategoryPaths, categoryPaths } from '@/lib/categoryPaths';
import { getRussianUnderNameBySlug } from '@/lib/categoryUrls';
import { ItemLoader, NotFound } from '@/modules/shared/components';
import { useCategories } from '@/modules/categories';
import { useInfiniteListRef, useSetQueryParams } from '@/modules/shared/hooks';
import { useGlobalData } from '@/context/GlobalContext';
import { modalTypeParam } from '@/modules/auth/consts';
import { Seo } from '@/modules/seo';
import { useSearchParams } from 'next/navigation';
import useTranslation from 'next-translate/useTranslation';
import { Filter } from '../filter';
import { AdMiniCard } from '../ad-mini-card';
import { OrderSelector } from '../order-selector';
import { subcategoryPaths } from './sub-category-paths.const';
import { useAdsInfiniteListQuery } from '../../queries';


const getRussianNameBySlug = (slug) => invert(subcategoryPaths)[slug] || slug;
const getRussianCategoryBySlug = (slug) => invert(categoryPaths)[slug] || slug;


export const AdsInfiniteList = ({ order, onOrderChange, cityId }) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const searchParams = useSearchParams();
  const { locale, query } = router;
  const { getCategoryByCode } = useCategories();
  const {
    category: categorySlug,
    subcategory: subCategorySlug,
    undersubcategory: underSubCategorySlug,
  } = query;

  const categoryItem = getCategoryByCode(categorySlug);
  const subCategoryItem = getCategoryByCode(categorySlug, subCategorySlug);
  const underSubCategoryItem = getCategoryByCode(categorySlug, subCategorySlug, underSubCategorySlug);

  const { setQuantityAd } = useGlobalData();

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const filters = useMemo(() => ({
    ...omit(query, ['category', 'subcategory', 'undersubcategory', modalTypeParam]),
    city: searchParams.get('city') || cityId || undefined,
    category: categoryItem?.id,
    sub_category: subCategoryItem?.id,
    under_sub_category: underSubCategoryItem?.id,
  }), [cityId, query, categoryItem, subCategoryItem, underSubCategoryItem, searchParams]);

  const handleToggleFilter = useCallback(() => {
    setIsFilterVisible((current) => !current);
  }, []);

  const setQueryParams = useSetQueryParams();
  const onFilterChange = useCallback(
    (data) => {
      setIsFilterVisible(false);
      if (data.city && data.city === cityId) {
        setQueryParams({
          ...data,
          city: undefined,
        });
      } else {
        setQueryParams(data);
      }
    },
    [setQueryParams, cityId],
  );

  const fullSubCategoryPath = `${categorySlug}/${subCategorySlug}`;
  const {
    russianSubCategoryName,
    russianCategoryName,
    russianUnderSubCategory,
  } = useMemo(() => ({
    russianSubCategoryName: invert(subCategoryPaths)[fullSubCategoryPath]
        || getRussianNameBySlug(subCategorySlug),
    russianCategoryName: getRussianCategoryBySlug(categorySlug),
    russianUnderSubCategory: getRussianUnderNameBySlug(underSubCategorySlug),
  }), [underSubCategorySlug, categorySlug, fullSubCategoryPath, subCategorySlug]);

  const {
    hasMore,
    fetchMore,
    pages,
    isLoading,
  } = useAdsInfiniteListQuery({
    order,
    filters,
  });
  const lastPage = last(pages || []);
  const lastListItemRef = useInfiniteListRef(hasMore, fetchMore, isLoading);

  useEffect(() => {
    setQuantityAd(pages);
  }, [pages, setQuantityAd]);

  return (
    <>
      <Seo />
      <section className={s.container}>
        <MenuPaths
          category={categoryItem?.codename}
          subcategory={subCategoryItem?.codename}
          undersubcategory={underSubCategoryItem?.codename}
        />
        <div className={s.subcategory_header}>
          {filters.search ? (
            <div>
              <h1>
                {t('searchBlock.searchByRequest')}
                {' '}
                &quot;
                {filters.search}
                &quot;
              </h1>
              <span>{lastPage?.count}</span>
            </div>
          ) : (
            <div>
              <h1>{underSubCategoryItem?.title || subCategoryItem?.title || categoryItem?.title || t('searchBlock.allAds')}</h1>
              <span>{lastPage?.count}</span>
            </div>
          )}
          <img
            src="/assets/icons/adminAdvicon.svg"
            onClick={handleToggleFilter}
            alt="qwe"
          />
        </div>
        <div
          className={`${s.container_subcategory} ${
            isFilterVisible ? s.visibleFilter : ''
          }`}
        >
          <div className={`${s.filterComponentContainer}`}>
            <Filter
              key={cityId}
              category={categoryItem}
              subcategory={subCategoryItem}
              underSubCategory={underSubCategoryItem}
              onChange={onFilterChange}
              defaultValue={filters}
            />
          </div>
          <div className={s.subcategory_cards}>
            <OrderSelector value={order} onChange={onOrderChange} />
            <div className={s.subcategory_advs_cards}>
              {pages.map((page, i) => (
                <Fragment key={i}>
                  {page.data.map((product, j) => (
                    <Fragment key={product.id}>
                      <AdMiniCard product={product} />
                      {i + 1 === pages.length && page.data.length === j + 1 ? (
                        <div ref={lastListItemRef} />
                      ) : null}
                    </Fragment>
                  ))}
                </Fragment>
              ))}
              {!isLoading ? null : (
                <Box display="flex" justifyContent="center">
                  <ItemLoader isLoading size="50px" />
                </Box>
              )}
              {isLoading || pages?.[0]?.data?.length > 0 ? null : <NotFound />}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

import React from 'react';
import s from '@/styles/shared/adv/CategoryModal.module.scss';
import i18n from '@/lib/i18n';
import { useRouter } from 'next/router';
import { translateUnderSubCategory } from '@/lib/categoryTranslations';

function UnderSubCategory({
  underSubCategories,
  onClose,
  categoryId,
  subCategoryId,
  subCategories,
}) {
  const { locale } = useRouter();
  return (
    <div className={s.block_modal_category}>
      <div className={s.modal_category}>
        <div className={s.category_title}>
          <img
            src="/assets/icons/filter_arrow_left.svg"
            alt="back"
            onClick={() => onClose('SUB_CATEGORIES', subCategories, categoryId)}
          />
          <h4>{i18n.selectCategory[locale]}</h4>
        </div>
        <div className={s.category_list}>
          {underSubCategories.map((underSub) => (
            <div
              key={underSub.id}
              className={s.category}
              onClick={() => onClose('SOME_MOD', [], categoryId, subCategoryId, underSub.id)}
            >
              <p>
                {underSub.title}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default UnderSubCategory;

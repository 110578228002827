import React, { useMemo } from 'react';
import LocationSubCate from '@/components/uiComp/catalog/LocationSubCate';
import ToggleButtons from '@/components/uiComp/createProduct/ToggleButtons';
import MoreParametres from '@/components/uiComp/catalog/MoreParametres';
import PriceSlider from '@/components/uiComp/catalog/PriceSlider';
import FilterBtn from '@/components/uiComp/catalog/FilterBtn';
import useTranslation from 'next-translate/useTranslation';
import { useFilterFormState } from '@/modules/shared/hooks';
import s from './BuyEstateFilters.module.scss';
import DescriptionSubCate from '../../uiComp/catalog/DescriptionSubCate';
import FilterSubCategoryCloth from '../../uiComp/catalog/FilterSubCategoryCloth';

function BuyEstateFilters({
  category,
  subcategory,
  underSubCategory,
  getFilteringData,
  defaultValue,
}) {
  const [form] = useFilterFormState([
    'swimming_pool',
    'search',
    'district',
    'number_of_rooms',
    'type_housing',
    'min_price',
    'max_price',
    'city',
  ], defaultValue, getFilteringData);
  const { t } = useTranslation('');

  const handleBtnFiltering = () => {
    getFilteringData(form.query);
  };

  // Тип жилья
  const typeHouse = useMemo(() => [
    'town', 'apartment', 'villa']
    .map((el) => t(`category-parameters:real-estate.type_housing.values.${el}`)), [t]);

  // Количество комнат
  const typeRoom = useMemo(() => [
    'studio', 'one_room', 'two_rooms', 'three_rooms', 'four_rooms', 'five_or_more_rooms']
    .map((el) => t(`category-parameters:real-estate.number_of_rooms.values.${el}`)), [t]);

  return (
    <section className={s.container}>
      <div className={s.filter_block}>
        <FilterSubCategoryCloth
          category={category}
          subcategory={subcategory}
          undersubcategory={underSubCategory}
        />
        <LocationSubCate
          selectedDistrict={form.value.district}
          onSelectDistrict={form.setter.district}
          onSelectCity={form.setter.city}
          selectedCity={form.value.city}
        />
        {/* Тип жилья */}
        <MoreParametres
          title={t('category-parameters:real-estate.type_housing.label')}
          options={typeHouse}
          selected={form.value.type_housing}
          onSelect={form.setter.type_housing}
        />
        {/* Количество комнат */}
        <MoreParametres
          title={t('category-parameters:real-estate.number_of_rooms.label')}
          options={typeRoom}
          selected={form.value.number_of_rooms}
          onSelect={form.setter.number_of_rooms}
        />
        {/* Наличие бассейна */}
        <ToggleButtons
          title={t('category-parameters:real-estate.swimming_pool.label')}
          selectedStatus={form.value.swimming_pool}
          onSelectStatus={form.setter.swimming_pool}
        />
        <PriceSlider
          minPrice={form.value.min_price}
          maxPrice={form.value.max_price}
          setMinPrice={form.setter.min_price}
          setMaxPrice={form.setter.max_price}
        />
        <DescriptionSubCate description={form.value.search} setDescription={form.setter.search} />
        <FilterBtn handleBtnFiltering={handleBtnFiltering} />
      </div>
    </section>
  );
}

export default BuyEstateFilters;

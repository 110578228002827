import React, { useState } from 'react';
import s from '@/styles/uiComp/ToggleButtons.module.scss';

export default function TypeButtons({ title, one, two }) {
  const [selectedStatus, setSelectedStatus] = useState('Да');

  const handleSelectStatus = (status) => {
    setSelectedStatus(status);
  };

  return (
    <div className={s.form_status_event}>
      <h4>{title}</h4>
      <div className={s.status_event_btns}>
        <button
          type="button"
          className={selectedStatus === 'Да' ? s.btn_selected : s.btn_adult}
          onClick={() => handleSelectStatus('Да')}
        >
          {one}
        </button>
        <button
          type="button"
          className={selectedStatus === 'Нет' ? s.btn_child_selected : s.btn_child}
          onClick={() => handleSelectStatus('Нет')}
        >
          {two}
        </button>
      </div>
    </div>
  );
}

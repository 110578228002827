import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const API_URL = process.env.NEXT_PUBLIC_API_URL;

function useUserProfile(id) {
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getUserProfile = useCallback(async () => {
    if (id) {
      try {
        const response = await axios.get(`${API_URL}/api/users/profile/${id}/`);
        setUserProfile(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }
  }, [id, setUserProfile, setLoading, setError]);

  useEffect(() => {
    getUserProfile();
  }, [getUserProfile]);

  return {
    userProfile, loading, error, getUserProfile,
  };
}

export default useUserProfile;

import React, { useState } from 'react';
import s from '@/styles/uiComp/Color.module.scss';
import { useRouter } from 'next/router';
import i18n from '../../../lib/i18n';

export default function Colors({ selectedColor, onColorSelect }) {
  const [hex, setHex] = useState(null);
  const { locale } = useRouter();

  const colors = [
    { name: 'darkGray', hex: '#808080' },
    { name: 'blue', hex: '#414BB2' },
    { name: 'beige', hex: '#DBCA95' },
    { name: 'black', hex: '#000000' },
    { name: 'birch', hex: '#10A689' },
    { name: 'red', hex: '#F14725' },
    { name: 'ping', hex: '#F2439B' },
    { name: 'purple', hex: '#652CB3' },
    { name: 'sky', hex: '#6AD9E6' },
    { name: 'orange', hex: '#FAC712' },
    { name: 'yellow', hex: '#FEF445' },
    { name: 'gray', hex: '#D2C7C7' },
    { name: 'gold', hex: '#ECC819' },
    { name: 'bard', hex: '#9E3D3D' },
    { name: 'white', hex: '#FFF' },
    { name: 'lightGray', hex: '#F2F2F2' },
  ];

  const handleColorSelect = (color, hex) => {
    onColorSelect(selectedColor === color ? null : color);
    setHex(hex);
  };

  return (
    <section className={s.container}>
      <h3>{i18n.createAdvFilds.default.color[locale]}</h3>
      <div className={s.colors}>
        {colors.map((color) => (
          <span
            key={color.name}
            style={{ backgroundColor: color.hex }}
            className={`${s.color_span} ${
              selectedColor === color.name ? s.selected_color : ''
            } ${color.name === 'white' ? s.white_color : ''} ${
              color.name === 'lightGray' ? s.lightGray_color : ''
            } ${
              selectedColor === color.name && color.name === 'black'
                ? s.black_color
                : ''
            }`}
            onClick={() => handleColorSelect(color.name, color.hex)}
          />
        ))}
      </div>
    </section>
  );
}

import React from 'react';
import s from '@/styles/uiComp/CheckboxInput.module.scss';
import { useRouter } from 'next/router';
import i18n from '../../../lib/i18n';

function CheckboxInput({ label, isChecked, onChange }) {
  return (
    <div className={s.inputContainer}>
      <div className={s.inputField}>{label}</div>
      <input type="checkbox" checked={isChecked} onChange={onChange} className={s.checkbox} />
    </div>
  );
}

function CheckboxInputGroup({ options, title }) {
  const { locale } = useRouter();
  return (
    <section className={s.container}>
      <h3>{title || i18n.advDetail.moreParams[locale]}</h3>
      <div className={s.inputs}>
        {options.map((option, index) => (
          <CheckboxInput
            key={index}
            label={option.label}
            isChecked={option.isChecked}
            onChange={option.onChange}
          />
        ))}
      </div>
    </section>
  );
}

export default CheckboxInputGroup;

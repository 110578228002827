import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { getCookie } from '@/components/cookie/cookieHelpers';
import { useAuthContext } from '@/modules/auth';

const API_URL = process.env.NEXT_PUBLIC_API_URL;

const useBanned = () => {
  const [banned, setBanned] = useState([]);
  const [loading, setLoading] = useState(true);

  const { isAuthenticated } = useAuthContext();
  const getBannedList = useCallback(async () => {
    if (isAuthenticated) {
      const token = getCookie('access_token');
      try {
        const response = await axios.get(
          `${API_URL}/api/announcement/banned-list/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setBanned(response.data.results);
      } catch (error) {
        console.error(
          'Ошибка при получении списка забаненных объявлений:',
          error,
        );
      } finally {
        setLoading(false);
      }
    }
  }, [isAuthenticated, setLoading, setBanned]);

  useEffect(() => {
    getBannedList();
  }, [getBannedList]);

  return { banned, loading, setBanned };
};

export default useBanned;

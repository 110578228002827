import { toast } from 'react-toastify';
import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import i18n from '@/lib/i18n';
import { Me } from '@/modules/me/types';
import { api } from '@/modules/shared/api';
import { IAd } from '../../types';

const useMyAds = () => {
  const { locale } = useRouter();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState<Me>(null);

  const fetchUserData = async () => {
    try {
      const response = await api.get('/api/users/profile/');
      setUserData(response.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const reasons = useMemo(() => [
    'sold_on_bazaar',
    'sold_somewhere_else',
    'other',
    'duplicate_information',
    'inappropriate_content',
  ].map((reason) => ({
    value: reason,
    label: i18n.complaintModal[reason][locale],
  })), [locale]);

  const deactivateMyAd = async (
    id: number,
    body: Record<string, string>,
  ) => {
    try {
      const response = await api.put(
        `/api/announcement/delete/${id}/`,
        body,
      );
      if (response.status !== 204) {
        throw new Error('Произошла ошибка при деактивации!');
      }

      const updatedActiveAnnouncements = userData?.announcements.filter(
        (announcement) => announcement.id !== id,
      );
      const updatedNotActiveAnnouncements = userData?.not_active_announcements
        .concat([response.data]);
      setUserData((prevData) => ({
        ...prevData,
        announcements: updatedActiveAnnouncements,
        not_active_announcements: updatedNotActiveAnnouncements,
      }));
      fetchUserData();

      toast.success(i18n.toast.Thedeactivated[locale]);
    } catch (err) {
      console.log('Error deactivating announcement:', err);
    }
  };

  const activateMyAd = async (id: number) => {
    try {
      const response = await api.patch(
        `/api/announcement/update/${id}/`,
        { is_active: true },
      );

      if (response.status !== 200) {
        throw new Error('Произошла ошибка при активации!');
      }

      const updatedNotActiveAnnouncements = userData?.not_active_announcements.filter(
        (announcement: IAd) => announcement.id !== id,
      );
      const updatedActiveAnnouncements = userData?.announcements.concat([
        response.data,
      ]);
      setUserData((prevData) => ({
        ...prevData,
        announcements: updatedActiveAnnouncements,
        not_active_announcements: updatedNotActiveAnnouncements,
      }));
      fetchUserData();

      toast.success(i18n.toast.Event[locale]);
    } catch (err) {
      console.log('Error activating announcement:', err);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return {
    reasons,
    deactivateMyAd,
    loading,
    error,
    activateMyAd,
    userData,
  };
};

export default useMyAds;

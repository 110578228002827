import React, { useState, useEffect, useMemo } from 'react';
import ToggleButtons from '@/components/uiComp/createProduct/ToggleButtons';
import useTranslation from 'next-translate/useTranslation';
import SelectOptions from '@/components/uiComp/createProduct/SelectOptions';
import s from './Estate.module.scss';

export default function BuyEstate({ onByEstateDataChange, defaultValue }) {
  const { t } = useTranslation('');
  const [type_housing, setType_housing] = useState('');
  const [number_of_rooms, setNumber_of_rooms] = useState('');
  const [swimming_pool, setSwimming_pool] = useState(false);


  useEffect(() => {
    if (defaultValue) {
      setType_housing(defaultValue.type_housing);
      setSwimming_pool(defaultValue.swimming_pool);
      setNumber_of_rooms(defaultValue.number_of_rooms);
    }
  }, [defaultValue]);

  useEffect(() => {
    const formData = {
      type_housing,
      number_of_rooms,
      swimming_pool,
    };
    onByEstateDataChange(formData);
  }, [
    type_housing,
    number_of_rooms,
    swimming_pool,
  ]);

  // Тип жилья
  const optionsOfEstate = useMemo(() => [
    'other', 'town', 'apartment', 'villa']
    .map((el) => t(`category-parameters:real-estate.type_housing.values.${el}`)), [t]);

  // Количество комнат
  const optionsOfRooms = useMemo(() => [
    'studio', 'one_room', 'two_rooms', 'three_rooms', 'four_rooms', 'five_or_more_rooms']
    .map((el) => t(`category-parameters:real-estate.number_of_rooms.values.${el}`)), [t]);

  return (
    <div className={s.container}>
      {/* Тип жилья */}
      <SelectOptions
        title={t('category-parameters:real-estate.type_housing.label')}
        options={optionsOfEstate}
        selectedOption={type_housing}
        onSelectOption={setType_housing}
      />
      {/* Количество комнат */}
      <SelectOptions
        title={t('category-parameters:real-estate.number_of_rooms.label')}
        options={optionsOfRooms}
        selectedOption={number_of_rooms}
        onSelectOption={setNumber_of_rooms}
      />
      {/* Наличие бассейна */}
      <ToggleButtons
        title={t('category-parameters:real-estate.swimming_pool.label')}
        selectedStatus={swimming_pool}
        onSelectStatus={setSwimming_pool}
      />
    </div>
  );
}

import React from 'react';
import LocationSubCate from '@/components/uiComp/catalog/LocationSubCate';
import MoreParametres from '@/components/uiComp/catalog/MoreParametres';
import MoreParametresState from '@/components/uiComp/catalog/MoreParametresState';
import PriceSlider from '@/components/uiComp/catalog/PriceSlider';
import FilterBtn from '@/components/uiComp/catalog/FilterBtn';
import i18n from '@/lib/i18n';
import { useRouter } from 'next/router';
import { useFilterFormState } from '@/modules/shared/hooks';
import s from '../auto/AutoFilters.module.scss';
import DescriptionSubCate from '../../uiComp/catalog/DescriptionSubCate';
import FilterSubCategoryCloth from '../../uiComp/catalog/FilterSubCategoryCloth';
import Colors from '../../uiComp/createProduct/Colors';
import SelectOptions from '../../uiComp/createProduct/SelectOptions';

function Clothes({
  category,
  subcategory,
  getFilteringData,
  underSubCategory,
  defaultValue,
}) {
  const [form] = useFilterFormState([
    'color',
    'district',
    'state',
    'min_price',
    'max_price',
    'search',
    'brand',
    'city',
    'size',
  ], defaultValue, getFilteringData);
  const { locale } = useRouter();

  const brands = [
    i18n.createAdvFilds.things.Otherbrand[locale],
    'Adidas',
    'Aldo',
    'American Eagle Outfitters',
    'Armani',
    'ASOS',
    'Audemars Piguet',
    'Banana Republic',
    'Bogner',
    'Breguet',
    'Brunello Cucinelli',
    'Burberry',
    'C&A',
    'Calvin Klein',
    'Cartier',
    'Chanel',
    'Chopard',
    'Chow Tai Fook',
    'Christian Louboutin',
    'Coach',
    'Cole Haan',
    'Desigual',
    'Diesel',
    'Dior',
    'Dolce & Gabbana',
    'Elie Saab',
    'Elie Tahari',
    'ESCADA',
    'Fendi',
    'Forever 21',
    'Foot Locker Inc.',
    'Fossil',
    'Furla',
    'G-star',
    'GAP',
    'Gucci',
    'H&M',
    'Hermès',
    'Hugo Boss',
    'IWC Schaffhausen',
    'Jaeger-Le Coultre',
    'Jimmy Choo',
    'Lacoste',
    'Levi’s',
    'Lululemon',
    'Louis Vuitton',
    'Macy’s',
    'Manolo Blahnik',
    'Max Mara',
    'Michael Kors',
    'Moncler',
    'Net-a-Porter',
    'New Balance',
    'New Look',
    'Next',
    'Nike',
    'Nine West',
    'Nordstrom',
    'Oakley',
    'Off White',
    'Old Navy',
    'Omega',
    'Patek Philippe',
    'Patagonia',
    'Primark',
    'Prada',
    'Polo Ralph Lauren',
    'Puma',
    'Ray-Ban',
    'Rolex',
    'Salvatore Ferragamo',
    'Skechers',
    'Sisley',
    'Steve Madden',
    'Stuart Weitzman',
    'Swarovski',
    'Swatch',
    'Ted Baker',
    'The North Face',
    'Tiffany & Co.',
    'Tissot',
    'TOD’s',
    'Tom Ford',
    'Tommy Hilfiger',
    'Topshop',
    'Tory Burch',
    'TJ Maxx',
    'Under Armour',
    'UNIQLO',
    'Urban Outfitters',
    'Valentino',
    'Vacheron Constantin',
    'Vera Wang',
    'Victoria’s Secret',
    'Zalando',
    'Zara',
  ];
  const stateList = [
    i18n.filters.default.Excellent[locale],
    i18n.filters.default.good[locale],
    i18n.filters.default.Satisfactory[locale],
  ];
  const stateTitle = i18n.filters.default.States[locale];

  const handleBtnFiltering = () => {
    getFilteringData(form.query);
  };

  const menSizes = [
    '42-44 (S)',
    '44-46 (M)',
    '48-50 (L)',
    '52-54 (XL)',
    '54-56 (XXL)',
    '56-58 (XXXL)',
    i18n.createAdvFilds.things.onesize[locale],
  ];
  const womenSizes = [
    '38-40 (XS)',
    '42-44 (S)',
    '44-46 (M)',
    '46-48 (L)',
    '48-50 (XL)',
    '50-52 (XXL)',
    '52-54 (XXXL)',
    i18n.createAdvFilds.things.onesize[locale],
  ];

  return (
    <section className={s.container}>
      <div className={s.filter_block}>
        <FilterSubCategoryCloth
          category={category}
          subcategory={subcategory}
          undersubcategory={underSubCategory}
        />
        <LocationSubCate
          selectedDistrict={form.value.district}
          onSelectDistrict={form.setter.district}
          selectedCity={form.value.city}
          onSelectCity={form.setter.city}
        />
        <MoreParametres
          title={stateTitle}
          options={stateList}
          selected={form.value.state}
          onSelect={form.setter.state}
        />
        {subcategory === 'Мужская одежда' && (
          <MoreParametresState
            title={i18n.createAdvFilds.things.size[locale]}
            options={menSizes}
            selected={form.value.size}
            onSelect={form.setter.size}
          />
        )}
        {subcategory === 'Женская одежда' && (
          <MoreParametresState
            title={i18n.createAdvFilds.things.size[locale]}
            options={womenSizes}
            selected={form.value.size}
            onSelect={form.setter.size}
          />
        )}
        <Colors selectedColor={form.value.color} onColorSelect={form.setter.color} />
        <SelectOptions
          title={i18n.createAdvFilds.things.brand[locale]}
          options={brands}
          selectedOption={form.value.brand}
          onSelectOption={form.setter.brand}
        />

        <PriceSlider
          minPrice={form.value.min_price}
          maxPrice={form.value.max_price}
          setMinPrice={form.setter.min_price}
          setMaxPrice={form.setter.max_price}
        />
        <DescriptionSubCate description={form.value.search} setDescription={form.setter.search} />
        <FilterBtn handleBtnFiltering={handleBtnFiltering} />
      </div>
    </section>
  );
}

export default Clothes;

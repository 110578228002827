import React, { useCallback, useEffect } from 'react';
import { useSetQueryParams } from '@/modules/shared/hooks';
import { useRouter } from 'next/router';
import { useHomeCity } from '@/modules/locations/hooks';
import { ScreenLoader } from '@/modules/shared/components';
import { useCategoriesContext } from '@/modules/categories';
import { AdsInfiniteList } from './ads-infinity-list.component';

export const AdsInfiniteListPage = () => {
  const setQueryParams = useSetQueryParams();
  const { isLoading: categoriesLoading } = useCategoriesContext();
  const { cityId, isLoading: cityLoading } = useHomeCity();

  const handleOrderChange = useCallback((value) => {
    setQueryParams({ order: value });
  }, [setQueryParams]);
  const { order } = useRouter().query;

  if (cityLoading || categoriesLoading) {
    return <ScreenLoader />;
  }

  return (
    <AdsInfiniteList
      cityId={cityId}
      order={order || '-created_at'}
      onOrderChange={handleOrderChange}
    />
  );
};

import React from 'react';
import LocationSubCate from '@/components/uiComp/catalog/LocationSubCate';
import MoreParametres from '@/components/uiComp/catalog/MoreParametres';
import PriceSlider from '@/components/uiComp/catalog/PriceSlider';
import FilterBtn from '@/components/uiComp/catalog/FilterBtn';
import i18n from '@/lib/i18n';
import { useRouter } from 'next/router';
import { useFilterFormState } from '@/modules/shared/hooks';
import s from '../auto/AutoFilters.module.scss';
import DescriptionSubCate from '../../uiComp/catalog/DescriptionSubCate';
import FilterSubCategoryCloth from '../../uiComp/catalog/FilterSubCategoryCloth';
import Colors from '../../uiComp/createProduct/Colors';
import SelectOptions from '../../uiComp/createProduct/SelectOptions';

function Shoes({
  category,
  subcategory,
  getFilteringData,
  underSubCategory,
  defaultValue,
}) {
  const [form] = useFilterFormState([
    'color',
    'district',
    'state',
    'min_price',
    'max_price',
    'search',
    'gender',
    'brand',
    'city',
    'size',
  ], defaultValue, getFilteringData);
  const { locale } = useRouter();

  const brands = [
    i18n.createAdvFilds.things.Otherbrand[locale],
    'Adidas',
    'Alexander McQueen',
    'Alexander Wang',
    'Anya Hindmarch',
    'ASICS',
    'Balenciaga',
    'Balmain',
    'Birkenstock',
    'Bottega Veneta',
    'Brooks',
    'Burberry',
    'Céline',
    'Chanel',
    'Christian Louboutin',
    'Clarks',
    'Coach',
    'Cole Haan',
    'Converse',
    'Dolce & Gabbana',
    'Dolce Vita',
    'Dr. Martens',
    'Fendi',
    'Fila',
    'Giuseppe Zanotti',
    'Givenchy',
    'Gucci',
    'Hugo Boss',
    'Isabel Marant',
    'Jil Sander',
    'Jimmy Choo',
    'Keds',
    'Kenzo',
    'Lacoste',
    'Lanvin',
    'Loeffler Randall',
    'Loewe',
    'Marni',
    'Marc Jacobs',
    'Max Mara',
    'Michael Kors',
    'Miu Miu',
    'Moncler',
    'Moschino',
    'New Balance',
    'Nike',
    'Off-White',
    'Phillip Lim',
    'Prabal Gurung',
    'Prada',
    'Proenza Schouler',
    'Puma',
    'Rag & Bone',
    'Ralph Lauren',
    'Rebecca Minkoff',
    'Reebok',
    'Roger Vivier',
    'Saint Laurent',
    'Salvatore Ferragamo',
    'Sergio Rossi',
    'Skechers',
    'Sophie Theallet',
    'Stella McCartney',
    'Steve Madden',
    'Stuart Weitzman',
    'Superga',
    'The Row',
    'Thom Browne',
    'Timberland',
    'Tod’s',
    'Tory Burch',
    'UGG',
    'Under Armour',
    'Valentino',
    'Valentino Garavani',
    'Vans',
    'Vera Wang',
    'Versace',
    'Victoria Beckham',
    'Vivienne Westwood',
    'Yeezy',
    'Zara',
  ];
  const stateList = [
    i18n.filters.default.Excellent[locale],
    i18n.filters.default.good[locale],
    i18n.filters.default.Satisfactory[locale],
  ];
  const stateTitle = i18n.filters.default.States[locale];

  const handleBtnFiltering = () => {
    getFilteringData(form.query);
  };

  const manSizes = [
    '40 (7)',
    '40.5 (7.5)',
    '41 (8)',
    '41.5 (8.5)',
    '42 (9)',
    '42.5 (9.5)',
    '43 (10)',
    '43.5 (10.5)',
    '44 (11)',
    '44.5 (11.5)',
    '45 (12)',
    '46 (13)',
    '47 (14)',
  ];
  const womenSizes = [
    '35 (5)',
    '35.5 (5.5)',
    '36 (6)',
    '36.5 (6.5)',
    '37 (7)',
    '37.5 (7.5)',
    '38 (8)',
    '39 (9)',
    '39.5 (9.5)',
    '40/41 (10)',
    '41 (10.5)',
    '41/42 (11)',
    '42 (11.5)',
    '42/43 (12)',
  ];

  return (
    <section className={s.container}>
      <div className={s.filter_block}>
        <FilterSubCategoryCloth
          category={category}
          subcategory={subcategory}
          undersubcategory={underSubCategory}
        />
        <LocationSubCate
          selectedDistrict={form.value.district}
          onSelectDistrict={form.setter.district}
          onSelectCity={form.setter.city}
          selectedCity={form.value.city}
        />
        <MoreParametres
          title={stateTitle}
          options={stateList}
          selected={form.value.state}
          onSelect={form.setter.state}
        />
        {subcategory === 'Мужская обувь' && (
          <MoreParametres
            title={i18n.createAdvFilds.things.size[locale]}
            options={manSizes}
            selected={form.value.size}
            onSelect={form.setter.size}
          />
        )}
        {subcategory === 'Женская обувь' && (
          <MoreParametres
            title={i18n.createAdvFilds.things.size[locale]}
            options={womenSizes}
            selected={form.value.size}
            onSelect={form.setter.size}
          />
        )}
        <Colors selectedColor={form.value.color} onColorSelect={form.setter.color} />
        <SelectOptions
          title={i18n.createAdvFilds.things.brand[locale]}
          options={brands}
          selectedOption={form.value.brand}
          onSelectOption={form.setter.brand}
        />

        <PriceSlider
          minPrice={form.value.min_price}
          maxPrice={form.value.max_price}
          setMinPrice={form.setter.min_price}
          setMaxPrice={form.setter.max_price}
        />
        <DescriptionSubCate description={form.value.search} setDescription={form.setter.search} />
        <FilterBtn handleBtnFiltering={handleBtnFiltering} />
      </div>
    </section>
  );
}

export default Shoes;

import Colors from '@/components/uiComp/createProduct/Colors';
import SelectOptions from '@/components/uiComp/createProduct/SelectOptions';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import s from './Things.module.scss';
import i18n from '../../../lib/i18n';
import {
  translateUnderSubCategory,
} from '../../../lib/categoryTranslations';

export default function Clothing({
  onClothesDataChange,
  subCategoryCodename,
  onChildClothesDataChange,
  defaultValue,
}) {
  const [state, setState] = useState('');
  const [type_of_cloth, setType_of_cloth] = useState('');
  const [size, setSize] = useState('');
  const [color, setColor] = useState('');
  const [brand, setBrand] = useState('');
  const { locale } = useRouter();

  useEffect(() => {
    if (defaultValue) {
      setState(defaultValue.state);
      setSize(defaultValue.size);
      setColor(defaultValue.color);
      setBrand(defaultValue.brand);
      setType_of_cloth(defaultValue.type_of_cloth);
    }
  }, [defaultValue]);

  useEffect(() => {
    const formData = {
      state,
      size,
      brand,
      color,
      type_of_cloth,
    };

    if (
      subCategoryCodename === 'men-clothes'
      || subCategoryCodename === 'women-clothes'
    ) {
      onClothesDataChange(formData);
    } else {
      onChildClothesDataChange(formData);
    }
  }, [state, size, brand, color, type_of_cloth]);

  const childSizes = [
    `50-56 (3 ${i18n.createAdvFilds.default.month[locale]})`,
    `62-68 (6 ${i18n.createAdvFilds.default.months[locale]})`,
    `74-80 (9 ${i18n.createAdvFilds.default.months[locale]})`,
    `86-92 (1-3 ${i18n.createAdvFilds.default.year[locale]})`,
    `98-104 (4 ${i18n.createAdvFilds.default.year[locale]})`,
    `110-122 (5-7 ${i18n.createAdvFilds.default.years[locale]})`,
    `128-152 (8-12 ${i18n.createAdvFilds.default.years[locale]})`,
    `158-164 (14-17 ${i18n.createAdvFilds.default.years[locale]})`,
  ];

  const menSizes = [
    '42-44 (S)',
    '44-46 (M)',
    '48-50 (L)',
    '52-54 (XL)',
    '54-56 (XXL)',
    '56-58 (XXXL)',
  ];
  const typeOfClothesMan = [
    'Кофты и футболки',
    'Пиджаки и костюмы',
    'Джинсы',
    'Брюки',
    'Рубашки',
    'Спортивные костюмы',
    'Шорты',
    'Нижнее белье',
    'Другое',
  ];
  const typeOfClothesWoman = [
    'Платья',
    'Юбки',
    'Джемперы, свитеры, кардиганы',
    'Пиджаки и костюмы',
    'Брюки',
    'Джинсы',
    'Нижнее белье',
    'Свадебные платья',
    'Рубашки и блузки',
    'Топы и футболки',
    'Купальники',
    'Другое',
  ];

  const womenSizes = [
    '38-40 (XS)',
    '42-44 (S)',
    '44-46 (M)',
    '46-48 (L)',
    '48-50 (XL)',
    '50-52 (XXL)',
    '52-54 (XXXL)',
  ];

  const brands = [
    i18n.createAdvFilds.things.Otherbrand[locale],
    'Adidas',
    'Aldo',
    'American Eagle Outfitters',
    'Armani',
    'ASOS',
    'Audemars Piguet',
    'Banana Republic',
    'Bogner',
    'Breguet',
    'Brunello Cucinelli',
    'Burberry',
    'C&A',
    'Calvin Klein',
    'Cartier',
    'Chanel',
    'Chopard',
    'Chow Tai Fook',
    'Christian Louboutin',
    'Coach',
    'Cole Haan',
    'Desigual',
    'Diesel',
    'Dior',
    'Dolce & Gabbana',
    'Elie Saab',
    'Elie Tahari',
    'ESCADA',
    'Fendi',
    'Forever 21',
    'Foot Locker Inc.',
    'Fossil',
    'Furla',
    'G-star',
    'GAP',
    'Gucci',
    'H&M',
    'Hermès',
    'Hugo Boss',
    'IWC Schaffhausen',
    'Jaeger-Le Coultre',
    'Jimmy Choo',
    'Lacoste',
    'Levi’s',
    'Lululemon',
    'Louis Vuitton',
    'Macy’s',
    'Manolo Blahnik',
    'Max Mara',
    'Michael Kors',
    'Moncler',
    'Net-a-Porter',
    'New Balance',
    'New Look',
    'Next',
    'Nike',
    'Nine West',
    'Nordstrom',
    'Oakley',
    'Off White',
    'Old Navy',
    'Omega',
    'Patek Philippe',
    'Patagonia',
    'Primark',
    'Prada',
    'Polo Ralph Lauren',
    'Puma',
    'Ray-Ban',
    'Rolex',
    'Salvatore Ferragamo',
    'Skechers',
    'Sisley',
    'Steve Madden',
    'Stuart Weitzman',
    'Swarovski',
    'Swatch',
    'Ted Baker',
    'The North Face',
    'Tiffany & Co.',
    'Tissot',
    'TOD’s',
    'Tom Ford',
    'Tommy Hilfiger',
    'Topshop',
    'Tory Burch',
    'TJ Maxx',
    'Under Armour',
    'UNIQLO',
    'Urban Outfitters',
    'Valentino',
    'Vacheron Constantin',
    'Vera Wang',
    'Victoria’s Secret',
    'Zalando',
    'Zara',
  ];

  return (
    <div className={s.clothing}>
      {subCategoryCodename === 'men-clothes' && (
        <SelectOptions
          title={i18n.createAdvFilds.default.typeClothes[locale]}
          options={typeOfClothesMan.map((type) => translateUnderSubCategory(type, locale))}
          selectedOption={type_of_cloth}
          onSelectOption={setType_of_cloth}
        />
      )}
      {subCategoryCodename === 'women-clothes' && (
        <SelectOptions
          title={i18n.createAdvFilds.default.typeClothes[locale]}
          options={typeOfClothesWoman.map((type) => translateUnderSubCategory(type, locale))}
          selectedOption={type_of_cloth}
          onSelectOption={setType_of_cloth}
        />
      )}
      <SelectOptions
        title={i18n.createAdvFilds.default.state[locale]}
        options={[
          i18n.createAdvFilds.default.Excellent[locale],
          i18n.createAdvFilds.default.Good[locale],
          i18n.createAdvFilds.default.Satisfactory[locale],
        ]}
        selectedOption={state}
        onSelectOption={setState}
      />
      {subCategoryCodename === 'men-clothes' && (
        <SelectOptions
          title={i18n.createAdvFilds.things.size[locale]}
          options={menSizes}
          selectedOption={size}
          onSelectOption={setSize}
        />
      )}
      {subCategoryCodename === 'women-clothes' && (
        <SelectOptions
          title={i18n.createAdvFilds.things.size[locale]}
          options={womenSizes}
          selectedOption={size}
          onSelectOption={setSize}
        />
      )}
      {subCategoryCodename !== 'women-clothes'
      && subCategoryCodename !== 'men-clothes' ? (
        <SelectOptions
          title={i18n.createAdvFilds.things.size[locale]}
          options={childSizes}
          selectedOption={size}
          onSelectOption={setSize}
        />
        ) : null}
      <Colors selectedColor={color} onColorSelect={setColor} />
      <SelectOptions
        title={i18n.createAdvFilds.things.brand[locale]}
        options={brands}
        selectedOption={brand}
        onSelectOption={setBrand}
      />
    </div>
  );
}

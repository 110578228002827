import AutoFilters from '@/components/catalog/auto/AutoFilters';
import MotoFilters from '@/components/catalog/moto/MotoFilters';
import MopedFilters from '@/components/catalog/moped/MopedFilters';
import React, { memo } from 'react';
import TransfersFilters from '@/components/catalog/transfers/TransfersFilters';
import RentEstateFilters from '@/components/catalog/rentEstate/RentEstateFilters';
import BuyEstateFilters from '@/components/catalog/buyEstate/BuyEstateFilters';
import SummaryJobFilters from '@/components/catalog/summaryJob/SummaryJobFilters';
import VacancyJobFilters from '@/components/catalog/vacancyJob/VacancyJobFilters';
import DynamicService from '@/components/catalog/services/DynamicService';
import AdultClothes from '@/components/catalog/adultclothes/AdultClothes';
import ChildClothes from '@/components/catalog/childclothes/ChildClothes';
import Jawerly from '@/components/catalog/jawerly/Jawerly';
import Trucking from '@/components/catalog/trucking/Trucking';
import TelephoneFilter from '@/components/catalog/telephone/TelephoneFilter';
import AudioFilters from '@/components/catalog/audio/AudioFilters';
import AccessoriesFilter from '@/components/catalog/accessories/AccessoriesFilter';
import Clothes from '@/components/catalog/cloth/Clothes';
import Shoes from '@/components/catalog/shoes/Shoes';
import Watches from './watches.component';
import { DefaultFilter } from './default-filter.component';
import DinamicUnderFilter from '../../../../components/catalog/dinamicUnder/DinamicUnderFilter';

export const Filter = memo(({
  category,
  subcategory,
  underSubCategory,
  onChange,
  defaultValue,
}) => {
  if (subcategory?.codename === 'cars') {
    return (
      <AutoFilters
        category={category}
        subcategory={subcategory}
        getFilteringData={onChange}
        defaultValue={defaultValue}
      />
    );
  } if (subcategory?.codename === 'motorcycles') {
    return (
      <MotoFilters
        category={category}
        subcategory={subcategory}
        getFilteringData={onChange}
        defaultValue={defaultValue}
      />
    );
  } if (subcategory?.codename === 'motorbikes') {
    return (
      <MopedFilters
        category={category}
        subcategory={subcategory}
        getFilteringData={onChange}
        defaultValue={defaultValue}
      />
    );
  } if (
    subcategory?.codename === 'rent-daily'
    || subcategory?.codename === 'rent-longterm'
  ) {
    return (
      <RentEstateFilters
        category={category}
        subcategory={subcategory}
        getFilteringData={onChange}
        defaultValue={defaultValue}
      />
    );
  } if (subcategory?.codename === 'buy') {
    return (
      <BuyEstateFilters
        category={category}
        subcategory={subcategory}
        getFilteringData={onChange}
        defaultValue={defaultValue}
        underSubCategory={underSubCategory}
      />
    );
  } if (subcategory?.codename === 'transfer') {
    return (
      <TransfersFilters
        category={category}
        subcategory={subcategory}
        getFilteringData={onChange}
        defaultValue={defaultValue}
      />
    );
  } if (subcategory?.codename === 'vacancies') {
    return (
      <SummaryJobFilters
        category={category}
        subcategory={subcategory}
        getFilteringData={onChange}
        defaultValue={defaultValue}
        underSubCategory={underSubCategory}
      />
    );
  } if (subcategory?.codename === 'resumes') {
    return (
      <VacancyJobFilters
        category={category}
        subcategory={subcategory}
        getFilteringData={onChange}
        defaultValue={defaultValue}
        underSubCategory={underSubCategory}
      />
    );
  } if (subcategory?.codename === 'repair') {
    return (
      <DinamicUnderFilter
        category={category}
        subcategory={subcategory}
        getFilteringData={onChange}
        underSubCategory={underSubCategory}
        defaultValue={defaultValue}
      />
    );
  } if (
    underSubCategory?.codename === 'watches'
  ) {
    return (
      <Watches
        category={category}
        subcategory={subcategory}
        underSubCategory={underSubCategory}
        getFilteringData={onChange}
        defaultValue={defaultValue}
      />
    );
  } if (
    subcategory?.codename === 'men-shoes'
    || subcategory?.codename === 'women-shoes'
  ) {
    return (
      <Shoes
        category={category}
        subcategory={subcategory}
        underSubCategory={underSubCategory}
        getFilteringData={onChange}
        defaultValue={defaultValue}
      />
    );
  } if (
    subcategory?.codename === 'men-clothes'
    || subcategory?.codename === 'women-clothes'
  ) {
    return (
      <Clothes
        category={category}
        subcategory={subcategory}
        underSubCategory={underSubCategory}
        getFilteringData={onChange}
        defaultValue={defaultValue}
      />
    );
  } if (category?.codename === 'personal-items') {
    if (
      subcategory?.codename === 'beauty-health'
      || subcategory?.codename === 'men-shoes'
    ) {
      return (
        <AdultClothes
          category={category}
          subcategory={subcategory}
          getFilteringData={onChange}
          defaultValue={defaultValue}
          underSubCategory={underSubCategory}
        />
      );
    }
  } if (
    underSubCategory?.codename === 'jewelry'
  ) {
    return (
      <Jawerly
        category={category}
        subcategory={subcategory}
        underSubCategory={underSubCategory}
        getFilteringData={onChange}
        defaultValue={defaultValue}
      />
    );
  } if (subcategory?.codename === 'accessories') {
    if (underSubCategory?.codename === 'jewelry') {
      return (
        <Jawerly
          category={category}
          subcategory={subcategory}
          underSubCategory={underSubCategory}
          getFilteringData={onChange}
          defaultValue={defaultValue}
        />
      );
    }
    return (
      <AccessoriesFilter
        category={category}
        subcategory={subcategory}
        getFilteringData={onChange}
        defaultValue={defaultValue}
        underSubCategory={underSubCategory}
      />
    );
  } if (
    underSubCategory?.codename && [
      'headwear',
      'glasses',
      'belts-suspenders',
      'watches',
      'umbrellas-raincoats',
      'hair-accessories',
      'ties-bowties',
      'socks-stockings-tights',
      'slip-ons-and-espadrilles',
      'other',
    ].includes(underSubCategory.codename)
  ) {
    return (
      <AccessoriesFilter
        category={category}
        subcategory={subcategory}
        getFilteringData={onChange}
        defaultValue={defaultValue}
        underSubCategory={underSubCategory}
      />
    );
  } if (subcategory?.codename === 'children-clothes') {
    return (
      <ChildClothes
        category={category}
        subcategory={subcategory}
        underSubCategory={underSubCategory}
        getFilteringData={onChange}
        defaultValue={defaultValue}
      />
    );
  } if (subcategory?.codename === 'cargo-transportation') {
    return (
      <Trucking
        category={category}
        subcategory={subcategory}
        getFilteringData={onChange}
        defaultValue={defaultValue}
        underSubCategory={underSubCategory}
      />
    );
  } if (
    subcategory?.codename && [
      'children-goods',
      'sport',
      'kitchenware',
      'appliances',
      'furniture',
      'construction',
      'repair',
      'beauty-health',
      'repair-tech',
      'computer-help',
      'equipment-production',
      'photo-video',
      'education',
      'business-services',
      'it-telecom',
      'advertising',
      'cleaning',
      'household-services',
      'tickets',
      'garden',
    ].includes(subcategory.codename)
  ) {
    return (
      <DinamicUnderFilter
        category={category}
        subcategory={subcategory}
        getFilteringData={onChange}
        defaultValue={defaultValue}
        underSubCategory={underSubCategory}
      />
    );
  } if (
    subcategory?.codename && [
      'musical-instruments',
      'hunting-fishing',
      'sport',
      'spare-parts',
      'books',
      'bicycles',
      'kitchenware',
      'appliances',
      'food',
      'plants',
      'furniture',
      'security',
      'it-telecom',
      'garden',
      'passenger-transportation',
      'business-services',
      'handyman',
      'cargo-transportation',
      'household-services',
      'air-vehicles',
      'trucks',
      'water-vehicles',
      'tires,',
      'trailers',
      'equipment',
      'beauty-health',
      'auto-chemistry',
      'tuning',
      'gps',
      'trunk-hitch',
      'accessories',
      'collecting',
      'cats',
      'birds',
      'aquarium',
      'other-pets',
      'pet-supplies',
      'business-equipment',
      'tools',
      'dogs',
      'ready-business',
      'other',
      'resumes',
      'vacancies',
    ].includes(subcategory.codename)
  ) {
    return (
      <DynamicService
        category={category}
        subcategory={subcategory}
        getFilteringData={onChange}
        defaultValue={defaultValue}
      />
    );
  } if (subcategory?.codename === 'phones') {
    return (
      <TelephoneFilter
        category={category}
        subcategory={subcategory}
        getFilteringData={onChange}
        defaultValue={defaultValue}
        underSubCategory={underSubCategory}
      />
    );
  } if (
    subcategory?.codename && [
      'audio-video',
      'computer-goods',
      'consoles-sotfware',
      'laptops',
      'desktops',
      'photo',
      'tablets',
    ].includes(subcategory.codename)
  ) {
    return (
      <AudioFilters
        category={category}
        subcategory={subcategory}
        getFilteringData={onChange}
        defaultValue={defaultValue}
        underSubCategory={underSubCategory}
      />
    );
  } if (
    category?.codename && [
      'vehicles-rental',
      'services',
      'personal-items',
      'home',
      'spare-parts',
      'electronics',
      'hobbies',
      'animals',
      'business',
      'real-estate',
      'job',
    ].includes(category.codename)
  ) {
    return (
      <DynamicService
        category={category}
        subcategory={subcategory}
        getFilteringData={onChange}
        defaultValue={defaultValue}
      />
    );
  }

  return <DefaultFilter onChange={onChange} defaultValue={defaultValue} />;
});

import React from 'react';
import s from '@/styles/shared/Specifications.module.scss';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { getRussianCharacterBySlug } from '@/lib/character';

/**
 * FIXME
 * Когда бэк будет принимать codename создании объявлений, это не нужно будет.
 * Принимает t() из useTranslation() первым параметром,
 * вторым ПОЛНУЮ строку для перевода и третьим значение с бэка.
 * Если строка будет содержать кириллицу, вернет просто значение.
 * Если корректный перевод, вернет его
 *
 */
const checkCyrillic = (translateFunction, translateString, valueFromBackend) => {
  const cyrillicPattern = /[ЁА-яё]/;

  if (cyrillicPattern.test(translateString)) {
    return valueFromBackend;
  }
  return translateFunction(translateString);
};

export default function Specifications({ data }) {
  const { locale } = useRouter();
  const { t } = useTranslation();

  const colors = [
    { name: 'darkGray', hex: '#808080' },
    { name: 'blue', hex: '#414BB2' },
    { name: 'beige', hex: '#DBCA95' },
    { name: 'black', hex: '#000000' },
    { name: 'birch', hex: '#10A689' },
    { name: 'red', hex: '#F14725' },
    { name: 'ping', hex: '#F2439B' },
    { name: 'purple', hex: '#652CB3' },
    { name: 'sky', hex: '#6AD9E6' },
    { name: 'orange', hex: '#FAC712' },
    { name: 'yellow', hex: '#FEF445' },
    { name: 'gray', hex: '#D2C7C7' },
    { name: 'gold', hex: '#ECC819' },
    { name: 'bard', hex: '#9E3D3D' },
    { name: 'white', hex: '#FFF' },
    { name: 'lightGray', hex: '#F2F2F2' },
  ];

  const parameters = [
    { key: 'child_seat', label: t('specifications:parameters.child_seat') },
    {
      key: 'phone_charger',
      label: t('specifications:parameters.phone_charger'),
    },
    { key: 'audio_system', label: t('specifications:parameters.audio_system') },
    {
      key: 'phone_mount',
      label: t('specifications:parameters.phone_mount'),
    },
    {
      key: 'meeting_with_sign',
      label: t('specifications:parameters.meeting_with_sign'),
    },
  ];

  const getColorHex = (colorName) => {
    const color = colors.find((c) => c.name === colorName);
    return color ? color.hex : null;
  };

  const isValueEmpty = (value) => value === null || value === undefined || value === '';

  function renderDataSection(key, title, formatter = (value) => value) {
    if (!isValueEmpty(data[key])) {
      return (
        <div>
          <h4>{title}</h4>
          <span className={s.line} />
          <p>
            {key === 'color' ? (
              <div
                className={s.colorCircle}
                style={{ backgroundColor: getColorHex(data[key]) }}
              />
            ) : (
              locale === 'en' ? getRussianCharacterBySlug(formatter(data[key]), locale) : formatter(data[key])
            )}
          </p>
        </div>
      );
    }
    return null;
  }

  return (
    <>
      <section className={s.spec_block}>
        <h3>{t('specifications:characteristicTitle')}</h3>
        <div className={s.data}>
          {renderDataSection('rental_type', t('specifications:rental_type'))}
          {renderDataSection('service_type', t('specifications:service_type'))}
          {renderDataSection('vehicle_type', t('specifications:vehicle_type'))}
          {renderDataSection('number_of_rooms', t('specifications:number_of_rooms'))}
          {renderDataSection('type_housing', t('specifications:type_housing'))}
          {renderDataSection('drive', t('specifications:drive'))}
          {renderDataSection(
            'availability_of_loaders',
            t('specifications:availability_of_loaders'),
            (value) => (value ? t('common:yes') : t('common:no')),
          )}
          {renderDataSection('size', t('specifications:size'))}
          {renderDataSection(
            'brand',
            t('specifications:brand'),
            (value) => (value === 'Other' ? t('specifications:other') : value),
          )}
          {renderDataSection(
            'state',
            t('specifications:state'),
            (value) => checkCyrillic(
              t,
              `specifications:conditions.${value}`,
              value,
            ),
          )}
          {renderDataSection('gender', t('specifications:gender'))}
          {renderDataSection('experience', t('specifications:experience'))}
          {renderDataSection('type_of_cloth', t('specifications:type_of_cloth'))}
          {renderDataSection('type_of_shoes', t('specifications:type_of_shoes'))}
          {renderDataSection('schedule', t('specifications:schedule'))}
          {renderDataSection('wage', t('specifications:wage'))}
          {renderDataSection('payment', t('specifications:payment'))}
          {renderDataSection(
            'deposit',
            t('specifications:deposit'),
            (value) => (value ? t('common:yes') : t('common:no')),
          )}
          {renderDataSection('color', t('specifications:color'))}
          {renderDataSection(
            'delivery',
            t('specifications:delivery'),
            (value) => (value ? t('common:yes') : t('common:no')),
          )}
          {renderDataSection(
            'swimming_pool',
            t('specifications:swimming_pool'),
            (value) => (value ? t('common:yes') : t('common:no')),
          )}
          {renderDataSection('engine_capacity', t('specifications:engine_capacity'), (value) => `${value} см3`)}
          {renderDataSection('manufacturer', t('specifications:manufacturer'), (value) => value)}
          {renderDataSection('type_electronic', t('specifications:type_electronic'))}
        </div>
      </section>
      <section className={s.more}>
        <h3>{t('specifications:moreParameters')}</h3>
        {parameters.some((param) => data[param.key] === true) ? (
          parameters.map(
            (param) => data[param.key] === true && (
            <div key={param.key} className={s.parametrs}>
              <img src="/assets/icons/checked.svg" alt="checked" />
              <p>{param.label}</p>
            </div>
            ),
          )
        ) : (
          <p>{t('specifications:notIndicated')}</p>
        )}
      </section>
    </>
  );
}

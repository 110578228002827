import React from 'react';
import LocationSubCate from '@/components/uiComp/catalog/LocationSubCate';
import PriceSlider from '@/components/uiComp/catalog/PriceSlider';
import DescriptionSubCate from '@/components/uiComp/catalog/DescriptionSubCate';
import FilterBtn from '@/components/uiComp/catalog/FilterBtn';
import { useFilterFormState } from '@/modules/shared/hooks';
import s from '../auto/AutoFilters.module.scss';
import FilterCategory from '../../uiComp/catalog/FilterCategory';

function DinamicComponent({ category, getFilteringData, defaultValue = {} }) {
  const [filter] = useFilterFormState([
    'search',
    'min_price',
    'max_price',
    'district',
    'city',
  ], defaultValue, getFilteringData);

  const handleBtnFiltering = () => {
    getFilteringData(filter.query);
  };

  return (
    <section className={s.container}>
      <div className={s.filter_block}>
        <FilterCategory category={category} />
        <LocationSubCate
          selectedDistrict={filter.value.district}
          onSelectDistrict={filter.setter.district}
          onSelectCity={filter.setter.city}
          selectedCity={filter.value.city}
        />
        <PriceSlider
          minPrice={filter.value.min_price}
          maxPrice={filter.value.max_price}
          setMinPrice={filter.setter.min_price}
          setMaxPrice={filter.setter.max_price}
        />
        <DescriptionSubCate description={filter.value.search} setDescription={filter.setter.search} />
        <FilterBtn handleBtnFiltering={handleBtnFiltering} />
      </div>
    </section>
  );
}

export default DinamicComponent;

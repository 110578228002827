import React, { Fragment, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import MenuPaths from '@/components/shared/layout/Buttons/MenuPaths';
import ContactBtn from '@/components/shared/layout/Buttons/ContactBtn';
import AdvSellerCard from '@/components/shared/cards/AdvSellerCard';
import { useRouter } from 'next/router';
import useDetailAdv from '@/hooks/products/useDetailAdv';
import useProductData from '@/hooks/products/useProductData';
import useUserProfile from '@/hooks/users/useUserProfile';
import ComplaintModal from '@/components/shared/layout/AdsModal/ComplaintModal';
import Specifications from '@/components/shared/cards/Specifications';
import useComplaints from '@/hooks/complaints/useComplaints';
import i18n from '@/lib/i18n';
import Loadingmain from '@/components/shared/skeleton/loadingmain';
import ContactBtnAdv from '@/components/shared/layout/Buttons/ContsctBtnAdv';
import { NextSeo } from 'next-seo';
import Link from 'next/link';
import { useAuthContext, useOpenAuthModal } from '@/modules/auth';
import { TextWithLinks, ItemLoader } from '@/modules/shared/components';
import { getCityAndDistrict } from '@/modules/shared/helpers';
import Price from '@/components/shared/layout/price/Price';
import useFavorite from '@/hooks/products/useFavorite';
import { useFormatDataPrice } from '@/modules/shared/hooks';
import { AdCard } from '../ad-card';
import { Images } from './images.component';
import s from './ad-details-page.module.scss';
import { useAdsInfiniteListQuery } from '../../queries';

const allowedLinkDomains = process.env.NEXT_PUBLIC_ALLOWED_AD_LINK_DOMAINS?.split(',') || [];

export const AdDetailsPage = ({ initialId }) => {
  const openAuthModal = useOpenAuthModal();
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const router = useRouter();
  const { id } = router.query;
  const effectiveId = id || initialId;
  const { data, loading, error } = useDetailAdv(effectiveId);
  const { data: productData } = useProductData();
  const [simiralCards, setSimiralCards] = useState([]);
  const [userId, setUserId] = useState(0);
  const { userProfile } = useUserProfile(userId);
  const { createFavorite, deleteFavorite, userData } = useFavorite();
  const [complaintModal, setComplaintModal] = useState(false);
  const formattedPrice = Price(data?.price);
  const priceDisplayFormat = useFormatDataPrice();
  const { isAuthenticated } = useAuthContext();
  const [selectedReason, setSelectedReason] = useState(null);
  const { createComplaint } = useComplaints();
  const { locale } = useRouter();
  const { pages, isLoading } = useAdsInfiniteListQuery({ pageSize: 6, filters: { user: data?.user } });

  const userFavorites = userData?.flatMap((user) => user.announcements || []).map((favorite) => favorite.id) || [];
  const isFavorite = userFavorites.includes(data?.id);

  const handleFavorite = (adId: number) => {
    if (isAuthenticated) {
      createFavorite(adId);
    } else {
      openAuthModal('login');
    }
  };

  useEffect(() => {
    if (data && data.user) {
      setUserId(data.user);
    }
  }, [data]);

  useEffect(() => {
    if (data && productData) {
      const matchingCategories = productData.filter(
        (item) => item.category_title === data.category_title,
      );
      const matchingSubCategories = matchingCategories.filter(
        (item) => item.sub_category_title === data.sub_category_title,
      );
      setSimiralCards(matchingSubCategories);
    }
  }, [data, productData]);

  if (loading) return <Loadingmain />;
  if (error) {
    return (
      <div>
        Error:
        {error.message}
      </div>
    );
  }

  const maxShowSimCard = 4;
  const handlePrevSimCard = () => {
    setCurrentCardIndex(
      (prevIndex) => (prevIndex - 1 + simiralCards.length) % simiralCards.length,
    );
  };

  const handleNextSimCard = () => {
    setCurrentCardIndex((prevIndex) => (prevIndex + 1) % simiralCards.length);
  };

  const displayedCards = simiralCards.slice(
    currentCardIndex,
    currentCardIndex + maxShowSimCard,
  );

  // new function for simiral cards
  if (displayedCards.length < maxShowSimCard) {
    const remainingCards = simiralCards
      .filter((card) => !displayedCards.includes(card))
      .slice(0, maxShowSimCard - displayedCards.length);
    displayedCards.push(...remainingCards);
  }

  const handleComplaint = () => {
    if (isAuthenticated) {
      setComplaintModal(!complaintModal);
    } else {
      openAuthModal('login');
    }
  };

  const handleSendComplaint = async () => {
    const formData = {
      recipient: data?.user,
      announcement: data?.id,
      cause: selectedReason,
    };

    setComplaintModal(!complaintModal);
    if (formData) {
      await createComplaint(formData);
      setSelectedReason('');
    }
  };

  function Mobile() {
    return (
      <div className={s.adv_seller_block1}>
        <div className={s.adv_main_title}>
          <p>
            {i18n.advCard.postDate[locale]}
            &nbsp;
            {data.created_at}
            {!isFavorite && (
              <button onClick={() => handleFavorite(data.id)}>
                <img src="/assets/icons/cart.svg" alt="favorites btn" />
              </button>
            )}
            {isFavorite && (
              <button onClick={() => deleteFavorite(data.id)}>
                <img
                  src="/assets/icons/favorites.svg"
                  height={21}
                  alt="favorites"
                />
              </button>
            )}
          </p>
          <div className={s.adv_title}>
            <h1>{data.title}</h1>
            <span>
              <p>
                <strong>
                  {priceDisplayFormat({ priceType: data.price_type, price: data.price })}
                </strong>
              </p>
            </span>
          </div>
          <ContactBtnAdv
            isAuthenticated={isAuthenticated}
            userProfile={userProfile}
          />
        </div>
        <AdvSellerCard data={userProfile} />
        <div className={s.adv_address_block}>
          <div className={s.adv_address_header}>
            {i18n.advDetail.locations[locale]}
          </div>
          <div className={s.adv_address_title}>
            <img src="/assets/icons/adv_map.svg" alt="error" />
            <span>
              <strong>
                {getCityAndDistrict(
                  locale,
                  data?.city_name,
                  data?.district_name,
                )}
              </strong>
            </span>
          </div>
        </div>
      </div>
    );
  }

  const shouldRenderSpecifications = data.specifications && Object.keys(data.specifications).length > 0;
  const cityAndDistrict = getCityAndDistrict(
    locale,
    data?.city_name,
    data?.district_name,
  );
  const fromText = i18n.advDetail.from[locale];
  const onText = i18n.advDetail.on[locale];
  const advertisementsText = i18n.advDetail.advertisements[locale];
  const everythingText = i18n.advDetail.everything[locale];

  const Seotitle = `${data.title} ${cityAndDistrict} ${fromText} ${formattedPrice}THB | ${onText} Bazaar`;

  const SeoDescription = `${data.title} ${cityAndDistrict} ${fromText} ${formattedPrice}THB ${advertisementsText} ${onText} Bazaar ${everythingText} ${data.title}`;

  return (
    <section>
      <NextSeo title={Seotitle} description={SeoDescription} />
      <div className={s.rating_and_menu_paths}>
        <MenuPaths
          category={data.category?.codename}
          subcategory={data.sub_category?.codename}
          undersubcategory={data.under_sub_category?.codename}
        />
      </div>
      <div className={s.adv_detail_block}>
        <div className={s.adv_detail}>
          <Images images={data?.images} adTitle={data?.title} />
          <Mobile />
          <div className={s.adv_description}>
            <div className={s.description_text}>
              <h2>{i18n.advDetail.description[locale]}</h2>
              <p style={{ wordWrap: 'break-word' }}>
                <pre style={{ whiteSpace: 'pre-wrap' }}>
                  <TextWithLinks
                    whiteListedDomains={allowedLinkDomains}
                    text={data.description}
                  />
                </pre>
              </p>
            </div>
            <div className={s.description_info}>
              <p>
                ID
                :
                {data.id}
              </p>
              <p>
                {i18n.advDetail.views[locale]}
                :
                {Math.round(data.views / 2)}
              </p>
              <button onClick={handleComplaint}>
                <img src="/assets/icons/adv_btn.svg" alt="error" />
                {i18n.advDetail.complaint[locale]}
              </button>
            </div>
          </div>
          {shouldRenderSpecifications ? (
            <Specifications data={data.specifications} />
          ) : null}
        </div>
        <div className={s.adv_seller_block}>
          <div className={s.adv_main_title}>
            <p>
              {i18n.advCard.postDate[locale]}
              {' '}
              {data.created_at}
              {!isFavorite && (
                <button onClick={() => handleFavorite(data.id)}>
                  <img src="/assets/icons/cart.svg" alt="favorites btn" />
                </button>
              )}
              {isFavorite && (
                <button onClick={() => deleteFavorite(data.id)}>
                  <img
                    src="/assets/icons/favorites.svg"
                    height={21}
                    alt="favorites"
                  />
                </button>
              )}
            </p>
            <div className={s.adv_title}>
              <h1>{data.title}</h1>
              <span>
                <p>
                  <strong>{priceDisplayFormat({ priceType: data.price_type, price: data.price })}</strong>
                </p>
              </span>
            </div>
            <ContactBtn
              isAuthenticated={isAuthenticated}
              userProfile={userProfile}
            />
          </div>
          <AdvSellerCard data={userProfile} />
          <div className={s.adv_address_block}>
            <div className={s.adv_address_header}>
              {i18n.advDetail.locations[locale]}
            </div>
            <div className={s.adv_address_title}>
              <img src="/assets/icons/adv_map.svg" alt="error" />
              <span>
                <strong>
                  {getCityAndDistrict(
                    locale,
                    data?.city_name,
                    data?.district_name,
                  )}
                </strong>
              </span>
            </div>
          </div>
        </div>
      </div>
      {pages && pages[0] && pages[0].count > 0 ? (
        <div className={s.all_advs_autors}>
          <div className={s.advs_autors_title}>
            <h3>{i18n.advDetail.allUserAdv[locale]}</h3>
            <Link href={`/users/${userProfile?.id}`}>
              <button>{i18n.advDetail.viewAll[locale]}</button>
            </Link>
          </div>
          <div className={s.advs_cards_autors}>
            {pages.map((page, i) => (
              <Fragment key={i}>
                {page.results.map((product) => (
                  <AdCard key={product.id} ad={product} />
                ))}
              </Fragment>
            ))}
          </div>
        </div>
      ) : null}
      {!isLoading ? null : (
        <Box display="flex" justifyContent="center">
          <ItemLoader isLoading size="50px" />
        </Box>
      )}
      {displayedCards && displayedCards.length > 0 ? (
        <div className={s.similar_advs}>
          <div className={s.similar_advs_title}>
            <h3>
              {i18n.advDetail.similar[locale]}
            </h3>
            {maxShowSimCard <= simiralCards.length && (
              <div className={s.similar_advs_btns}>
                <button onClick={handlePrevSimCard}>
                  <img src="/assets/icons/btn_left.svg" alt="error" />
                </button>
                <button onClick={handleNextSimCard}>
                  <img src="/assets/icons/btn_rigth.svg" alt="error" />
                </button>
              </div>
            )}
          </div>
          <div className={s.similar_advs_list}>
            {displayedCards.map((product) => (
              <AdCard key={product.id} ad={product} />
            ))}
          </div>
        </div>
      ) : null}
      {complaintModal && (
        <ComplaintModal
          onClose={handleComplaint}
          selectedReason={selectedReason}
          setSelectedReason={setSelectedReason}
          sendComplaint={handleSendComplaint}
          setComplaintModal={setComplaintModal}
        />
      )}
    </section>
  );
};

import React, {
  FC, useEffect, useState,
} from 'react';
import { useRouter } from 'next/router';
import i18n from '@/lib/i18n';
import { IAd } from '@/modules/ads/types';
import { SvgIcon } from '@/modules/shared/components';
import { useMyAdsPageContext } from './my-ads-page.provider';

export type ActionColumnProps = {
  ad: IAd;
  setAds: React.Dispatch<React.SetStateAction<IAd[]>>;
};

export const ActionColumn: FC<ActionColumnProps> = ({ ad, setAds }) => {
  const { locale } = useRouter();
  const deactivateIconTooltip = i18n.modal.delete.Deactivatead[locale];
  const { adToDeactivate, setAdToDeactivate } = useMyAdsPageContext();
  const [targetId, setTargetId] = useState<number>();

  useEffect(() => {
    if (adToDeactivate === null) {
      setAds((prev) => prev.filter((el) => el.id !== targetId));
    }
  }, [adToDeactivate]);

  const handleClick = () => {
    setAdToDeactivate(ad);
    setTargetId(ad.id);
  };

  return (
    <button
      onClick={handleClick}
      title={deactivateIconTooltip}
      data-tippy-interactive
      type="button"
      style={{ fontSize: '24px' }}
    >
      <SvgIcon name="eye-closed" />
    </button>
  );
};

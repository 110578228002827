export const getRussianCharacterBySlug = (slug, locale) => {
  const CharacterPaths = {
    'По городу': 'Around town',
    'Легковой автомобиль': 'Car',
    Минивэн: 'Minivan',
    Микроавтобус: 'Minibus',
    Автобус: 'Bus',
    Другой: 'Other',
    'Трансфер из аэропорта': 'Airport transfer',
    'Трансфер класса комфорт/бизнес': 'Comfort/business class transfer',
    'Подача по времени': 'Feed by time',
    'Между городами': 'Between cities',
    Почасовая: 'Hourly',
    Посуточная: 'Daily',
    Долгосрочная: 'Long-term',
    'полный день': 'full day',
    'неполный день': 'part-time',
    плавающий: 'flexible',
    'Без опыта': 'without experience',
    'Более одного года': 'More than one year',
    'Более 3 лет': 'More than 3 years',
    'Более 5 лет': 'More than 5 years',
    'Более 10 лет': 'More than 10 years',
    почасовая: 'hourly',
    'по месяцам': 'by month',
    'разовая оплата за проект': 'one-time fee for the project',
    Мужская: 'Male',
    Женская: 'Female',
    Отличное: 'Excellent',
    Хорошее: 'Good',
    Удовлетворительное: 'Satisfactory',
    Апартаменты: 'Apartments',
    Квартира: 'Apartment',
    Вилла: 'Villa',
    Неважно: 'Other',
    '5 комнат и больше': '5 rooms or more',
    Студия: 'Studio',
    '1 комната': '1 room',
    '2 комнаты': '2 rooms',
    '3 комнаты': '3 rooms',
    '4 комнаты': '4 rooms',
    'Кофты и футболки': 'Sweaters and T-shirts',
    'Пиджаки и костюмы': 'Jackets and suits',
    Джинсы: 'jeans',
    Брюки: 'trousers',
    Рубашки: 'shirts',
    'Спортивные костюмы': 'Sport suits',
    Шорты: 'shorts',
    'Нижнее белье': 'underwear',
    Другое: 'Other',
    Платья: 'dresses',
    Юбки: 'Skirts',
    'Джемперы, свитеры, кардиганы': 'Jumpers, sweaters, cardigans',
    'Свадебные платья': 'Wedding dresses',
    'Рубашки и блузки': 'Shirts and blouses',
    'Топы и футболки': 'Tops and T-shirts',
    Купальники: 'Swimsuits',
    Туфли: 'shoes',
    Босоножки: 'sandals',
    'Батинки и полуботинки': 'Boots and ankle boots',
    'Кроссовки и кеды': 'Sneakers and trainers',
    Балетки: 'Ballet flats',
    'Сабо и мюли': 'Sabots and mules',
    'Резиновая обувь': 'Rubber footwear',
    'Макасины и лоферы': 'Moccasins and loafers',
    Сандалии: 'sandals',
    'Шлепанцы и сланцы': 'Flip-flops and sliders',
    'Домашняя обувь': 'slippers',
    'Спортивная обувь': 'Sports footwear',
    'Слипоны и эспадрильи': 'Slip-ons and espadrilles',
    'Уход за обувью': 'Footwear care',
  };
  if (locale === 'en') {
    return CharacterPaths[slug] || slug;
  }
};

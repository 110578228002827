import CheckboxInputGroup from '@/components/uiComp/createProduct/CheckboxInput';
import Colors from '@/components/uiComp/createProduct/Colors';
import SelectOptions from '@/components/uiComp/createProduct/SelectOptions';
import ToggleButtons from '@/components/uiComp/createProduct/ToggleButtons';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import s from '../moto/CreateMoto.module.scss';
import i18n from '../../../lib/i18n';

function CreateMoped({ onMopedDataChange, defaultValue }) {
  const [color, setColor] = useState('');
  const [deposit, setDeposit] = useState(false);
  const [rental_type, setRental_type] = useState('');
  const [engine_capacity, setEngine_capacity] = useState('');
  const [delivery, setDelivery] = useState(false);

  const [childSeat, setChildSeat] = useState(false);
  const [phoneCharger, setPhoneCharger] = useState(false);
  const [audioSystem, setAudioSystem] = useState(false);
  const { locale } = useRouter();
  useEffect(() => {
    if (defaultValue) {
      setColor(defaultValue.color);
      setDeposit(defaultValue.deposit);
      setRental_type(defaultValue.rental_type);
      setEngine_capacity(defaultValue.engine_capacity);
      setDelivery(defaultValue.delivery);
      setChildSeat(defaultValue.child_seat);
      setPhoneCharger(defaultValue.phone_charger);
      setAudioSystem(defaultValue.audio_system);
    }
  }, [defaultValue]);
  const getCheckboxData = () => ({
    child_seat: childSeat,
    phone_charger: phoneCharger,
    audio_system: audioSystem,
  });
  useEffect(() => {
    const formData = {
      color,
      deposit,
      rental_type,
      delivery,
      engine_capacity,
      ...getCheckboxData(),
    };

    onMopedDataChange(formData);
  }, [
    color,
    deposit,
    rental_type,
    delivery,
    childSeat,
    phoneCharger,
    audioSystem,
    engine_capacity,
  ]);


  return (
    <div className={s.container}>
      <div className={s.block}>
        <Colors selectedColor={color} onColorSelect={setColor} />
        <ToggleButtons
          title={i18n.createAdvFilds.default.depo[locale]}
          selectedStatus={deposit}
          onSelectStatus={setDeposit}
        />
        <ToggleButtons
          title={i18n.createAdvFilds.default.delivery[locale]}
          selectedStatus={delivery}
          onSelectStatus={setDelivery}
        />
      </div>
      <div className={s.block}>
        <SelectOptions
          title={i18n.createAdvFilds.default.rentalType[locale]}
          options={[
            i18n.createAdvFilds.default.rentalTypeHourly[locale],
            i18n.createAdvFilds.default.rentalTypeDaily[locale],
            i18n.createAdvFilds.default.rentalTypeLongTerm[locale],
          ]}
          selectedOption={rental_type}
          onSelectOption={setRental_type}
        />
      </div>
      <CheckboxInputGroup
        options={[
          {
            label: i18n.createAdvFilds.default.PhoneMount[locale],
            isChecked: childSeat,
            onChange: () => setChildSeat(!childSeat),
          },
        ]}
      />
    </div>
  );
}
export default CreateMoped;

import React, {
  FC, ReactNode, createContext, useCallback, useContext, useMemo, useState,
} from 'react';
import { noop } from 'lodash';
import { IAd } from '@/modules/ads/types';
import { DeactivationModal } from '@/modules/shared/components';
import useTranslation from 'next-translate/useTranslation';
import useMyAds from './use-my-ads.hook';

interface IMyAdsPageContext {
  adToDeactivate?: IAd;
  setAdToDeactivate: (ad?: IAd) => void;
}
export const MyAdsPageContext = createContext<IMyAdsPageContext>({
  setAdToDeactivate: noop,
});
export const useMyAdsPageContext = () => useContext(MyAdsPageContext);

export const MyAdsPageProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { reasons, deactivateMyAd } = useMyAds();
  const [adToDeactivate, setAdToDeactivate] = useState<IAd>(undefined);
  const closeModal = useCallback(() => setAdToDeactivate(null), [setAdToDeactivate]);

  const value = useMemo<IMyAdsPageContext>(() => ({
    adToDeactivate,
    setAdToDeactivate,
  }), [adToDeactivate]);

  const { t } = useTranslation('userAdvertisements');
  const titleReason = t('userAdvertisements.deactivatedModal.title');

  const deactivateAd = useCallback(async (reason: string) => {
    await deactivateMyAd(adToDeactivate.id, { deactivation_reason: reason });
    closeModal();
  }, [closeModal, adToDeactivate]);

  return (
    <MyAdsPageContext.Provider value={value}>
      {children}
      {!adToDeactivate ? null : (
        <DeactivationModal
          onClose={closeModal}
          title={titleReason}
          onSubmit={deactivateAd}
          reasons={reasons}
        />
      )}
    </MyAdsPageContext.Provider>
  );
};

import SelectOptions from '@/components/uiComp/createProduct/SelectOptions';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import i18n from '../../../lib/i18n';

export default function Toys({ onToysAndBeautyDataChange, defaultValue }) {
  const [state, setState] = useState('');
  const { locale } = useRouter();
  useEffect(() => {
    if (defaultValue) {
      setState(defaultValue.state);
    }
  }, [defaultValue]);
  const states = [i18n.createAdvFilds.default.Excellent[locale], i18n.createAdvFilds.default.Good[locale], i18n.createAdvFilds.default.Satisfactory[locale]];

  useEffect(() => {
    const formData = {
      state,
    };
    onToysAndBeautyDataChange(formData);
  }, [state]);

  return (
    <div>
      <SelectOptions
        title={i18n.createAdvFilds.default.state[locale]}
        options={states}
        selectedOption={state}
        onSelectOption={setState}
      />
    </div>
  );
}

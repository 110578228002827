import React from 'react';
import { Tooltip } from '@mui/material';
import { useFormatDataPrice } from '@/modules/shared/hooks';
import useTranslation from 'next-translate/useTranslation';
import { useTranslateLocation } from '@/modules/locations';
import { AdImage } from '../ad-image';
import { AdLink } from '../ad-link';
import s from './ad-mini-card.module.scss';
import { FavouriteButton } from './favourite-button.component';

export const AdMiniCard = ({ product }) => {
  const { t } = useTranslation();
  const priceDisplayFormat = useFormatDataPrice();
  const translatedLocation = useTranslateLocation(
    product?.city?.codename,
    product?.district?.codename,
  );

  return (
    <div className={s.adv_mini_card}>
      <div className={s.mini_card_img}>
        <AdLink ad={product}>
          <AdImage ad={product} />
        </AdLink>
      </div>
      <div className={s.mini_card_info}>
        <div className={s.mini_card_title}>
          <AdLink ad={product}>
            <Tooltip title={product.title}>
              <h4>{product.title}</h4>
            </Tooltip>
          </AdLink>
          <FavouriteButton ad={product} />
        </div>
        <div className={s.mini_card_descr}>
          <div className={s.card_descr}>
            <p>
              {t('adv:cost')}
              :
              <strong>
                {priceDisplayFormat({ priceType: product?.price_type, price: product.price })}
              </strong>
            </p>
            <h5>
              {priceDisplayFormat({ priceType: product?.price_type, price: product.price })}
            </h5>
            <p>
              {t('adv:address')}
              :
              <strong>
                {translatedLocation}
              </strong>
            </p>
            <span>
              {translatedLocation}
            </span>
          </div>
          <div className={s.card_descr_date}>
            <p>{product?.created_at}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

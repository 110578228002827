import React from 'react';
import LocationSubCate from '@/components/uiComp/catalog/LocationSubCate';
import MoreParametres from '@/components/uiComp/catalog/MoreParametres';
import PriceSlider from '@/components/uiComp/catalog/PriceSlider';
import FilterBtn from '@/components/uiComp/catalog/FilterBtn';
import i18n from '@/lib/i18n';
import { useRouter } from 'next/router';
import { useFilterFormState } from '@/modules/shared/hooks';
import s from '../auto/AutoFilters.module.scss';
import DescriptionSubCate from '../../uiComp/catalog/DescriptionSubCate';
import SelectOptions from '../../uiComp/createProduct/SelectOptions';
import Colors from '../../uiComp/createProduct/Colors';
import FilterSubCategoryCloth from '../../uiComp/catalog/FilterSubCategoryCloth';

function Jawerly({
  category,
  subcategory,
  getFilteringData,
  underSubCategory,
  defaultValue,
}) {
  const [form] = useFilterFormState([
    'color',
    'district',
    'state',
    'min_price',
    'max_price',
    'search',
    'gender',
    'brand',
    'city',
  ], defaultValue, getFilteringData);
  const { locale } = useRouter();

  const TypeClothes = [
    i18n.filters.default.Excellent[locale],
    i18n.filters.default.good[locale],
    i18n.filters.default.Satisfactory[locale],
  ];
  const titleClothes = i18n.filters.default.States[locale];

  const genderTitle = i18n.createAdvFilds.things.gender[locale];
  const genderJob = [
    i18n.createAdvFilds.things.Male[locale],
    i18n.createAdvFilds.things.Female[locale],
  ];
  const luxuryBrands = [
    i18n.createAdvFilds.things.Otherbrand[locale],
    'Tiffany & Co.',
    'Bulgari',
    'Cartier',
    'Chopard',
    'Harry Winston',
    'Van Cleef & Arpels',
    'Graff Diamonds',
    'Piaget',
    'Mikimoto',
    'David Yurman',
    'Chanel',
    'De Beers',
    'Swatch Group',
    'Swarovski',
    'Pandora',
    'Rolex',
    'David Morris',
    'David Webb',
    'Montblanc',
    'Buccellati',
    'Asprey',
    'Garrard',
    'Graff',
    'Mappin & Webb',
    'Marina B',
    'Mikimoto Pearls',
    'Sabba',
    'Seaman Schepps',
    'Verdura',
    'Yazbukey',
    'Audemars Piguet',
    'Baume & Mercier',
    'Breitling',
    'Breguet',
    'Jaeger-LeCoultre',
    'Longines',
    'Omega',
    'Panerai',
    'Patek Philippe',
    'Richard Mille',
    'Roger Dubuis',
    'Tag Heuer',
    'Ulysse Nardin',
    'Vacheron Constantin',
    'Blancpain',
    'Glashütte Original',
    'Gübelin',
    'IWC Schaffhausen',
    'Jaquet Droz',
    'Parmigiani Fleurier',
    'Romain Gauthier',
    'Andres Sarda',
    'Bordelle',
    'Cadolle',
    'Chantal Thomass',
    'Confidences',
    'Eres',
    'Fifi Chachnil',
    'I.D. Sarrieri',
    'La Perla',
    'Lise Charmel',
    'Marjolaine',
    'Rigby & Peller',
    'Sinéquanone',
    'Wolford',
    'Agatha Paris',
    'Atelier de Famille',
    'Au Printemps Paris',
    'Baccarat',
    'Dinh Van',
    'Gèdre',
    'Guérin Joaillerie',
    'Hermès',
    'Irène Van Ryb',
    'Jean-Pierre Lépine',
    'Lalique',
    'Le Rhöne',
    'Luz',
    'Mathon Paris',
    'Native Union',
    'Numérologie',
    'Pensluxe',
    'Pierre Cardin',
    'Pointe de Penmarc’h',
    'Repetto',
    'Serge Lutens',
    'Shoemakers',
    'Tea for Two',
    'Théodor',
    'Vert&Bois',
    'Vetrerie di Empoli',
    'Yankee Candle',
    'Zack',
    'Zwilling',
  ];

  const handleBtnFiltering = () => {
    getFilteringData(form.query);
  };

  return (
    <section className={s.container}>
      <div className={s.filter_block}>
        <FilterSubCategoryCloth
          category={category}
          subcategory={subcategory}
          undersubcategory={underSubCategory}
        />
        <LocationSubCate
          selectedDistrict={form.value.district}
          onSelectDistrict={form.setter.district}
          onSelectCity={form.setter.city}
          selectedCity={form.value.city}
        />
        <MoreParametres
          title={titleClothes}
          options={TypeClothes}
          selected={form.value.state}
          onSelect={form.setter.state}
        />
        <Colors selectedColor={form.value.color} onColorSelect={form.setter.color} />
        <SelectOptions
          title={i18n.createAdvFilds.things.brand[locale]}
          options={luxuryBrands}
          selectedOption={form.value.brand}
          onSelectOption={form.setter.brand}
        />
        <MoreParametres
          title={genderTitle}
          options={genderJob}
          selected={form.value.gender}
          onSelect={form.setter.gender}
        />
        <PriceSlider
          minPrice={form.value.min_price}
          maxPrice={form.value.max_price}
          setMinPrice={form.setter.min_price}
          setMaxPrice={form.setter.max_price}
        />
        <DescriptionSubCate description={form.value.search} setDescription={form.setter.search} />
        <FilterBtn handleBtnFiltering={handleBtnFiltering} />
      </div>
    </section>
  );
}

export default Jawerly;


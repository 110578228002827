export const subcategoryPaths = {
  auto: 'Автомобили',
  moto: 'Мотоциклы и мототехника',
  specialvehicle: 'Грузовики и спецтехника',
  'water-vehicle': 'Водный транспорт',
  'air-vehicle': 'Воздушный транспорт',
  vacancy: 'Ищу работу',
  resume: 'Ищу сотрудника',
  logistic: 'Грузоперевозки',
  building: 'Строительство',
  education: 'Обучение и курсы',
  domestic: 'Бытовые услуги',
  handyworker: 'Мастер на час',
  'business-services': 'Деловые услуги',
  holidays: 'Праздники, мероприятия',
  'passenger-transportation': 'Пассажирские перевозки',
  gardening: 'Сад, благоустройство',
  'communications-services': 'IT, интернет, телеком',
  security: 'Охрана, безопасность',
  loader: 'Грузчики, складские услуги',
  'computer-support': 'Компьютерная помощь',
  'advertising-printing': 'Реклама, полиграфия',
  'photo-video': 'Фото и видеосъемка',
  // towing: 'Услуги эвакуатора',
  technician: 'Установка техники',
  art: 'Искусство',
  nannies: 'Няни, сиделки',
  cleaning: 'Уборка',
  'nimal-car': 'Уход за животными',
  other: 'Другое',
  clothes: 'Одежда',
  shoes: 'Обувь',
  'childrens-clothing': 'Детская одежда и обувь',
  'childrens-goods': 'Товары для детей и игрушки',
  beauty: 'Красота и здоровье',
  'Beauty-Health': 'Красота, здоровье',
  jewelry: 'Часы и украшения',
  repair: 'Ремонт и строительство' || 'Ремонт и отделка',
  furniture: 'Мебель и интерьер',
  appliances: 'Бытовая техника',
  food: 'Продукты питания',
  plants: 'Растения',
  kitchenware: 'Посуда и товары для кухни',
  parts: 'Запчасти',
  tools: 'Инструменты',
  tires: 'Шины, диски и колёса',
  trailers: 'Прицепы',
  accessories: 'Аксессуары',
  Accessories: 'Аксессуары Аксессуары',
  accessori: 'Аксессуары',
  oils: 'Масла и автохимия',
  tuning: 'Тюнинг',
  gps: 'GPS-навигаторы',
  luggage: 'Багажники и фаркопы',
  phones: 'Телефоны',
  photo: 'Фототехника',
  'audio-video': 'Аудио и видео' || 'Аудио и видео',
  'tablets-ebooks': 'Планшеты и электронные книги',
  'computer-goods': 'Товары для компьютера',
  consumables: 'Оргтехника и расходники',
  consoles: 'Игры, приставки и программы',
  notebooks: 'Ноутбуки',
  desktops: 'Настольные компьютеры',
  tickets: 'Билеты и путешествия',
  sports: 'Спорт и отдых',
  bikes: 'Велосипеды',
  books: 'Книги и журналы',
  collecting: 'Коллекционирование',
  'musical-instruments': 'Музыкальные инструменты',
  'hunting-fishing': 'Охота и рыбалка',
  dogs: 'Собаки',
  cats: 'Кошки',
  birds: 'Птицы',
  aquarium: 'Аквариум',
  'other-pets': 'Другие животные',
  'pet-supplies': 'Товары для животных',
  'ready-business': 'Готовый бизнес',
  equipment:
    'Оборудование для бизнеса' || 'Экипировка' || 'Оборудование, производство',
  buy: 'Купить жильё',
  'rent-daily': 'Снять посуточно',
  'rent-longterm': 'Снять долгосрочно',
  commercial: 'Коммерческая недвижимость',
  Beauty_health: 'Красота, здоровье',
  beauty: 'Красота и здоровье',
};

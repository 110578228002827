import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import i18n from '@/lib/i18n';
import { pick } from 'lodash';
import { api, baseEndpointV1 } from '@/modules/shared/api';
import { useMeContext } from '@/modules/me';
import { IAdInput } from './ad-form.types';


export const useSaveAd = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const router = useRouter();
  const locale = router;
  const { refetch, me } = useMeContext();

  const createProduct = useCallback(async (
    commonData,
    categoryId,
    subCategoryId,
    underSubCategoryId,
    cityId,
    districtId,
    autoData,
    motoData,
    mopedData,
    rentEstateData,
    clothesData,
    shoesData,
    accessoriesData,
    watchesData,
    jewerlyData,
    childClothesData,
    toysAndBeautyData,
    byEstateData,
    transfersData,
    searchWorkData,
    searchWorkerData,
    transportationData,
    electronicData,
    telephoneData,
  ) => {
    const userId = me?.id;

    const formData = pick<IAdInput>(
      commonData,
      ['title', 'description', 'price', 'images', 'address', 'price_type'],
    );

    formData.user = userId;
    formData.category = categoryId;
    formData.sub_category = subCategoryId;
    if (underSubCategoryId) {
      formData.under_sub_category = underSubCategoryId;
    }
    formData.city = cityId;
    formData.district = districtId;

    if (autoData) {
      Object.keys(autoData).forEach((key) => {
        formData[key] = autoData[key];
      });
    }
    if (motoData) {
      Object.keys(motoData).forEach((key) => {
        formData[key] = motoData[key];
      });
    }
    if (mopedData) {
      Object.keys(mopedData).forEach((key) => {
        formData[key] = mopedData[key];
      });
    }
    if (rentEstateData) {
      Object.keys(rentEstateData).forEach((key) => {
        formData[key] = rentEstateData[key];
      });
    }
    if (transportationData) {
      Object.keys(transportationData).forEach((key) => {
        formData[key] = transportationData[key];
      });
    }
    if (byEstateData) {
      Object.keys(byEstateData).forEach((key) => {
        formData[key] = byEstateData[key];
      });
    }
    if (clothesData) {
      Object.keys(clothesData).forEach((key) => {
        formData[key] = clothesData[key];
      });
    }
    if (shoesData) {
      Object.keys(shoesData).forEach((key) => {
        formData[key] = shoesData[key];
      });
    }
    if (accessoriesData) {
      Object.keys(accessoriesData).forEach((key) => {
        formData[key] = accessoriesData[key];
      });
    }
    if (watchesData) {
      Object.keys(watchesData).forEach((key) => {
        formData[key] = watchesData[key];
      });
    }
    if (jewerlyData) {
      Object.keys(jewerlyData).forEach((key) => {
        formData[key] = jewerlyData[key];
      });
    }
    if (childClothesData) {
      Object.keys(childClothesData).forEach((key) => {
        formData[key] = childClothesData[key];
      });
    }
    if (toysAndBeautyData) {
      Object.keys(toysAndBeautyData).forEach((key) => {
        formData[key] = toysAndBeautyData[key];
      });
    }
    if (transfersData) {
      Object.keys(transfersData).forEach((key) => {
        formData[key] = transfersData[key];
      });
    }
    if (searchWorkData) {
      Object.keys(searchWorkData).forEach((key) => {
        formData[key] = searchWorkData[key];
      });
    }
    if (searchWorkerData) {
      Object.keys(searchWorkerData).forEach((key) => {
        formData[key] = searchWorkerData[key];
      });
    }
    if (transportationData) {
      Object.keys(transportationData).forEach((key) => {
        formData[key] = transportationData[key];
      });
    }
    if (electronicData) {
      Object.keys(electronicData).forEach((key) => {
        formData[key] = electronicData[key];
      });
    }
    if (telephoneData) {
      Object.keys(telephoneData).forEach((key) => {
        formData[key] = telephoneData[key];
      });
    }

    const apiUrl = `${baseEndpointV1}/announcement/create/`;

    setLoading(true);
    try {
      const response = await api.post(apiUrl, formData);
      if (response.status === 200) {
        if (
          response.data.error
          && response.data.error
            === 'Объявление создано, но отмечено как неактивное'
        ) {
          toast.info(i18n.toast.advModeration[locale]);
        } else {
          toast.success(i18n.toast.advertisementSuccess[locale]);
        }
      }
      refetch();
      /**
       * FIXME
       * router.push(`/adv/${response.data.data.id}`);
       * Временно меняем на window.location.href, потому что для некоторых категорий
       * получаем ошибку Error: Abort fetching component for route: "/adv/[...slug]"
       * и отмену роутера
       */
      window.location.href = `/adv/${response.data.data.id}`;
    } catch (err) {
      setError(err.response ? err.response.data : err.message);
      toast.error(i18n.toast.while[locale]);
    } finally {
      setLoading(false);
    }
  }, [me, locale, refetch, router]);

  return { createProduct, loading, error };
};

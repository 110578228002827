import Colors from '@/components/uiComp/createProduct/Colors';
import SelectOptions from '@/components/uiComp/createProduct/SelectOptions';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import s from './Things.module.scss';
import i18n from '../../../lib/i18n';

export default function Accessories({ onAccessoriesDataChange, defaultValue }) {
  const [state, setState] = useState('');
  const [color, setColor] = useState('');
  const { locale } = useRouter();
  useEffect(() => {
    if (defaultValue) {
      setState(defaultValue.state);
      setColor(defaultValue.color);
    }
  }, [defaultValue]);

  const states = [i18n.createAdvFilds.default.Excellent[locale], i18n.createAdvFilds.default.Good[locale], i18n.createAdvFilds.default.Satisfactory[locale]];

  useEffect(() => {
    const formData = {
      state,
      color,
    };
    onAccessoriesDataChange(formData);
  }, [state, color]);

  return (
    <div className={s.flex}>
      <SelectOptions
        title={i18n.createAdvFilds.default.state[locale]}
        options={states}
        selectedOption={state}
        onSelectOption={setState}
      />
      <Colors selectedColor={color} onColorSelect={setColor} />
    </div>
  );
}

import { useState, useEffect } from 'react';
import axios from 'axios';
import Cookie from 'js-cookie';
import { getCookie } from '@/components/cookie/cookieHelpers';
import { toast } from 'react-toastify';
import i18n from '@/lib/i18n';
import { useRouter } from 'next/router';
import { useAuthContext } from '@/modules/auth';

const API_URL = process.env.NEXT_PUBLIC_API_URL;

const useFavorite = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [favouriteEvents, setFavouriteEvents] = useState([]);
  const { locale } = useRouter();
  const { isAuthenticated } = useAuthContext();
  useEffect(() => {
    if (isAuthenticated) {
      fetchFavoriteData();
    }
  }, [isAuthenticated]);

  const fetchFavoriteData = async () => {
    try {
      const token = Cookie.get('access_token');

      const response = await axios.get(`${API_URL}/api/complaint/list/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserData(response.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const createFavorite = async (id) => {
    const token = getCookie('access_token');
    const config = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    setLoading(true);

    try {
      const response = await fetch(
        `${API_URL}/api/complaint/create/${id}/`,
        config,
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      fetchFavoriteData();
      setLoading(false);
    } catch (err) {
      toast.error(i18n.toast.like[locale]);
      setLoading(false);
      setError(true);
    }
  };

  const deleteFavorite = async (id) => {
    const token = getCookie('access_token');
    const config = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}/api/complaint/delete/${id}/`,
        config,
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      fetchFavoriteData();
      setLoading(false);
    } catch (err) {
      toast.error(i18n.toast.like[locale]);
      setLoading(false);
      setError(true);
    }
  };

  // ! functions for events

  const fetchFavoriteEvents = async () => {
    try {
      const token = Cookie.get('access_token');
      const response = await axios.get(`${API_URL}/api/complaint/event_list/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFavouriteEvents(response.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const createFavoriteEvent = async (id) => {
    const token = getCookie('access_token');
    const config = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}/api/complaint/event_create/${id}/`,
        config,
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      fetchFavoriteEvents();
      setLoading(false);
    } catch (err) {
      toast.error(i18n.toast.like[locale]);
      setLoading(false);
      setError(true);
    }
  };

  const deleteFavoriteEvent = async (id) => {
    const token = getCookie('access_token');
    const config = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}/api/complaint/event_delete/${id}/`,
        config,
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      fetchFavoriteEvents();
      setLoading(false);
    } catch (err) {
      toast.error(i18n.toast.like[locale]);
      setLoading(false);
      setError(true);
    }
  };

  return {
    userData,
    loading,
    error,
    setUserData,
    createFavorite,
    deleteFavorite,
    favouriteEvents,
    createFavoriteEvent,
    deleteFavoriteEvent,
  };
};

export default useFavorite;

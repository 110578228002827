import { getCookie } from '@/components/cookie/cookieHelpers';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import i18n from '@/lib/i18n';
import { useRouter } from 'next/router';
import { useAuthContext } from '@/modules/auth';
import useBanned from '../admin/useBanned';

const URL = process.env.NEXT_PUBLIC_API_URL;

const useComplaints = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [complaintsList, setComplaintsList] = useState([]);
  const { banned, setBanned } = useBanned();
  const { locale } = useRouter();

  const createComplaint = async (data) => {
    try {
      setLoading(true);
      const token = getCookie('access_token');
      const userId = parseInt(getCookie('user_id'), 10);
      const formData = {
        ...data,
        reviewer: userId,
      };
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.post(`${URL}/api/complaint_system/create/`, formData, config);
      toast.success(i18n.toast.advertisementSuccess[locale]);
    } catch (err) {
      setError(err.response ? err.response.data : err.message);
      toast.error(i18n.toast.errorcreating[locale]);
      setLoading(false);
      setError(true);
    }
  };

  const { isAuthenticated } = useAuthContext();
  const getComplaintList = useCallback(async () => {
    if (isAuthenticated) {
      try {
        const token = getCookie('access_token');
        const response = await axios.get(`${URL}/api/complaint_system/list`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setComplaintsList(response.data);
      } catch (err) {
        setError(err.response ? err.response.data : err.message);
      }
    }
  }, [isAuthenticated, setError, setComplaintsList]);
  useEffect(() => {
    getComplaintList();
  }, [getComplaintList]);

  const verdictComplaint = async (id, result) => {
    try {
      const token = getCookie('access_token');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.patch(
        `${URL}/api/complaint_system/detail/${id}/`,
        { status: result },
        config,
      );
      toast.success(i18n.toast.Thecomplaint[locale]);
      setComplaintsList(complaintsList.filter((item) => item.id !== id));
      setBanned(banned.filter((item) => item.id !== id));
      setLoading(true);
    } catch (err) {
      setError(err.response ? err.response.data : err.message);
      toast.error(i18n.toast.accepting[locale]);
      setLoading(false);
      setError(true);
    }
  };

  return {
    loading,
    error,
    complaintsList,
    setComplaintsList,
    createComplaint,
    getComplaintList,
    verdictComplaint,
  };
};

export default useComplaints;

import React from 'react';
import s from '@/styles/uiComp/catalog/FilterSubCate.module.scss';
import i18n from '@/lib/i18n';
import Link from 'next/link';
import { useRouter } from 'next/router';

function FilterSubCategoryCloth({ category, subcategory, undersubcategory }) {
  const router = useRouter();
  const { locale } = router;

  return (
    <div className={s.filter_subcategory}>
      <h4>{i18n.filters.title[locale]}</h4>
      <Link
        rel="canonical"
        href={category.pathname}
      >
        <span>
          <img src="/assets/icons/arrow.svg" alt="icon_error" />
          <p>{subcategory.title}</p>
        </span>
      </Link>
      <p className={s.current_subcategory}>
        {undersubcategory?.title}
      </p>
      {subcategory?.under_sub_categories?.map((sub) => (
        <Link
          rel="canonical"
          key={sub.id}
          href={sub.pathname}
        >
          <p className={s.current_subcategories}>
            <img src="/assets/icons/minus.svg" alt="" />
            {sub.title}
          </p>
        </Link>
      ))}
    </div>
  );
}

export default FilterSubCategoryCloth;

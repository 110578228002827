import React, { useState } from 'react';
import s from '@/styles/uiComp/ToggleButtons.module.scss';
import { useRouter } from 'next/router';
import i18n from '../../../lib/i18n';

export default function ToggleButtons({ title, selectedStatus, onSelectStatus }) {
  const { locale } = useRouter();
  return (
    <div className={s.form_status_event}>
      <h4>{title}</h4>
      <div className={s.status_event_btns}>
        <button
          type="button"
          className={selectedStatus === true ? s.btn_selected : s.btn_adult}
          onClick={() => onSelectStatus(true)}
        >
          {i18n.filters.default.yes[locale]}
        </button>
        <button
          type="button"
          className={selectedStatus === false ? s.btn_child_selected : s.btn_child}
          onClick={() => onSelectStatus(false)}
        >
          {i18n.filters.default.not[locale]}
        </button>
      </div>
    </div>
  );
}

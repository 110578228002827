import React, {
  FC,
  MouseEventHandler,
  useCallback,
  useMemo,
} from 'react';
import useFavorite from '@/hooks/products/useFavorite';
import { stopEvent } from '@/modules/shared/helpers';
import { IUser } from '@/modules/users/types';
import { useAuthContext, useOpenAuthModal } from '@/modules/auth';
import { IAd } from '../../types';
import FavouriteIcon from './favourite.svg';
import FavouriteEmptyIcon from './favourite-empty.svg';
import styles from './favourite-button.module.scss';

interface IFavouriteButtonProps {
  ad: IAd;
}
export const FavouriteButton: FC<IFavouriteButtonProps> = ({ ad }) => {
  const { createFavorite, deleteFavorite, userData } = useFavorite();
  const id = ad?.id;
  const openAuthModal = useOpenAuthModal();
  const { isAuthenticated } = useAuthContext();

  const isFavorite = useMemo(() => {
    const favorites = (userData as IUser[])
      ?.flatMap((user) => user.announcements || [])
      .map((favorite) => favorite.id) || [];
    return favorites.includes(id);
  }, [userData, id]);

  const onClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      if (!isAuthenticated) {
        openAuthModal('login');
      } else {
        stopEvent(event);
        if (isFavorite) {
          return deleteFavorite(id);
        }
        createFavorite(id);
      }
    },
    [createFavorite, deleteFavorite, id, isFavorite],
  );

  const Icon = isFavorite ? FavouriteIcon : FavouriteEmptyIcon;
  return (
    <button onClick={onClick} className={styles.root}>
      <Icon />
    </button>
  );
};

import React, { useState } from 'react';
import s from '@/styles/shared/btns/ContactBtn.module.scss';
import { useRouter } from 'next/router';
import { getCookie } from '@/components/cookie/cookieHelpers';
import i18n from '@/lib/i18n';
import { useOpenAuthModal } from '@/modules/auth/hooks';

function ContactBtnAdv({ userProfile, isAuthenticated }) {
  const router = useRouter();
  const userId = getCookie('user_id');
  const [showModal, setShowModal] = useState(false);
  const { locale } = useRouter();
  const openAuthModal = useOpenAuthModal();

  const handleChat = () => {
    if (userProfile?.user === Number(userId)) {
      return;
    }

    if (isAuthenticated) {
      router.push(`/user/chat/user/${userProfile.id}`);
    } else {
      openAuthModal('login');
    }
  };

  const handleMouseEnter = () => {
    if (isAuthenticated) {
      setShowModal(!showModal);
    } else {
      openAuthModal('login');
    }
  };

  return (
    <div className={s.contact_btn1}>
      <button
        onClick={handleChat}
        className={
          userProfile?.user === Number(userId)
            ? s.contact_btn_message_not_active
            : s.contact_btn_message
        }
        disabled={userProfile?.user === Number(userId)}
      >
        {i18n.header.dropdown.chat[locale]}
        <img src="/assets/icons/contact_message.svg" alt="error" />
      </button>

      <span className={s.contact_btn_phone} onClick={handleMouseEnter}>
        {i18n.user.forms.phoneNumber[locale]}
        <img src="/assets/icons/contact_phone.svg" alt="error" />
      </span>

      {isAuthenticated && showModal && (
        <div className={s.modal}>
          <div className={s.modal_contact_button}>
            <div onClick={handleMouseEnter} className={s.modal_button}>
              <span>{i18n.advDetail.hide[locale]}</span>
              <img src="/assets/icons/buttonNot.svg" alt="" />
            </div>
            <div className={s.modal_button_number}>
              <img src="/assets/icons/contactBlue.svg" alt="icon_error" />
              <a className={s.modal_button_number_color} href={`tel:${userProfile?.phone_number}`}>
                {userProfile?.phone_number}
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ContactBtnAdv;

import React, { useEffect, useState } from 'react';
import TypeButtons from '@/components/uiComp/createProduct/TypeButtons';
import { useRouter } from 'next/router';
import TypeElectronic from '../../uiComp/createProduct/TypeElectronic';
import i18n from '../../../lib/i18n';

export default function Computer({ onElectronicDataChange, defaultValue }) {
  const [type_electronic, setType_electronic] = useState('Б/у');
  const { locale } = useRouter();
  useEffect(() => {
    if (defaultValue) {
      setType_electronic(defaultValue.type_electronic);
    }
  }, [defaultValue]);
  useEffect(() => {
    const formData = {
      type_electronic,
    };

    onElectronicDataChange(formData);
  }, [
    type_electronic,
  ]);
  return (
    <div>
      <TypeElectronic
        title={i18n.createAdvFilds.electronics.type[locale]}
        selectedStatus={type_electronic}
        onSelectStatus={setType_electronic}
      />
    </div>
  );
}

import { useCategoriesContext } from '@/modules/categories';
import { useCallback } from 'react';


const useCategoriesData = () => {
  const {
    isLoading,
    error,
    categories,
  } = useCategoriesContext();

  const getSubcategoriesByCategoryTitle = useCallback((categoryTitle: string) => {
    const category = categories.find((c) => c.category_title === categoryTitle);
    return category ? category.sub_categories : [];
  }, [categories]);

  return {
    categories,
    getSubcategoriesByCategoryTitle,
    loading: isLoading,
    error,
  };
};

export default useCategoriesData;

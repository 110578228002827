import React from 'react';
import s from '@/styles/shared/adv/CategoryModal.module.scss';
import { useRouter } from 'next/router';
import i18n from '@/lib/i18n';

function SubCategories({ subcategories, onClose, categoryId }) {
  const { locale } = useRouter();
  return (
    <div className={s.block_modal_category}>
      <div className={s.modal_category}>
        <div className={s.category_title}>
          <img
            src="/assets/icons/filter_arrow_left.svg"
            alt="back"
            onClick={() => onClose('CATEGORIES')}
          />
          <h4>{i18n.selectCategory[locale]}</h4>
        </div>
        <div className={s.category_list}>
          {subcategories.map((sub) => (
            <div
              key={sub.id}
              className={s.category}
              onClick={() => onClose('UNDER_SUB_CATEGORIES', [], categoryId, sub.id)}
            >
              <p>{sub.title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SubCategories;

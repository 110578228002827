import React from 'react';
import LocationSubCate from '@/components/uiComp/catalog/LocationSubCate';
import MoreParametres from '@/components/uiComp/catalog/MoreParametres';
import FilterBtn from '@/components/uiComp/catalog/FilterBtn';
import { useRouter } from 'next/router';
import { useFilterFormState } from '@/modules/shared/hooks';
import s from '../moto/MotoFilters.module.scss';
import DescriptionSubCate from '../../uiComp/catalog/DescriptionSubCate';
import i18n from '../../../lib/i18n';
import FilterSubCategoryCloth from '../../uiComp/catalog/FilterSubCategoryCloth';

function SummaryJobFilters({
  category,
  subcategory,
  underSubCategory,
  getFilteringData,
  defaultValue,
}) {
  const [form] = useFilterFormState([
    'district',
    'search',
    'city',
    'schedule',
    'experience',
  ], defaultValue, getFilteringData);
  const { locale } = useRouter();

  const handleBtnFiltering = () => {
    getFilteringData(form.query);
  };

  const titleTypeJob = i18n.categories.workSchedule[locale];
  const TypeJob = [
    i18n.categories.fullDay[locale],
    i18n.categories.partTime[locale],
    i18n.categories.flexible[locale],
    '5/2',
    '6/1',
  ];
  const experienceJobTitle = i18n.categories.workExperience[locale];
  const experienceJob = [
    i18n.categories.withoutExperienceJob[locale],
    i18n.categories.moreThanOneYear[locale],
    i18n.categories.moreThan3Years[locale],
    i18n.categories.moreThan5Years[locale],
    i18n.categories.moreThan10Years[locale],
  ];
  return (
    <section className={s.container}>
      <div className={s.filter_block}>
        <FilterSubCategoryCloth
          category={category}
          subcategory={subcategory}
          undersubcategory={underSubCategory}
        />
        <LocationSubCate
          selectedDistrict={form.value.district}
          onSelectDistrict={form.setter.district}
          onSelectCity={form.setter.city}
          selectedCity={form.value.city}
        />
        <MoreParametres
          title={titleTypeJob}
          options={TypeJob}
          selected={form.value.schedule}
          onSelect={form.setter.schedule}
        />
        <MoreParametres
          title={experienceJobTitle}
          options={experienceJob}
          selected={form.value.experience}
          onSelect={form.setter.experience}
        />
        <DescriptionSubCate description={form.value.search} setDescription={form.setter.search} />
        <FilterBtn handleBtnFiltering={handleBtnFiltering} />
      </div>
    </section>
  );
}

export default SummaryJobFilters;

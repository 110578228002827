import i18n from '@/lib/i18n';

export const translateCategoryTitle = (categoryTitle, t) => {
  const translations = {
    Транспорт: t('categories.vehicles-rental._'),
    'Аренда транспорта': t('categories.vehicles-rental._'),
    Недвижимость: t('categories.real-estate._'),
    'Личные вещи': t('categories.personal-items._'),
    'Для дома': t('categories.home._'),
    'Запчасти и аксессуары': t('categories.spare-parts._'),
    Электроника: t('categories.electronics._'),
    'Хобби и отдых': t('categories.hobbies._'),
    Животные: t('categories.animals._'),
    'Бизнес и оборудование': t('categories.business._'),
    Бизнес: t('categories.business._'),
    Работа: t('categories.job._'),
    Услуги: t('categories.services._'),
  };
  return translations[categoryTitle] || categoryTitle;
};

export const translateSubCategoryTitle = (subCategoryTitle, locale) => {
  const subCategoryTranslations = {
    'Красота, здоровье': i18n.categories.beautyAndHealth[locale],
    'Озеленение, уход за садом и огородом':
      i18n.categories.greeningGardening[locale],
    'Земляные работы': i18n.categories.earthworks[locale],
    'Заборы, ограждения, навесы': i18n.categories.fencesCanopies[locale],
    'Организация праздников и мероприятий': i18n.categories.holidays[locale],
    'Няни, сиделки': i18n.underUnderSubCategory.interests.Nannies[locale],
    'Фото и видеосъемка': i18n.categories.Photoandvideoshooting[locale],
    Спорт: i18n.underUnderSubCategory.interests.Sports[locale],
    'Водоемы и фонтаны': i18n.categories.reservoirsFountains[locale],
    'Услуги эвакуатора': i18n.categories.Tow[locale],
    'Другое Ремонт и обслуживание техники': i18n.categories.Tow[locale],
    Автомобили: i18n.categories.cars[locale],
    'Мотоциклы и мототехника': i18n.categories.motorcycles[locale],
    Мопеды: i18n.categories.mopeds[locale],
    Трансферы: i18n.categories.transfer[locale],
    'Грузовики и спецтехника': i18n.categories.trucks[locale],
    'Воздушный транспорт': i18n.categories.airtransport[locale],
    'Водный транспорт': i18n.categories.Watertransport[locale],
    'Детская одежда и обувь': i18n.categories.childrensClothingShoes[locale],
    'Товары для детей и игрушки': i18n.categories.childrensProductsToys[locale],
    'Красота и здоровье ': i18n.categories.beautyAndHealth[locale],
    'Красота и здоровье Красота и здоровье':
      i18n.categories.beautyAndHealth[locale],
    'Бытовая техника': i18n.categories.householdAppliances[locale],
    'Ремонт и строительство': i18n.categories.repairAndFinishing[locale],
    Стройматериалы: i18n.categories.BuildingMaterials[locale],
    Инструменты: i18n.categories.Tools[locale],
    'Сантехника, водоснабжение и сауна':
      i18n.categories.PlumbingWaterSupplySauna[locale],
    Двери: i18n.categories.Doors[locale],
    'Садовая техника': i18n.categories.GardenEquipment[locale],

    'Мебель и интерьер': i18n.categories.furnitureAndInterior[locale],
    'Продукты питания': i18n.categories.foodProducts[locale],
    Растения: i18n.categories.plants[locale],
    'Посуда и товары для кухни': i18n.categories.tablewareKitchenGoods[locale],
    Телефоны: i18n.categories.phones[locale],
    Фототехника: i18n.categories.photographicEquipment[locale],
    'Аудио и видео': i18n.categories.audioVideo[locale],
    'Аудио- и видеотехника': i18n.categories.audioVideo[locale],
    'Планшеты и электронные книги': i18n.categories.tabletsEbooks[locale],
    'Товары для компьютера': i18n.categories.computerProducts[locale],
    'Игры, приставки и программы':
      i18n.categories.gamesConsolesPrograms[locale],
    Ноутбуки: i18n.categories.laptops[locale],
    'Настольные компьютеры': i18n.categories.desktopComputers[locale],
    Велосипеды: i18n.categories.bicycles[locale],
    'Книги и журналы': i18n.categories.booksMagazines[locale],
    'Музыкальные инструменты': i18n.categories.musicalInstruments[locale],
    'Спорт и отдых': i18n.categories.sportsRecreation[locale],
    Животные: i18n.categories.animals[locale],
    Собаки: i18n.categories.dogs[locale],
    Кошки: i18n.categories.cats[locale],
    Птицы: i18n.categories.birds[locale],
    Аквариум: i18n.categories.aquarium[locale],
    'Другие животные': i18n.categories.otherAnimals[locale],
    'Готовый бизнес': i18n.categories.readyMadeBusiness[locale],
    'Оборудование для бизнеса':
      i18n.categories.businessEquipmentForBusiness[locale],
    Работа: i18n.categories.job[locale],
    'Ищу работу': i18n.categories.lookingForAJob[locale],
    Другое: i18n.categories.other[locale],
    'Другое Товары для компьютера': i18n.categories.other[locale],

    Строительство: i18n.categories.building[locale],
    'Обучение и курсы': i18n.categories.trainingCourses[locale],
    'Бытовые услуги': i18n.categories.householdServices[locale],
    Грузоперевозки: i18n.categories.cargoTransportation[locale],
    'Мастер на час': i18n.categories.handyman[locale],
    'Сад, благоустройство': i18n.categories.gardenLandscaping[locale],
    'Деловые услуги': i18n.categories.businessServices[locale],
    'Праздники, мероприятия': i18n.categories.holidaysEvents[locale],
    'Охрана, безопасность': i18n.categories.safetySecurity[locale],
    'Снять посуточно': i18n.categories.rent[locale],
    'Снять долгосрочно': i18n.categories.long[locale],
    'Купить жильё': i18n.categories.buy[locale],
    Запчасти: i18n.categories.autoParts[locale],
    Инструменты: i18n.categories.tools[locale],
    'Шины, диски и колёса': i18n.categories.tiresWheels[locale],
    'Аудио и видео': i18n.categories.audioVideo[locale],
    'Аудио- и видеотехника': i18n.categories.audioVideo[locale],
    Прицепы: i18n.categories.trailers[locale],
    Аксессуары: i18n.categories.accessories[locale],
    ' Аксессуары Запчасти и аксессуары': i18n.categories.accessories[locale],
    'Масла и автохимия': i18n.categories.oilsChemicals[locale],
    Тюнинг: i18n.categories.tuning[locale],
    'GPS-навигаторы': i18n.categories.gps[locale],
    'Багажники и фаркопы': i18n.categories.racksHitches[locale],
    Экипировка: i18n.categories.gear[locale],
    'Оргтехника и расходники': i18n.categories.supplies[locale],
    'Охота и рыбалка': i18n.categories.huntingFishing[locale],
    Коллекционирование: i18n.categories.collectibles[locale],
    'Товары для животных': i18n.categories.petSupplies[locale],
    'Ищу сотрудника': i18n.categories.employment[locale],
    'Обучение и курсы': i18n.categories.educationCourses[locale],
    'Праздники и мероприятия': i18n.categories.events[locale],
    'Пассажирские перевозки': i18n.categories.passengerTransport[locale],
    'IT, интернет, телеком': i18n.categories.IT[locale],
    'Одежда, обувь, аксессуары': i18n.categories.cloth[locale],
    Одежда: i18n.categories.cloth[locale],
    Обувь: i18n.categories.Shoes123[locale],
    'Кофты и футболки': i18n.categories.sweatersAndTshirts[locale],
    'Пиджаки и костюмы': i18n.categories.jacketsAndSuits[locale],
    'Пиджаки и костюмы Женская одежда': i18n.categories.jacketsAndSuits[locale],
    Джинсы: i18n.categories.jeans[locale],
    'Джинсы Женская одежда': i18n.categories.jeans[locale],
    Брюки: i18n.categories.trousers[locale],
    'Брюки Женская одежда': i18n.categories.trousers[locale],
    Рубашки: i18n.categories.shirts[locale],
    'Спортивные костюмы': i18n.categories.sportSuits[locale],
    Шорты: i18n.categories.shorts[locale],
    'Нижнее белье': i18n.categories.underwear[locale],
    Платья: i18n.categories.dresses[locale],
    Юбки: i18n.categories.skirts[locale],
    'Джемперы, свитеры, кардиганы':
      i18n.categories.jumpersSweatersCardigans[locale],
    'Свадебные платья': i18n.categories.weddingDresses[locale],
    'Рубашки и блузки': i18n.categories.shirtsAndBlouses[locale],
    'Топы и футболки': i18n.categories.topsAndTshirts[locale],
    Купальники: i18n.categories.swimsuits[locale],
    Кроссовки: i18n.categories.sneakers[locale],
    Туфли: i18n.categories.shoes[locale],
    'Туфли Мужская обувь': i18n.categories.shoes[locale],
    Кеды: i18n.categories.plimsolls[locale],
    'Спортивная обувь': i18n.categories.sportShoes[locale],
    'Спортивная обувь Мужская обувь': i18n.categories.sportShoes[locale],
    'Рабочая обувь': i18n.categories.workShoes[locale],
    'Резиновая обувь': i18n.categories.rubberShoes[locale],
    Сандалии: i18n.categories.sandals[locale],
    'Сандалии Мужская обувь': i18n.categories.sandals[locale],
    'Шлепанцы и сланцы': i18n.categories.flipFlopsAndSlides[locale],
    'Домашняя обувь': i18n.categories.homeShoes[locale],
    'Домашняя обувь Мужская обувь': i18n.categories.homeShoes[locale],
    'Слипоны и эспадрильи': i18n.categories.slipOnsAndEspadrilles[locale],
    'Слипоны и эспадрильи Мужская обувь':
      i18n.categories.slipOnsAndEspadrilles[locale],
    'Уход за обувью': i18n.categories.shoeCare[locale],
    'Уход за обувью Мужская обувь': i18n.categories.shoeCare[locale],
    Босоножки: i18n.categories.sandalsForWomen[locale],
    'Батинки и полуботинки': i18n.categories.ankleBootsAndBooties[locale],
    'Кроссовки и кеды': i18n.categories.sneakersAndPlimsolls[locale],
    Балетки: i18n.categories.balletFlats[locale],
    'Сабо и мюли': i18n.categories.clogsAndMules[locale],
    'Головные уборы': i18n.categories.headwear[locale],
    Очки: i18n.categories.glasses[locale],
    'Ремни, пояса, подтяжки': i18n.categories.beltsAndBraces[locale],
    'Ювелирные украшения': i18n.categories.jewelry[locale],
    Часы: i18n.categories.watches[locale],
    'Зонты и дождевики': i18n.categories.umbrellasAndRaincoats[locale],
    'Аксессуары для волос': i18n.categories.hairAccessories[locale],
    'Галстуки и бабочки': i18n.categories.tiesAndBowties[locale],
    'Носки, чулки, колготки': i18n.categories.socksStockingsTights[locale],
    'Грузчики, складские услуги': i18n.categories.Loaders[locale],
    'Билеты и путешествия': i18n.categories.Tickets[locale],
    'Ремонт и обслуживание техники': i18n.categories.Repair[locale],
    'Компьютерная помощь': i18n.categories.Computerhelp[locale],
    'Создание сайтов и приложений':
      i18n.categories.websiteApplicationCreation[locale],
    'Установки и настройка ПО': i18n.categories.Softwareconfiguration[locale],
    'Оборудование, производство': i18n.categories.Equipment[locale],
    'Реклама, полиграфия': i18n.categories.Advertising[locale],
    Уборка: i18n.categories.Cleaning1[locale],
    'Услуги эвакуатора': i18n.categories.Tow[locale],
    'Ремонт и отделка': i18n.categories.decoration[locale],
    'Доставка еды и продуктов': i18n.categories.Delivery1[locale],
    'Фото и видио съемка': i18n.categories.shooting[locale],
    'Няни, сиделки': i18n.categories.Nannies[locale],
    'Уход за животными': i18n.categories.Animalcare[locale],
    'Телевизоры и проекторы': i18n.categories.projectors[locale],
    'Для автотранспорта': i18n.categories.forVehicles[locale],
    'Для мототранспорта': i18n.categories.forMotorVehicles[locale],
    'Организация праздников и мероприятий': i18n.categories.holidays[locale],
    'Няни, сиделки': i18n.underUnderSubCategory.interests.Nannies[locale],
    'Услуги эвакуатора': i18n.categories.Tow[locale],
    Резюме: i18n.categories.Summary[locale],
    Вакансии: i18n.categories.Vacancies[locale],
    'Дорожное строительство': i18n.categories.roadConstruction[locale],
    'Мужская одежда': i18n.categories.mensClothing[locale],
    'Женская одежда': i18n.categories.womensClothing[locale],
    'Мужская обувь': i18n.categories.mensShoes[locale],
    'Женская обувь': i18n.categories.womensShoes[locale],
    'Кофты и футболки': i18n.categories.sweatersAndTshirts[locale],
    'Пиджаки и костюмы': i18n.categories.jacketsAndSuits[locale],
    'Пиджаки и костюмы Женская одежда': i18n.categories.jacketsAndSuits[locale],
    Джинсы: i18n.categories.jeans[locale],
    'Джинсы Женская одежда': i18n.categories.jeans[locale],
    Брюки: i18n.categories.trousers[locale],
    'Брюки Женская одежда': i18n.categories.trousers[locale],
    Рубашки: i18n.categories.shirts[locale],
    'Спортивные костюмы': i18n.categories.sportSuits[locale],
    Шорты: i18n.categories.shorts[locale],
    'Нижнее белье': i18n.categories.underwear[locale],
    Другое: i18n.categories.otherClothes[locale],
    Платья: i18n.categories.dresses[locale],
    Юбки: i18n.categories.skirts[locale],
    'Джемперы, свитеры, кардиганы':
      i18n.categories.jumpersSweatersCardigans[locale],
    'Свадебные платья': i18n.categories.weddingDresses[locale],
    'Рубашки и блузки': i18n.categories.shirtsAndBlouses[locale],
    'Топы и футболки': i18n.categories.topsAndTshirts[locale],
    Купальники: i18n.categories.swimsuits[locale],
    Кроссовки: i18n.categories.sneakers[locale],
    Туфли: i18n.categories.shoes[locale],
    'Туфли Мужская обувь': i18n.categories.shoes[locale],
    Кеды: i18n.categories.plimsolls[locale],
    'Мокасины и лоферы Мужская обувь':
      i18n.categories.loafersAndMoccasins[locale],
    'Спортивная обувь Мужская обувь': i18n.categories.sportShoes[locale],
    'Спортивная обувь': i18n.categories.sportShoes[locale],
    'Рабочая обувь': i18n.categories.workShoes[locale],
    'Резиновая обувь': i18n.categories.rubberShoes[locale],
    Сандалии: i18n.categories.sandals[locale],
    'Сандалии Мужская обувь': i18n.categories.sandals[locale],
    'Шлепанцы и сланцы': i18n.categories.flipFlopsAndSlides[locale],
    'Домашняя обувь': i18n.categories.homeShoes[locale],
    'Домашняя обувь Мужская обувь': i18n.categories.homeShoes[locale],
    'Слипоны и эспадрильи': i18n.categories.slipOnsAndEspadrilles[locale],
    'Слипоны и эспадрильи Мужская обувь':
      i18n.categories.slipOnsAndEspadrilles[locale],
    'Уход за обувью': i18n.categories.shoeCare[locale],
    'Уход за обувью Мужская обувь': i18n.categories.shoeCare[locale],
    Босоножки: i18n.categories.sandalsForWomen[locale],
    'Батинки и полуботинки': i18n.categories.ankleBootsAndBooties[locale],
    'Кроссовки и кеды': i18n.categories.sneakersAndPlimsolls[locale],
    Балетки: i18n.categories.balletFlats[locale],
    'Сабо и мюли': i18n.categories.clogsAndMules[locale],
    'Головные уборы': i18n.categories.headwear[locale],
    Очки: i18n.categories.glasses[locale],
    'Ремни, пояса, подтяжки': i18n.categories.beltsAndBraces[locale],
    'Ювелирные украшения': i18n.categories.jewelry[locale],
    Часы: i18n.categories.watches[locale],
    'Зонты и дождевики': i18n.categories.umbrellasAndRaincoats[locale],
    'Аксессуары для волос': i18n.categories.hairAccessories[locale],
    'Галстуки и бабочки': i18n.categories.tiesAndBowties[locale],
    'Носки, чулки, колготки': i18n.categories.socksStockingsTights[locale],
    'Ремонт квартир и домов под ключ': i18n.categories.renovation[locale],
    Сантехника: i18n.categories.plumbing[locale],
    Электрика: i18n.categories.electrics[locale],
    'Сборка и ремонт мебели': i18n.categories.furnitureAssemblyRepair[locale],
    'Поклейка обоев и малярные работы':
      i18n.categories.wallpaperingPainting[locale],
    'Полы и напольные покрытия': i18n.categories.floorsFloorCoverings[locale],
    'Штукатурные работы': i18n.categories.plasteringWorks[locale],
    'Высотные работы': i18n.categories.highAltitudeWork[locale],
    'Металлоконструкции и кованные изделия':
      i18n.categories.metalStructuresForgedProducts[locale],
    Изоляция: i18n.categories.insulation[locale],
    'Ремонт офиса': i18n.categories.officeRenovation[locale],
    'Строительство домов под ключ':
      i18n.categories.turnkeyHouseConstruction[locale],
    'Кладочные работы': i18n.categories.masonryWork[locale],
    'Кровельные работы': i18n.categories.roofingWorks[locale],
    'Сварочные работы': i18n.categories.weldingWorks[locale],
    'Бетонные работы': i18n.categories.concreteWorks[locale],
    'Фундаментальные работы': i18n.categories.foundationWorks[locale],
    'Снос и демонтаж': i18n.categories.demolitionDismantling[locale],
    'Фасадные работы': i18n.categories.facadeWorks[locale],
    'Проектирование и сметы': i18n.categories.designEstimates[locale],
    'Водоемы и фонтаны': i18n.categories.reservoirsFountains[locale],
    'Дорожное строительство': i18n.categories.roadConstruction[locale],
    'Заборы, ограждения, навесы': i18n.categories.fencesCanopies[locale],
    'Земляные работы': i18n.categories.earthworks[locale],
    'Озеленение, уход за садом и огородом':
      i18n.categories.greeningGardening[locale],
    'Рольставни и ворота': i18n.categories.rollerShuttersGates[locale],
    Компьютеры: i18n.categories.computers[locale],
    Принтеры: i18n.categories.printers[locale],
    'Игровые приставки': i18n.categories.gameConsoles[locale],
    'ОС и программы': i18n.categories.osAndPrograms[locale],
    'Интернет и другие сети': i18n.categories.internetAndOtherNetworks[locale],
    'Бухгалтерия, финансы': i18n.categories.accountingFinance[locale],
    'Бухгалтерия, финансы Деловые услуги':
      i18n.categories.accountingFinance[locale],
    Перевод: i18n.categories.translation[locale],
    Страхование: i18n.categories.insurance[locale],
    'Юридические услуги': i18n.categories.legalServices[locale],
    'Маркетинг, реклама, PR': i18n.categories.marketingAdvertisingPR[locale],
    'Полиграфия, дизайн': i18n.categories.printingDesign[locale],
    'Генеральная уборка': i18n.categories.generalCleaning[locale],
    'Дезинфекция, дезинсекция':
      i18n.categories.disinfectionDisinsection[locale],
    'Мойка окон': i18n.categories.windowCleaning[locale],
    'Простая уборка': i18n.categories.simpleCleaning[locale],
    'Уборка после ремонта': i18n.categories.cleaningAfterRepair[locale],
    'Чистка ковров': i18n.categories.carpetCleaning[locale],
    'Чиста мягкой мебели': i18n.categories.upholsteredFurnitureCleaning[locale],
    'Изготовление ключей': i18n.categories.keyMaking[locale],
    'Пошив и ремонт одежды': i18n.categories.tailoringClothesRepair[locale],
    'Ремонт часов': i18n.categories.watchRepair[locale],
    'Химчистка, стирка': i18n.categories.dryCleaningLaundry[locale],
    'Ювелирные услуги': i18n.categories.jewelryServices[locale],
    Все: i18n.underUnderSubCategory.estate.all[locale],
    Вторичка: i18n.underUnderSubCategory.estate.secondary[locale],
    Новостройка: i18n.underUnderSubCategory.estate.building[locale],
    Вентиляция: i18n.underUnderSubCategory.services.Ventilation[locale],
    // Телевизоры: i18n.underUnderSubCategory.services.Ventilation[locale],
    'Кровати, диваны и кресла':
      i18n.underUnderSubCategory.services.armchairs[locale],
    'Шкафы, комоды и стеллажи':
      i18n.underUnderSubCategory.services.Wardrobes[locale],
    'Столы и стулья': i18n.underUnderSubCategory.services.Tables[locale],
    'Текстиль и ковры': i18n.underUnderSubCategory.services.Textiles[locale],
    Освещение: i18n.underUnderSubCategory.services.CookingLighting[locale],
    'Компьютерные столы и кресла':
      i18n.underUnderSubCategory.services.Computertables[locale],
    'Для кухни': i18n.underUnderSubCategory.services.Forkitchen[locale],
    'Для дома': i18n.underUnderSubCategory.services.Forhome[locale],
    'Климатические оборудование':
      i18n.underUnderSubCategory.services.Climatic[locale],
    'Для индивидуального ухода':
      i18n.underUnderSubCategory.services.individual[locale],
    'Сервировка стола':
      i18n.underUnderSubCategory.services.TableSetting[locale],
    'Приготовление пищи': i18n.underUnderSubCategory.services.Cooking[locale],
    'Хранение продуктов':
      i18n.underUnderSubCategory.services.FoodStorage[locale],
    'Приготовление напитков':
      i18n.underUnderSubCategory.services.BeveragePreparation[locale],
    'Хозяйственные товары':
      i18n.underUnderSubCategory.services.HouseholdGoods[locale],
    'Кухонные товары': i18n.underUnderSubCategory.services.KitchenGoods[locale],
    'Автомобильный бизнес':
      i18n.underUnderSubCategory.jobCategories.Automotive[locale],
    'Административная работа':
      i18n.underUnderSubCategory.jobCategories.Administrative[locale],
    'Без опыта': i18n.underUnderSubCategory.jobCategories.NoExperience[locale],
    'Бухгалтерия, финансы':
      i18n.underUnderSubCategory.jobCategories.AccountingFinance[locale],
    'Домашний персонал':
      i18n.underUnderSubCategory.jobCategories.HouseholdStaff[locale],
    'Искусство, развлечения':
      i18n.underUnderSubCategory.jobCategories.ArtsEntertainment[locale],
    Консультирование:
      i18n.underUnderSubCategory.jobCategories.Consulting[locale],
    'Маркетинг реклама, PR':
      i18n.underUnderSubCategory.jobCategories.MarketingPR[locale],
    'Образование, наука':
      i18n.underUnderSubCategory.jobCategories.EducationScience[locale],
    'Охрана, безопасность':
      i18n.underUnderSubCategory.jobCategories.Security[locale],
    Продажи: i18n.underUnderSubCategory.jobCategories.Sales[locale],
    'Туризм, рестораны':
      i18n.underUnderSubCategory.jobCategories.TourismRestaurants[locale],
    'Управление персоналом':
      i18n.underUnderSubCategory.jobCategories.PersonnelManagement[locale],
    'Фитнес, салоны красоты':
      i18n.underUnderSubCategory.jobCategories.FitnessBeauty[locale],
    Юриспруденция: i18n.underUnderSubCategory.jobCategories.Legal[locale],
    Концерты: i18n.underUnderSubCategory.interests.Concerts[locale],
    Путешествия: i18n.underUnderSubCategory.interests.Travel[locale],

    'Бильярд и боулинг':
      i18n.underUnderSubCategory.interests.BilliardsBowling[locale],
    'Дайвинг и водный спорт':
      i18n.underUnderSubCategory.interests.DivingWaterSports[locale],
    Единоброства: i18n.underUnderSubCategory.interests.MartialArts[locale],
    'Игры с мячом': i18n.underUnderSubCategory.interests.BallGames[locale],
    'Настольные игры': i18n.underUnderSubCategory.interests.BoardGames[locale],
    'Ролики и скейтбординг':
      i18n.underUnderSubCategory.interests.RollerSkatingSkateboarding[locale],
    'Теннис, бадминтон, пинг-понг':
      i18n.underUnderSubCategory.interests.TennisBadmintonPingPong[locale],
    Туризм: i18n.underUnderSubCategory.interests.Tourism[locale],
    'Фитнес и тренажеры':
      i18n.underUnderSubCategory.interests.FitnessExerciseMachines[locale],
    'Спортивное питание':
      i18n.underUnderSubCategory.interests.SportsNutrition[locale],
    'Мобильные телефоны':
      i18n.underUnderSubCategory.interests.MobilePhones[locale],
    'Системные блоки': i18n.underUnderSubCategory.interests.SystemUnits[locale],
    Моноблоки: i18n.underUnderSubCategory.interests.Monoblocks[locale],
    'Оборудование и аксессуары':
      i18n.underUnderSubCategory.interests.EquipmentAccessories[locale],
    Объективы: i18n.underUnderSubCategory.interests.Lenses[locale],
    'Компактные фотоаппараты':
      i18n.underUnderSubCategory.interests.CompactCameras[locale],
    'Пленочные фотоаппараты':
      i18n.underUnderSubCategory.interests.FilmCameras[locale],
    'Зеркальные фотоаппараты':
      i18n.underUnderSubCategory.interests.DSLRCameras[locale],
    'Бинокли и телескопы':
      i18n.underUnderSubCategory.interests.BinocularsTelescopes[locale],
    Планшеты: i18n.underUnderSubCategory.interests.Tablets[locale],
    'Организация праздников и мероприятий': i18n.categories.holidays[locale],
    'Няни, сиделки': i18n.underUnderSubCategory.interests.Nannies[locale],
    'Услуги эвакуатора': i18n.categories.Tow[locale],
    'Электронные книги': i18n.categories.eBooks[locale],
    'Шлёпанцы и сланцы': i18n.categories.flipFlopsSlates[locale],
    'Шлёпанцы и сланцы Мужская обувь': i18n.categories.flipFlopsSlates[locale],
    'Ботинки и полуботинки': i18n.categories.bootsHalfBoots[locale],
    'Нижнее бельё': i18n.categories.underwear[locale],
    'Нижнее бельё Женская одежда': i18n.categories.underwear[locale],
    'Компьютерные игры': i18n.categories.computerGames[locale],
    'Игры для приставок': i18n.categories.gamesForConsoles[locale],
    'Сервировка стола': i18n.underUnderSubCategory.services.armchairs[locale],
    'Телевизоры и проекторы': i18n.categories.projectors[locale],
    'Акустика, колонки, сабвуферы':
      i18n.categories.acousticsSpeakersSubwoofers[locale],
    Наушники: i18n.categories.headphones[locale],
    'Иностранные языки': i18n.categories.ForeignLanguages[locale],
    'Детское развитие, логопеды':
      i18n.categories.ChildDevelopmentSpeechTherapists[locale],
    'IT, бизнес': i18n.categories.ITBusiness[locale],
    'Дизайн, рисование': i18n.categories.DesignDrawing[locale],
    'Красота, здоровье': i18n.categories.BeautyHealth[locale],
    'Спорт, танцы': i18n.categories.SportsDance[locale],
    Вождение: i18n.categories.Driving[locale],
    'По городу': i18n.categories.city[locale],
    'Между городами': i18n.categories.between[locale],
    'Музыка, театр': i18n.categories.MusicTheater[locale],
    'Создание и продвижение сайтов':
      i18n.categories.WebsiteCreationPromotion[locale],
    'Установка и настройка ПО':
      i18n.categories.SoftwareInstallationConfiguration[locale],
    'Настройка интернета и сетей':
      i18n.categories.InternetNetworkConfiguration[locale],
    'Реклама, маркетинг, продвижение':
      i18n.categories.AdvertisingMarketingPromotion[locale],
    'Программирование, настройка CRM':
      i18n.categories.ProgrammingCRMConfiguration[locale],
    'Programming, CRM configuration':
      i18n.categories.WebsiteAppCreation[locale],
    Резюме: i18n.categories.Summary[locale],
    Вакансии: i18n.categories.Vacancies[locale],
    Рыбалка: i18n.categories.fishing[locale],
    'Маникюр, педикюр': i18n.categories.ManicurePedicure[locale],
    'Услуги, парикмахера': i18n.categories.HairdresserServices[locale],
    'Ресницы, брови': i18n.categories.EyelashesEyebrows[locale],
    Косметология: i18n.categories.Cosmetology[locale],
    Эпиляция: i18n.categories.Epilation[locale],
    'СПА-услуги, массаж': i18n.categories.SpaServicesMassage[locale],
    'Тату, пирсинг': i18n.categories.TattooPiercing[locale],
    Психология: i18n.categories.Psychology[locale],
    Диетология: i18n.categories.Dietetics[locale],
    'Аренда рабочего места': i18n.categories.WorkspaceRental[locale],
    Аксессуары: i18n.categories.accessories[locale],
    ' Аксессуары Запчасти и аксессуары': i18n.categories.accessories[locale],
    'Приборы и аксессуары': i18n.categories.DevicesAccessories[locale],
    'Приборы и аксессуары Красота и здоровье':
      i18n.categories.DevicesAccessories[locale],
    Парфюмерия: i18n.categories.Perfumery[locale],
    'Парфюмерия Красота и здоровье': i18n.categories.Perfumery[locale],
    'Средства гигиены': i18n.categories.HygieneProducts[locale],
    'Средства гигиены Красота и здоровье':
      i18n.categories.HygieneProducts[locale],
    'Косметика Красота и здоровье': i18n.categories.Cosmetics[locale],
    'Средства для волос Красота и здоровье':
      i18n.categories.HairCareProducts[locale],
    'Медицинские изделия Красота и здоровье':
      i18n.categories.MedicalDevices[locale],
    /// ////////////
    'IT Резюме': i18n.underUnderSubCategory.jobCategories.developer[locale],
    'Автомобильный бизнес Резюме':
      i18n.underUnderSubCategory.jobCategories.Automotive[locale],
    'Административная работа Резюме':
      i18n.underUnderSubCategory.jobCategories.Administrative[locale],
    'Без опыта Резюме':
      i18n.underUnderSubCategory.jobCategories.NoExperience[locale],
    'Бухгалтерия, финансы Резюме':
      i18n.underUnderSubCategory.jobCategories.AccountingFinance[locale],
    'Домашний персонал Резюме':
      i18n.underUnderSubCategory.jobCategories.HouseholdStaff[locale],
    'Искусство, развлечения Резюме':
      i18n.underUnderSubCategory.jobCategories.ArtsEntertainment[locale],
    'Консультирование Резюме':
      i18n.underUnderSubCategory.jobCategories.Consulting[locale],
    'Маркетинг реклама, PR Резюме':
      i18n.underUnderSubCategory.jobCategories.MarketingPR[locale],
    'Образование, наука Резюме':
      i18n.underUnderSubCategory.jobCategories.EducationScience[locale],
    'Охрана, безопасность Резюме':
      i18n.underUnderSubCategory.jobCategories.Security[locale],
    'Продажи Резюме': i18n.underUnderSubCategory.jobCategories.Sales[locale],
    'Туризм, рестораны Резюме':
      i18n.underUnderSubCategory.jobCategories.TourismRestaurants[locale],
    'Управление персоналом Резюме':
      i18n.underUnderSubCategory.jobCategories.PersonnelManagement[locale],
    'Фитнес, салоны красоты Резюме':
      i18n.underUnderSubCategory.jobCategories.FitnessBeauty[locale],
    'Юриспруденция Резюме':
      i18n.underUnderSubCategory.jobCategories.Legal[locale],
    Телевизоры: i18n.categories.TVs[locale],
    'Мобильные устройства': i18n.categories.MobileDevices[locale],
    'Фото, аудио, видеотехника':
      i18n.categories.PhotoAudioVideoEquipment[locale],
    'Кондиционеры, вентиляция':
      i18n.categories.AirConditionersVentilation[locale],
    'Стиральные, сушильные машины':
      i18n.categories.WashingDryingMachines[locale],
    'Посудомоечные машины': i18n.categories.Dishwashers[locale],
    'Холодильники, морозильные камеры':
      i18n.categories.RefrigeratorsFreezers[locale],
    'Варочные панели, духовые шкафы': i18n.categories.CooktopsOvens[locale],
    Водонагреватели: i18n.categories.WaterHeaters[locale],
    Кофемашины: i18n.categories.CoffeeMachines[locale],
    'Швейные машины, оверлоки': i18n.categories.SewingMachinesOverlocks[locale],
    'Аренда оборудования': i18n.categories.EquipmentRental[locale],
    'Монтаж и обслуживание оборудования':
      i18n.categories.EquipmentInstallationMaintenance[locale],
    'Установки и настройка ПО': i18n.categories.Softwareconfiguration[locale],
    'Аксессуары Аксессуары': i18n.categories.accessories[locale],
    /// /////////////////////////////
    'Средства для волос': i18n.categories.HairCareProducts[locale],
    'Медицинские изделия': i18n.categories.MedicalDevices[locale],
    'Другое Женская одежда': i18n.categories.other[locale],
    'Другое Женская обувь': i18n.categories.other[locale],
    'Другое Мужская одежда': i18n.categories.other[locale],
    'Другое Мужская обувь': i18n.categories.other[locale],
    'Другое Ремонт и строительство': i18n.categories.other[locale],
    'Другое Билеты и путешествия': i18n.categories.other[locale],
    'Другое Мебель и интерьер': i18n.categories.other[locale],
    'Другое Строительствор': i18n.categories.other[locale],
    'Другое Настольные компьютеры': i18n.categories.other[locale],

    'Другое  Ремонт и отделка': i18n.categories.other[locale],
    'Другое Бытовая техника': i18n.categories.other[locale],
    'Другое Бытовые услуги': i18n.categories.other[locale],

    'Другое Красота и здоровье': i18n.categories.other[locale],

    'Другое Ремонт и обслуживание техники': i18n.categories.other[locale],
    'Другое Аксессуары': i18n.categories.other[locale],
    'Детские коляски': i18n.categories.babyStrollers[locale],
    'Детская мебель': i18n.categories.childrensFurniture[locale],
    'Велосипеды и самокаты': i18n.categories.bicyclesScooters[locale],
    'Товары для кормления': i18n.categories.feedingProducts[locale],
    'Автомобильные кресла': i18n.categories.carSeats[locale],
    Игрушки: i18n.categories.toys[locale],
    'Товары для купания': i18n.categories.bathingProducts[locale],
    'Товары для школы': i18n.categories.schoolSupplies[locale],
    'Другое Товары для детей и игрушки': i18n.categories.other[locale],
    'Товары для компьютера': i18n.categories.computerProducts[locale],
    Комплектующие: i18n.categories.computerAccessories[locale],
    Мониторы: i18n.categories.monitors[locale],
    'Клавиатуры и мыши': i18n.categories.keyboardsMice[locale],
  };
  return subCategoryTranslations[subCategoryTitle] || subCategoryTitle;
};

export const translateUnderSubCategory = (categoryTitle, locale) => {
  const translations = {
    'Мокасины и лоферы Мужская обувь':
      i18n.categories.loafersAndMoccasins[locale],
    'Мокасины и лоферы':
    i18n.categories.loafersAndMoccasins[locale],
    'Другое Настольные компьютеры': i18n.categories.other[locale],
    'Другое Аксессуары': i18n.categories.other[locale],
    'Другое Красота и здоровье': i18n.categories.other[locale],
    'Другое Ремонт и обслуживание техники': i18n.categories.other[locale],
    'Другое Товары для компьютера': i18n.categories.other[locale],
    'Фото и видеосъемка': i18n.categories.Photoandvideoshooting[locale],
    'Другое Бытовые услуги': i18n.categories.other[locale],
    Телевизоры: i18n.categories.TVs[locale],
    'Мобильные устройства': i18n.categories.MobileDevices[locale],
    'Фото, аудио, видеотехника':
      i18n.categories.PhotoAudioVideoEquipment[locale],
    'Кондиционеры, вентиляция':
      i18n.categories.AirConditionersVentilation[locale],
    'Стиральные, сушильные машины':
      i18n.categories.WashingDryingMachines[locale],
    'Посудомоечные машины': i18n.categories.Dishwashers[locale],
    'Холодильники, морозильные камеры':
      i18n.categories.RefrigeratorsFreezers[locale],
    'Варочные панели, духовые шкафы': i18n.categories.CooktopsOvens[locale],
    Водонагреватели: i18n.categories.WaterHeaters[locale],
    Кофемашины: i18n.categories.CoffeeMachines[locale],
    'Швейные машины, оверлоки': i18n.categories.SewingMachinesOverlocks[locale],
    'Другое Мебель и интерьер': i18n.categories.other[locale],
    'Другое Аудио и видео': i18n.categories.other[locale],
    'Другое Уборка': i18n.categories.other[locale],
    'Другое IT, интернет, телеком': i18n.categories.other[locale],
    'Другое Деловые услуги': i18n.categories.other[locale],
    'Другое Обучение и курсы': i18n.categories.other[locale],
    'Другое Оборудование, производство': i18n.categories.other[locale],
    'Другое Компьютерная помощь': i18n.categories.other[locale],
    'Другое Красота, здоровье': i18n.categories.other[locale],
    'Другое Сад, благоустройство': i18n.categories.other[locale],
    'Другое Строительство': i18n.categories.other[locale],
    'Другое  Ремонт и отделка': i18n.categories.other[locale],
    'Другое Бытовая техника': i18n.categories.other[locale],
    'Клавиатуры и мыши': i18n.categories.keyboardsMice[locale],
    /// /////////////////////////////////////////////////////////////
    'Другое Билеты и путешествия': i18n.categories.other[locale],
    'Другое Ремонт и строительство': i18n.categories.other[locale],
    'Другое Женская одежда': i18n.categories.other[locale],
    'Другое Женская обувь': i18n.categories.other[locale],
    'Другое Мужская одежда': i18n.categories.other[locale],
    'Другое Мужская обувь': i18n.categories.other[locale],
    'Бухгалтерия, финансы Деловые услуги':
      i18n.categories.accountingFinance[locale],
    'Приборы и аксессуары': i18n.categories.DevicesAccessories[locale],
    'Приборы и аксессуары Красота и здоровье':
      i18n.categories.DevicesAccessories[locale],
    'Аренда оборудования': i18n.categories.EquipmentRental[locale],
    'Монтаж и обслуживание оборудования':
      i18n.categories.EquipmentInstallationMaintenance[locale],
    Парфюмерия: i18n.categories.Perfumery[locale],
    'Парфюмерия Красота и здоровье': i18n.categories.Perfumery[locale],
    'Автомобильный бизнес Резюме':
      i18n.underUnderSubCategory.jobCategories.Automotive[locale],
    'IT Резюме': i18n.underUnderSubCategory.jobCategories.developer[locale],
    'Административная работа Резюме':
      i18n.underUnderSubCategory.jobCategories.Administrative[locale],
    'Без опыта Резюме':
      i18n.underUnderSubCategory.jobCategories.NoExperience[locale],
    'Бухгалтерия, финансы Резюме':
      i18n.underUnderSubCategory.jobCategories.AccountingFinance[locale],
    'Домашний персонал Резюме':
      i18n.underUnderSubCategory.jobCategories.HouseholdStaff[locale],
    'Искусство, развлечения Резюме':
      i18n.underUnderSubCategory.jobCategories.ArtsEntertainment[locale],
    'Консультирование Резюме':
      i18n.underUnderSubCategory.jobCategories.Consulting[locale],
    'Маркетинг реклама, PR Резюме':
      i18n.underUnderSubCategory.jobCategories.MarketingPR[locale],
    'Образование, наука Резюме':
      i18n.underUnderSubCategory.jobCategories.EducationScience[locale],
    'Охрана, безопасность Резюме':
      i18n.underUnderSubCategory.jobCategories.Security[locale],
    'Продажи Резюме': i18n.underUnderSubCategory.jobCategories.Sales[locale],
    'Туризм, рестораны Резюме':
      i18n.underUnderSubCategory.jobCategories.TourismRestaurants[locale],
    'Управление персоналом Резюме':
      i18n.underUnderSubCategory.jobCategories.PersonnelManagement[locale],
    'Фитнес, салоны красоты Резюме':
      i18n.underUnderSubCategory.jobCategories.FitnessBeauty[locale],
    'Юриспруденция Резюме':
      i18n.underUnderSubCategory.jobCategories.Legal[locale],
    'Средства гигиены': i18n.categories.HygieneProducts[locale],
    'Средства гигиены Красота и здоровье':
      i18n.categories.HygieneProducts[locale],
    'Косметика Красота и здоровье': i18n.categories.Cosmetics[locale],
    'Средства для волос Красота и здоровье':
      i18n.categories.HairCareProducts[locale],
    'Медицинские изделия Красота и здоровье':
      i18n.categories.MedicalDevices[locale],
    'Для мальчиков': i18n.categories.ForBoys[locale],
    'Для девочек': i18n.categories.ForGirls[locale],
    Аксессуары: i18n.categories.accessories[locale],
    ' Аксессуары Запчасти и аксессуары': i18n.categories.accessories[locale],
    'Аксессуары Аксессуары': i18n.categories.accessories[locale],
    'Красота и здоровье': i18n.categories.beautyAndHealth[locale],
    'Красота и здоровье Красота и здоровье':
      i18n.categories.beautyAndHealth[locale],
    'Маникюр, педикюр': i18n.categories.ManicurePedicure[locale],
    'Услуги, парикмахера': i18n.categories.HairdresserServices[locale],
    'Ресницы, брови': i18n.categories.EyelashesEyebrows[locale],
    Косметология: i18n.categories.Cosmetology[locale],
    Эпиляция: i18n.categories.Epilation[locale],
    'СПА-услуги, массаж': i18n.categories.SpaServicesMassage[locale],
    'Тату, пирсинг': i18n.categories.TattooPiercing[locale],
    Психология: i18n.categories.Psychology[locale],
    Диетология: i18n.categories.Dietetics[locale],
    'Аренда рабочего места': i18n.categories.WorkspaceRental[locale],
    'Создание сайтов и приложений':
      i18n.categories.websiteApplicationCreation[locale],
    'Установки и настройка ПО': i18n.categories.Softwareconfiguration[locale],
    'Билеты и путешествия': i18n.categories.Tickets[locale],
    Спорт: i18n.underUnderSubCategory.interests.Sports[locale],
    Рыбалка: i18n.categories.fishing[locale],
    Резюме: i18n.categories.Summary[locale],
    Вакансии: i18n.categories.Vacancies[locale],
    'Создание и продвижение сайтов':
      i18n.categories.WebsiteCreationPromotion[locale],
    'Установка и настройка ПО':
      i18n.categories.SoftwareInstallationConfiguration[locale],
    'Настройка интернета и сетей':
      i18n.categories.InternetNetworkConfiguration[locale],
    'Реклама, маркетинг, продвижение':
      i18n.categories.AdvertisingMarketingPromotion[locale],
    'Программирование, настройка CRM':
      i18n.categories.ProgrammingCRMConfiguration[locale],
    'Programming, CRM configuration':
      i18n.categories.WebsiteAppCreation[locale],
    Наушники: i18n.categories.headphones[locale],
    'По городу': i18n.categories.city[locale],
    'Между городами': i18n.categories.between[locale],
    'Иностранные языки': i18n.categories.ForeignLanguages[locale],
    'Детское развитие, логопеды':
      i18n.categories.ChildDevelopmentSpeechTherapists[locale],
    'IT, бизнес': i18n.categories.ITBusiness[locale],
    'Дизайн, рисование': i18n.categories.DesignDrawing[locale],
    'Красота, здоровье': i18n.categories.BeautyHealth[locale],
    'Спорт, танцы': i18n.categories.SportsDance[locale],
    Вождение: i18n.categories.Driving[locale],
    'Музыка, театр': i18n.categories.MusicTheater[locale],
    'Акустика, колонки, сабвуферы':
      i18n.categories.acousticsSpeakersSubwoofers[locale],
    'Товары для компьютера': i18n.categories.computerProducts[locale],
    Комплектующие: i18n.categories.computerAccessories[locale],
    Мониторы: i18n.categories.monitors[locale],
    'Телевизоры и проекторы': i18n.categories.projectors[locale],
    'Компьютерные игры': i18n.categories.computerGames[locale],
    'Игры для приставок': i18n.categories.gamesForConsoles[locale],
    'Сервировка стола': i18n.underUnderSubCategory.services.armchairs[locale],
    'Кровати, диваны и кресла':
      i18n.underUnderSubCategory.services.armchairs[locale],
    'Шлёпанцы и сланцы': i18n.categories.flipFlopsSlates[locale],
    'Шлёпанцы и сланцы Мужская обувь': i18n.categories.flipFlopsSlates[locale],
    'Ботинки и полуботинки': i18n.categories.bootsHalfBoots[locale],
    'Нижнее бельё': i18n.categories.underwear[locale],
    'Нижнее бельё Женская одежда': i18n.categories.underwear[locale],
    Аксессуары: i18n.categories.accessories[locale],
    'Электронные книги': i18n.categories.eBooks[locale],
    Стройматериалы: i18n.categories.BuildingMaterials[locale],
    Инструменты: i18n.categories.Tools[locale],
    'Сантехника, водоснабжение и сауна':
      i18n.categories.PlumbingWaterSupplySauna[locale],
    Двери: i18n.categories.Doors[locale],
    'Садовая техника': i18n.categories.GardenEquipment[locale],
    'Мужская одежда': i18n.categories.mensClothing[locale],
    'Женская одежда': i18n.categories.womensClothing[locale],
    'Мужская обувь': i18n.categories.mensShoes[locale],
    'Женская обувь': i18n.categories.womensShoes[locale],
    'Кофты и футболки': i18n.categories.sweatersAndTshirts[locale],
    'Пиджаки и костюмы': i18n.categories.jacketsAndSuits[locale],
    'Пиджаки и костюмы Женская одежда': i18n.categories.jacketsAndSuits[locale],
    Джинсы: i18n.categories.jeans[locale],
    'Джинсы Женская одежда': i18n.categories.jeans[locale],
    Брюки: i18n.categories.trousers[locale],
    'Брюки Женская одежда': i18n.categories.trousers[locale],
    Рубашки: i18n.categories.shirts[locale],
    'Спортивные костюмы': i18n.categories.sportSuits[locale],
    Шорты: i18n.categories.shorts[locale],
    'Нижнее белье': i18n.categories.underwear[locale],
    Другое: i18n.categories.otherClothes[locale],
    Платья: i18n.categories.dresses[locale],
    Юбки: i18n.categories.skirts[locale],
    'Джемперы, свитеры, кардиганы':
      i18n.categories.jumpersSweatersCardigans[locale],
    'Свадебные платья': i18n.categories.weddingDresses[locale],
    'Рубашки и блузки': i18n.categories.shirtsAndBlouses[locale],
    'Топы и футболки': i18n.categories.topsAndTshirts[locale],
    Купальники: i18n.categories.swimsuits[locale],
    Кроссовки: i18n.categories.sneakers[locale],
    Туфли: i18n.categories.shoes[locale],
    'Туфли Мужская обувь': i18n.categories.shoes[locale],
    Кеды: i18n.categories.plimsolls[locale],
    'Спортивная обувь Мужская обувь': i18n.categories.sportShoes[locale],
    'Спортивная обувь': i18n.categories.sportShoes[locale],
    'Рабочая обувь': i18n.categories.workShoes[locale],
    'Резиновая обувь': i18n.categories.rubberShoes[locale],
    Сандалии: i18n.categories.sandals[locale],
    'Сандалии Мужская обувь': i18n.categories.sandals[locale],
    'Шлепанцы и сланцы': i18n.categories.flipFlopsAndSlides[locale],
    'Домашняя обувь': i18n.categories.homeShoes[locale],
    'Домашняя обувь Мужская обувь': i18n.categories.homeShoes[locale],
    'Слипоны и эспадрильи': i18n.categories.slipOnsAndEspadrilles[locale],
    'Слипоны и эспадрильи Мужская обувь':
      i18n.categories.slipOnsAndEspadrilles[locale],
    'Уход за обувью': i18n.categories.shoeCare[locale],
    'Уход за обувью Мужская обувь': i18n.categories.shoeCare[locale],
    Босоножки: i18n.categories.sandalsForWomen[locale],
    'Батинки и полуботинки': i18n.categories.ankleBootsAndBooties[locale],
    'Кроссовки и кеды': i18n.categories.sneakersAndPlimsolls[locale],
    Балетки: i18n.categories.balletFlats[locale],
    'Сабо и мюли': i18n.categories.clogsAndMules[locale],
    'Головные уборы': i18n.categories.headwear[locale],
    Очки: i18n.categories.glasses[locale],
    'Ремни, пояса, подтяжки': i18n.categories.beltsAndBraces[locale],
    'Ювелирные украшения': i18n.categories.jewelry[locale],
    Часы: i18n.categories.watches[locale],
    'Зонты и дождевики': i18n.categories.umbrellasAndRaincoats[locale],
    'Аксессуары для волос': i18n.categories.hairAccessories[locale],
    'Галстуки и бабочки': i18n.categories.tiesAndBowties[locale],
    'Носки, чулки, колготки': i18n.categories.socksStockingsTights[locale],
    'Ремонт квартир и домов под ключ': i18n.categories.renovation[locale],
    Сантехника: i18n.categories.plumbing[locale],
    Электрика: i18n.categories.electrics[locale],
    'Сборка и ремонт мебели': i18n.categories.furnitureAssemblyRepair[locale],
    'Поклейка обоев и малярные работы':
      i18n.categories.wallpaperingPainting[locale],
    'Полы и напольные покрытия': i18n.categories.floorsFloorCoverings[locale],
    'Штукатурные работы': i18n.categories.plasteringWorks[locale],
    'Высотные работы': i18n.categories.highAltitudeWork[locale],
    'Металлоконструкции и кованные изделия':
      i18n.categories.metalStructuresForgedProducts[locale],
    Изоляция: i18n.categories.insulation[locale],
    'Ремонт офиса': i18n.categories.officeRenovation[locale],
    'Строительство домов под ключ':
      i18n.categories.turnkeyHouseConstruction[locale],
    'Кладочные работы': i18n.categories.masonryWork[locale],
    'Кровельные работы': i18n.categories.roofingWorks[locale],
    'Сварочные работы': i18n.categories.weldingWorks[locale],
    'Бетонные работы': i18n.categories.concreteWorks[locale],
    'Фундаментальные работы': i18n.categories.foundationWorks[locale],
    'Снос и демонтаж': i18n.categories.demolitionDismantling[locale],
    'Фасадные работы': i18n.categories.facadeWorks[locale],
    'Проектирование и сметы': i18n.categories.designEstimates[locale],
    'Водоемы и фонтаны': i18n.categories.reservoirsFountains[locale],
    'Дорожное строительство': i18n.categories.roadConstruction[locale],
    'Заборы, ограждения, навесы': i18n.categories.fencesCanopies[locale],
    'Земляные работы': i18n.categories.earthworks[locale],
    'Озеленение, уход за садом и огородом':
      i18n.categories.greeningGardening[locale],
    'Рольставни и ворота': i18n.categories.rollerShuttersGates[locale],
    Компьютеры: i18n.categories.computers[locale],
    Принтеры: i18n.categories.printers[locale],
    'Игровые приставки': i18n.categories.gameConsoles[locale],
    'ОС и программы': i18n.categories.osAndPrograms[locale],
    'Интернет и другие сети': i18n.categories.internetAndOtherNetworks[locale],
    'Бухгалтерия, финансы': i18n.categories.accountingFinance[locale],
    Перевод: i18n.categories.translation[locale],
    Страхование: i18n.categories.insurance[locale],
    'Юридические услуги': i18n.categories.legalServices[locale],
    'Маркетинг, реклама, PR': i18n.categories.marketingAdvertisingPR[locale],
    'Полиграфия, дизайн': i18n.categories.printingDesign[locale],
    'Генеральная уборка': i18n.categories.generalCleaning[locale],
    'Дезинфекция, дезинсекция':
      i18n.categories.disinfectionDisinsection[locale],
    'Мойка окон': i18n.categories.windowCleaning[locale],
    'Простая уборка': i18n.categories.simpleCleaning[locale],
    'Уборка после ремонта': i18n.categories.cleaningAfterRepair[locale],
    'Чистка ковров': i18n.categories.carpetCleaning[locale],
    'Чиста мягкой мебели': i18n.categories.upholsteredFurnitureCleaning[locale],
    'Изготовление ключей': i18n.categories.keyMaking[locale],
    'Пошив и ремонт одежды': i18n.categories.tailoringClothesRepair[locale],
    'Ремонт часов': i18n.categories.watchRepair[locale],
    'Химчистка, стирка': i18n.categories.dryCleaningLaundry[locale],
    'Ювелирные услуги': i18n.categories.jewelryServices[locale],
    Все: i18n.underUnderSubCategory.estate.all[locale],
    Вторичка: i18n.underUnderSubCategory.estate.secondary[locale],
    Новостройка: i18n.underUnderSubCategory.estate.building[locale],
    Вентиляция: i18n.underUnderSubCategory.services.Ventilation[locale],
    // Телевизоры: i18n.underUnderSubCategory.services.Ventilation[locale],
    'Кровати, диваны и кресла':
      i18n.underUnderSubCategory.services.armchairs[locale],
    'Шкафы, комоды и стеллажи':
      i18n.underUnderSubCategory.services.Wardrobes[locale],
    'Столы и стулья': i18n.underUnderSubCategory.services.Tables[locale],
    'Текстиль и ковры': i18n.underUnderSubCategory.services.Textiles[locale],
    Освещение: i18n.underUnderSubCategory.services.CookingLighting[locale],
    'Компьютерные столы и кресла':
      i18n.underUnderSubCategory.services.Computertables[locale],
    'Для кухни': i18n.underUnderSubCategory.services.Forkitchen[locale],
    'Для дома': i18n.underUnderSubCategory.services.Forhome[locale],
    'Климатические оборудование':
      i18n.underUnderSubCategory.services.Climatic[locale],
    'Для индивидуального ухода':
      i18n.underUnderSubCategory.services.individual[locale],
    'Сервировка стола':
      i18n.underUnderSubCategory.services.TableSetting[locale],
    'Приготовление пищи': i18n.underUnderSubCategory.services.Cooking[locale],
    'Хранение продуктов':
      i18n.underUnderSubCategory.services.FoodStorage[locale],
    'Приготовление напитков':
      i18n.underUnderSubCategory.services.BeveragePreparation[locale],
    'Хозяйственные товары':
      i18n.underUnderSubCategory.services.HouseholdGoods[locale],
    'Кухонные товары': i18n.underUnderSubCategory.services.KitchenGoods[locale],
    'Автомобильный бизнес':
      i18n.underUnderSubCategory.jobCategories.Automotive[locale],
    'Административная работа':
      i18n.underUnderSubCategory.jobCategories.Administrative[locale],
    'Без опыта': i18n.underUnderSubCategory.jobCategories.NoExperience[locale],
    'Бухгалтерия, финансы':
      i18n.underUnderSubCategory.jobCategories.AccountingFinance[locale],
    'Домашний персонал':
      i18n.underUnderSubCategory.jobCategories.HouseholdStaff[locale],
    'Искусство, развлечения':
      i18n.underUnderSubCategory.jobCategories.ArtsEntertainment[locale],
    Консультирование:
      i18n.underUnderSubCategory.jobCategories.Consulting[locale],
    'Маркетинг реклама, PR':
      i18n.underUnderSubCategory.jobCategories.MarketingPR[locale],
    'Образование, наука':
      i18n.underUnderSubCategory.jobCategories.EducationScience[locale],
    'Охрана, безопасность':
      i18n.underUnderSubCategory.jobCategories.Security[locale],
    Продажи: i18n.underUnderSubCategory.jobCategories.Sales[locale],
    'Туризм, рестораны':
      i18n.underUnderSubCategory.jobCategories.TourismRestaurants[locale],
    'Управление персоналом':
      i18n.underUnderSubCategory.jobCategories.PersonnelManagement[locale],
    'Фитнес, салоны красоты':
      i18n.underUnderSubCategory.jobCategories.FitnessBeauty[locale],
    Юриспруденция: i18n.underUnderSubCategory.jobCategories.Legal[locale],
    Концерты: i18n.underUnderSubCategory.interests.Concerts[locale],
    Путешествия: i18n.underUnderSubCategory.interests.Travel[locale],

    'Бильярд и боулинг':
      i18n.underUnderSubCategory.interests.BilliardsBowling[locale],
    'Дайвинг и водный спорт':
      i18n.underUnderSubCategory.interests.DivingWaterSports[locale],
    Единоброства: i18n.underUnderSubCategory.interests.MartialArts[locale],
    'Игры с мячом': i18n.underUnderSubCategory.interests.BallGames[locale],
    'Настольные игры': i18n.underUnderSubCategory.interests.BoardGames[locale],
    'Ролики и скейтбординг':
      i18n.underUnderSubCategory.interests.RollerSkatingSkateboarding[locale],
    'Теннис, бадминтон, пинг-понг':
      i18n.underUnderSubCategory.interests.TennisBadmintonPingPong[locale],
    Туризм: i18n.underUnderSubCategory.interests.Tourism[locale],
    'Фитнес и тренажеры':
      i18n.underUnderSubCategory.interests.FitnessExerciseMachines[locale],
    'Спортивное питание':
      i18n.underUnderSubCategory.interests.SportsNutrition[locale],
    'Мобильные телефоны':
      i18n.underUnderSubCategory.interests.MobilePhones[locale],
    'Системные блоки': i18n.underUnderSubCategory.interests.SystemUnits[locale],
    Моноблоки: i18n.underUnderSubCategory.interests.Monoblocks[locale],
    'Оборудование и аксессуары':
      i18n.underUnderSubCategory.interests.EquipmentAccessories[locale],
    Объективы: i18n.underUnderSubCategory.interests.Lenses[locale],
    'Компактные фотоаппараты':
      i18n.underUnderSubCategory.interests.CompactCameras[locale],
    'Пленочные фотоаппараты':
      i18n.underUnderSubCategory.interests.FilmCameras[locale],
    'Зеркальные фотоаппараты':
      i18n.underUnderSubCategory.interests.DSLRCameras[locale],
    'Бинокли и телескопы':
      i18n.underUnderSubCategory.interests.BinocularsTelescopes[locale],
    Планшеты: i18n.underUnderSubCategory.interests.Tablets[locale],
    'Организация праздников и мероприятий': i18n.categories.holidays[locale],
    'Няни, сиделки': i18n.underUnderSubCategory.interests.Nannies[locale],
    'Услуги эвакуатора': i18n.categories.Tow[locale],
    'Детские коляски': i18n.categories.babyStrollers[locale],
    'Детская мебель': i18n.categories.childrensFurniture[locale],
    'Велосипеды и самокаты': i18n.categories.bicyclesScooters[locale],
    'Товары для кормления': i18n.categories.feedingProducts[locale],
    'Автомобильные кресла': i18n.categories.carSeats[locale],
    Игрушки: i18n.categories.toys[locale],
    'Товары для купания': i18n.categories.bathingProducts[locale],
    'Товары для школы': i18n.categories.schoolSupplies[locale],
    'Другое Товары для детей и игрушки': i18n.categories.other[locale],
    'Другое Резюме': i18n.categories.other[locale],
    'Другое Фототехника': i18n.categories.other[locale],
    'Другое Игры, приставки и программы': i18n.categories.other[locale],
  };
  return translations[categoryTitle] || categoryTitle;
};

export const translateFigUnderSubCategory = (categoryTitle, locale) => {
  const translations = {
    'Мокасины и лоферы Мужская обувь':
      i18n.categories.loafersAndMoccasins[locale],
    'Детские коляски': i18n.categories.babyStrollers[locale],
    'Детская мебель': i18n.categories.childrensFurniture[locale],
    'Велосипеды и самокаты': i18n.categories.bicyclesScooters[locale],
    'Товары для кормления': i18n.categories.feedingProducts[locale],
    'Автомобильные кресла': i18n.categories.carSeats[locale],
    Игрушки: i18n.categories.toys[locale],
    'Товары для купания': i18n.categories.bathingProducts[locale],
    'Товары для школы': i18n.categories.schoolSupplies[locale],
    'Другое Товары для детей и игрушки': i18n.categories.other[locale],
    'Другое Билеты и путешествия': i18n.categories.other[locale],
    'Другое Резюме': i18n.categories.other[locale],
    'Другое Ремонт и строительство': i18n.categories.other[locale],
    'Другое Женская одежда': i18n.categories.other[locale],
    'Другое Женская обувь': i18n.categories.other[locale],
    'Другое Мужская одежда': i18n.categories.other[locale],
    'Другое Мужская обувь': i18n.categories.other[locale],
    'Другое Ремонт и строительство': i18n.categories.other[locale],
    'Другое Фототехника': i18n.categories.other[locale],
    'Другое Игры, приставки и программы': i18n.categories.other[locale],
    Рыбалка: i18n.categories.fishing[locale],
    Резюме: i18n.categories.Summary[locale],
    Вакансии: i18n.categories.Vacancies[locale],
    Стройматериалы: i18n.categories.BuildingMaterials[locale],
    Инструменты: i18n.categories.Tools[locale],
    'Сантехника, водоснабжение и сауна':
      i18n.categories.PlumbingWaterSupplySauna[locale],
    Двери: i18n.categories.Doors[locale],
    'Садовая техника': i18n.categories.GardenEquipment[locale],
    'Мужская одежда': i18n.categories.mensClothing[locale],
    'Женская одежда': i18n.categories.womensClothing[locale],
    'Мужская обувь': i18n.categories.mensShoes[locale],
    'Женская обувь': i18n.categories.womensShoes[locale],
    Транспорт: i18n.categories.transportRental[locale],
    Недвижимость: i18n.categories.propertiesForRent[locale],
    'Личные вещи': i18n.categories.personalBelongings[locale],
    'Для дома': i18n.categories.forTheHome[locale],
    'Запчасти и аксессуары': i18n.categories.sparePartsAccessories[locale],
    Электроника: i18n.categories.electronics[locale],
    'Хобби и отдых': i18n.categories.hobbiesRecreation[locale],
    Животные: i18n.categories.animals[locale],
    'Бизнес и оборудование': i18n.categories.businessEquipment[locale],
    Работа: i18n.categories.job[locale],
    Услуги: i18n.categories.services2[locale],
    Автомобили: i18n.categories.cars[locale],
    'Мотоциклы и мототехника': i18n.categories.motorcycles[locale],
    Мопеды: i18n.categories.mopeds[locale],
    Трансферы: i18n.categories.transfer[locale],
    'Грузовики и спецтехника': i18n.categories.trucks[locale],
    'Воздушный транспорт': i18n.categories.airtransport[locale],
    'Водный транспорт': i18n.categories.Watertransport[locale],
    'Детская одежда и обувь': i18n.categories.childrensClothingShoes[locale],
    'Товары для детей и игрушки': i18n.categories.childrensProductsToys[locale],
    'Красота и здоровье': i18n.categories.beautyAndHealth[locale],
    'Красота и здоровье Красота и здоровье':
      i18n.categories.beautyAndHealth[locale],
    'Бытовая техника': i18n.categories.householdAppliances[locale],
    'Ремонт и строительство': i18n.categories.repairAndFinishing[locale],
    'Мебель и интерьер': i18n.categories.furnitureAndInterior[locale],
    'Продукты питания': i18n.categories.foodProducts[locale],
    Растения: i18n.categories.plants[locale],
    'Посуда и товары для кухни': i18n.categories.tablewareKitchenGoods[locale],
    Телефоны: i18n.categories.phones[locale],
    Фототехника: i18n.categories.photographicEquipment[locale],
    'Аудио и видео': i18n.categories.audioVideo[locale],
    'Аудио- и видеотехника': i18n.categories.audioVideo[locale],
    'Планшеты и электронные книги': i18n.categories.tabletsEbooks[locale],
    'Товары для компьютера': i18n.categories.computerProducts[locale],
    'Игры, приставки и программы':
      i18n.categories.gamesConsolesPrograms[locale],
    Ноутбуки: i18n.categories.laptops[locale],
    'Настольные компьютеры': i18n.categories.desktopComputers[locale],
    Велосипеды: i18n.categories.bicycles[locale],
    'Книги и журналы': i18n.categories.booksMagazines[locale],
    'Музыкальные инструменты': i18n.categories.musicalInstruments[locale],
    'Спорт и отдых': i18n.categories.sportsRecreation[locale],
    Животные: i18n.categories.animals[locale],
    Собаки: i18n.categories.dogs[locale],
    Кошки: i18n.categories.cats[locale],
    Птицы: i18n.categories.birds[locale],
    Аквариум: i18n.categories.aquarium[locale],
    'Другие животные': i18n.categories.otherAnimals[locale],
    'Готовый бизнес': i18n.categories.readyMadeBusiness[locale],
    'Оборудование для бизнеса':
      i18n.categories.businessEquipmentForBusiness[locale],
    Работа: i18n.categories.job[locale],
    'Ищу работу': i18n.categories.lookingForAJob[locale],
    Другое: i18n.categories.other[locale],
    Строительство: i18n.categories.building[locale],
    'Обучение и курсы': i18n.categories.trainingCourses[locale],
    'Бытовые услуги': i18n.categories.householdServices[locale],
    Грузоперевозки: i18n.categories.cargoTransportation[locale],
    'Мастер на час': i18n.categories.handyman[locale],
    'Сад, благоустройство': i18n.categories.gardenLandscaping[locale],
    'Деловые услуги': i18n.categories.businessServices[locale],
    'Праздники, мероприятия': i18n.categories.holidaysEvents[locale],
    'Охрана, безопасность': i18n.categories.safetySecurity[locale],
    'Снять посуточно': i18n.categories.rent[locale],
    'Снять долгосрочно': i18n.categories.long[locale],
    'Купить жильё': i18n.categories.buy[locale],
    Запчасти: i18n.categories.autoParts[locale],
    Инструменты: i18n.categories.tools[locale],
    'Шины, диски и колёса': i18n.categories.tiresWheels[locale],
    'Аудио и видео': i18n.categories.audioVideo[locale],
    'Аудио- и видеотехника': i18n.categories.audioVideo[locale],
    Прицепы: i18n.categories.trailers[locale],
    Аксессуары: i18n.categories.accessories[locale],
    ' Аксессуары Запчасти и аксессуары': i18n.categories.accessories[locale],
    'Масла и автохимия': i18n.categories.oilsChemicals[locale],
    Тюнинг: i18n.categories.tuning[locale],
    'GPS-навигаторы': i18n.categories.gps[locale],
    'Багажники и фаркопы': i18n.categories.racksHitches[locale],
    Экипировка: i18n.categories.gear[locale],
    'Оргтехника и расходники': i18n.categories.supplies[locale],
    'Охота и рыбалка': i18n.categories.huntingFishing[locale],
    Коллекционирование: i18n.categories.collectibles[locale],
    'Товары для животных': i18n.categories.petSupplies[locale],
    'Ищу сотрудника': i18n.categories.employment[locale],
    'Обучение и курсы': i18n.categories.educationCourses[locale],
    'Праздники и мероприятия': i18n.categories.events[locale],
    'Пассажирские перевозки': i18n.categories.passengerTransport[locale],
    'IT, интернет, телеком': i18n.categories.IT[locale],
    'Одежда, обувь, аксессуары': i18n.categories.cloth[locale],
    Одежда: i18n.categories.cloth[locale],
    Обувь: i18n.categories.Shoes123[locale],
    'Кофты и футболки': i18n.categories.sweatersAndTshirts[locale],
    'Пиджаки и костюмы': i18n.categories.jacketsAndSuits[locale],
    'Пиджаки и костюмы Женская одежда': i18n.categories.jacketsAndSuits[locale],
    Джинсы: i18n.categories.jeans[locale],
    'Джинсы Женская одежда': i18n.categories.jeans[locale],
    Брюки: i18n.categories.trousers[locale],
    'Брюки Женская одежда': i18n.categories.trousers[locale],
    Рубашки: i18n.categories.shirts[locale],
    'Спортивные костюмы': i18n.categories.sportSuits[locale],
    Шорты: i18n.categories.shorts[locale],
    'Нижнее белье': i18n.categories.underwear[locale],
    Платья: i18n.categories.dresses[locale],
    Юбки: i18n.categories.skirts[locale],
    'Джемперы, свитеры, кардиганы':
      i18n.categories.jumpersSweatersCardigans[locale],
    'Свадебные платья': i18n.categories.weddingDresses[locale],
    'Рубашки и блузки': i18n.categories.shirtsAndBlouses[locale],
    'Топы и футболки': i18n.categories.topsAndTshirts[locale],
    Купальники: i18n.categories.swimsuits[locale],
    Кроссовки: i18n.categories.sneakers[locale],
    Туфли: i18n.categories.shoes[locale],
    'Туфли Мужская обувь': i18n.categories.shoes[locale],
    Кеды: i18n.categories.plimsolls[locale],
    'Спортивная обувь Мужская обувь': i18n.categories.sportShoes[locale],
    'Спортивная обувь': i18n.categories.sportShoes[locale],
    'Рабочая обувь': i18n.categories.workShoes[locale],
    'Резиновая обувь': i18n.categories.rubberShoes[locale],
    Сандалии: i18n.categories.sandals[locale],
    'Сандалии Мужская обувь': i18n.categories.sandals[locale],
    'Шлепанцы и сланцы': i18n.categories.flipFlopsAndSlides[locale],
    'Домашняя обувь': i18n.categories.homeShoes[locale],
    'Домашняя обувь Мужская обувь': i18n.categories.homeShoes[locale],
    'Слипоны и эспадрильи': i18n.categories.slipOnsAndEspadrilles[locale],
    'Слипоны и эспадрильи Мужская обувь':
      i18n.categories.slipOnsAndEspadrilles[locale],
    'Уход за обувью': i18n.categories.shoeCare[locale],
    'Уход за обувью Мужская обувь': i18n.categories.shoeCare[locale],
    Босоножки: i18n.categories.sandalsForWomen[locale],
    'Батинки и полуботинки': i18n.categories.ankleBootsAndBooties[locale],
    'Кроссовки и кеды': i18n.categories.sneakersAndPlimsolls[locale],
    Балетки: i18n.categories.balletFlats[locale],
    'Сабо и мюли': i18n.categories.clogsAndMules[locale],
    'Головные уборы': i18n.categories.headwear[locale],
    Очки: i18n.categories.glasses[locale],
    'Ремни, пояса, подтяжки': i18n.categories.beltsAndBraces[locale],
    'Ювелирные украшения': i18n.categories.jewelry[locale],
    Часы: i18n.categories.watches[locale],
    'Зонты и дождевики': i18n.categories.umbrellasAndRaincoats[locale],
    'Аксессуары для волос': i18n.categories.hairAccessories[locale],
    'Галстуки и бабочки': i18n.categories.tiesAndBowties[locale],
    'Носки, чулки, колготки': i18n.categories.socksStockingsTights[locale],
    'Грузчики, складские услуги': i18n.categories.Loaders[locale],
    'Билеты и путешествия': i18n.categories.Tickets[locale],
    'Ремонт и обслуживание техники': i18n.categories.Repair[locale],
    'Компьютерная помощь': i18n.categories.Computerhelp[locale],
    'Оборудование и производство': i18n.categories.Equipment[locale],
    'Реклама, полиграфия': i18n.categories.Advertising[locale],
    Уборка: i18n.categories.Cleaning1[locale],
    'Услуги эвакуатора': i18n.categories.Tow[locale],
    'Ремонт и отделка': i18n.categories.decoration[locale],
    'Доставка еды и продуктов': i18n.categories.Delivery1[locale],
    'Фото и видио съемка': i18n.categories.shooting[locale],
    'Няни, сиделки': i18n.categories.Nannies[locale],
    'Уход за животными': i18n.categories.Animalcare[locale],
    'Телевизоры и проекторы': i18n.categories.projectors[locale],
    'Для автотранспорта': i18n.categories.forVehicles[locale],
    'Для мототранспорта': i18n.categories.forMotorVehicles[locale],
    'Мужская одежда': i18n.categories.mensClothing[locale],
    'Женская одежда': i18n.categories.womensClothing[locale],
    'Мужская обувь': i18n.categories.mensShoes[locale],
    'Женская обувь': i18n.categories.womensShoes[locale],
    'Кофты и футболки': i18n.categories.sweatersAndTshirts[locale],
    'Пиджаки и костюмы': i18n.categories.jacketsAndSuits[locale],
    'Пиджаки и костюмы Женская одежда': i18n.categories.jacketsAndSuits[locale],
    Джинсы: i18n.categories.jeans[locale],
    'Джинсы Женская одежда': i18n.categories.jeans[locale],
    Брюки: i18n.categories.trousers[locale],
    'Брюки Женская одежда': i18n.categories.trousers[locale],
    Рубашки: i18n.categories.shirts[locale],
    'Спортивные костюмы': i18n.categories.sportSuits[locale],
    Шорты: i18n.categories.shorts[locale],
    'Нижнее белье': i18n.categories.underwear[locale],
    Другое: i18n.categories.otherClothes[locale],
    Платья: i18n.categories.dresses[locale],
    Юбки: i18n.categories.skirts[locale],
    'Джемперы, свитеры, кардиганы':
      i18n.categories.jumpersSweatersCardigans[locale],
    'Свадебные платья': i18n.categories.weddingDresses[locale],
    'Рубашки и блузки': i18n.categories.shirtsAndBlouses[locale],
    'Топы и футболки': i18n.categories.topsAndTshirts[locale],
    Купальники: i18n.categories.swimsuits[locale],
    Кроссовки: i18n.categories.sneakers[locale],
    Туфли: i18n.categories.shoes[locale],
    'Туфли Мужская обувь': i18n.categories.shoes[locale],
    Кеды: i18n.categories.plimsolls[locale],
    'Спортивная обувь Мужская обувь': i18n.categories.sportShoes[locale],
    'Спортивная обувь': i18n.categories.sportShoes[locale],
    'Рабочая обувь': i18n.categories.workShoes[locale],
    'Резиновая обувь': i18n.categories.rubberShoes[locale],
    Сандалии: i18n.categories.sandals[locale],
    'Сандалии Мужская обувь': i18n.categories.sandals[locale],
    'Шлепанцы и сланцы': i18n.categories.flipFlopsAndSlides[locale],
    'Домашняя обувь': i18n.categories.homeShoes[locale],
    'Домашняя обувь Мужская обувь': i18n.categories.homeShoes[locale],
    'Слипоны и эспадрильи': i18n.categories.slipOnsAndEspadrilles[locale],
    'Слипоны и эспадрильи Мужская обувь':
      i18n.categories.slipOnsAndEspadrilles[locale],
    'Уход за обувью': i18n.categories.shoeCare[locale],
    'Уход за обувью Мужская обувь': i18n.categories.shoeCare[locale],
    Босоножки: i18n.categories.sandalsForWomen[locale],
    'Батинки и полуботинки': i18n.categories.ankleBootsAndBooties[locale],
    'Кроссовки и кеды': i18n.categories.sneakersAndPlimsolls[locale],
    Балетки: i18n.categories.balletFlats[locale],
    'Сабо и мюли': i18n.categories.clogsAndMules[locale],
    'Головные уборы': i18n.categories.headwear[locale],
    Очки: i18n.categories.glasses[locale],
    'Ремни, пояса, подтяжки': i18n.categories.beltsAndBraces[locale],
    'Ювелирные украшения': i18n.categories.jewelry[locale],
    Часы: i18n.categories.watches[locale],
    'Зонты и дождевики': i18n.categories.umbrellasAndRaincoats[locale],
    'Аксессуары для волос': i18n.categories.hairAccessories[locale],
    'Галстуки и бабочки': i18n.categories.tiesAndBowties[locale],
    'Носки, чулки, колготки': i18n.categories.socksStockingsTights[locale],
    'Ремонт квартир и домов под ключ': i18n.categories.renovation[locale],
    Сантехника: i18n.categories.plumbing[locale],
    Электрика: i18n.categories.electrics[locale],
    'Сборка и ремонт мебели': i18n.categories.furnitureAssemblyRepair[locale],
    'Поклейка обоев и малярные работы':
      i18n.categories.wallpaperingPainting[locale],
    'Полы и напольные покрытия': i18n.categories.floorsFloorCoverings[locale],
    'Штукатурные работы': i18n.categories.plasteringWorks[locale],
    'Высотные работы': i18n.categories.highAltitudeWork[locale],
    'Металлоконструкции и кованные изделия':
      i18n.categories.metalStructuresForgedProducts[locale],
    Изоляция: i18n.categories.insulation[locale],
    'Ремонт офиса': i18n.categories.officeRenovation[locale],
    'Строительство домов под ключ':
      i18n.categories.turnkeyHouseConstruction[locale],
    'Кладочные работы': i18n.categories.masonryWork[locale],
    'Кровельные работы': i18n.categories.roofingWorks[locale],
    'Сварочные работы': i18n.categories.weldingWorks[locale],
    'Бетонные работы': i18n.categories.concreteWorks[locale],
    'Фундаментальные работы': i18n.categories.foundationWorks[locale],
    'Снос и демонтаж': i18n.categories.demolitionDismantling[locale],
    'Фасадные работы': i18n.categories.facadeWorks[locale],
    'Проектирование и сметы': i18n.categories.designEstimates[locale],
    'Водоемы и фонтаны': i18n.categories.reservoirsFountains[locale],
    'Дорожное строительство': i18n.categories.roadConstruction[locale],
    'Заборы, ограждения, навесы': i18n.categories.fencesCanopies[locale],
    'Земляные работы': i18n.categories.earthworks[locale],
    'Озеленение, уход за садом и огородом':
      i18n.categories.greeningGardening[locale],
    'Рольставни и ворота': i18n.categories.rollerShuttersGates[locale],
    Компьютеры: i18n.categories.computers[locale],
    Принтеры: i18n.categories.printers[locale],
    'Игровые приставки': i18n.categories.gameConsoles[locale],
    'ОС и программы': i18n.categories.osAndPrograms[locale],
    'Интернет и другие сети': i18n.categories.internetAndOtherNetworks[locale],
    'Бухгалтерия, финансы': i18n.categories.accountingFinance[locale],
    Перевод: i18n.categories.translation[locale],
    Страхование: i18n.categories.insurance[locale],
    'Юридические услуги': i18n.categories.legalServices[locale],
    'Маркетинг, реклама, PR': i18n.categories.marketingAdvertisingPR[locale],
    'Полиграфия, дизайн': i18n.categories.printingDesign[locale],
    'Генеральная уборка': i18n.categories.generalCleaning[locale],
    'Дезинфекция, дезинсекция':
      i18n.categories.disinfectionDisinsection[locale],
    'Мойка окон': i18n.categories.windowCleaning[locale],
    'Простая уборка': i18n.categories.simpleCleaning[locale],
    'Уборка после ремонта': i18n.categories.cleaningAfterRepair[locale],
    'Чистка ковров': i18n.categories.carpetCleaning[locale],
    'Чиста мягкой мебели': i18n.categories.upholsteredFurnitureCleaning[locale],
    'Изготовление ключей': i18n.categories.keyMaking[locale],
    'Пошив и ремонт одежды': i18n.categories.tailoringClothesRepair[locale],
    'Ремонт часов': i18n.categories.watchRepair[locale],
    'Химчистка, стирка': i18n.categories.dryCleaningLaundry[locale],
    'Ювелирные услуги': i18n.categories.jewelryServices[locale],
    Все: i18n.underUnderSubCategory.estate.all[locale],
    Вторичка: i18n.underUnderSubCategory.estate.secondary[locale],
    Новостройка: i18n.underUnderSubCategory.estate.building[locale],
    Вентиляция: i18n.underUnderSubCategory.services.Ventilation[locale],
    // Телевизоры: i18n.underUnderSubCategory.services.Ventilation[locale],
    'Кровати, диваны и кресла':
      i18n.underUnderSubCategory.services.armchairs[locale],

    'Шкафы, комоды и стеллажи':
      i18n.underUnderSubCategory.services.Wardrobes[locale],
    'Столы и стулья': i18n.underUnderSubCategory.services.Tables[locale],
    'Текстиль и ковры': i18n.underUnderSubCategory.services.Textiles[locale],
    Освещение: i18n.underUnderSubCategory.services.CookingLighting[locale],
    'Компьютерные столы и кресла':
      i18n.underUnderSubCategory.services.Computertables[locale],
    'Для кухни': i18n.underUnderSubCategory.services.Forkitchen[locale],
    'Для дома': i18n.underUnderSubCategory.services.Forhome[locale],
    'Климатические оборудование':
      i18n.underUnderSubCategory.services.Climatic[locale],
    'Для индивидуального ухода':
      i18n.underUnderSubCategory.services.individual[locale],
    'Сервировка стола':
      i18n.underUnderSubCategory.services.TableSetting[locale],
    'Приготовление пищи': i18n.underUnderSubCategory.services.Cooking[locale],
    'Хранение продуктов':
      i18n.underUnderSubCategory.services.FoodStorage[locale],
    'Приготовление напитков':
      i18n.underUnderSubCategory.services.BeveragePreparation[locale],
    'Хозяйственные товары':
      i18n.underUnderSubCategory.services.HouseholdGoods[locale],
    'Кухонные товары': i18n.underUnderSubCategory.services.KitchenGoods[locale],
    'Автомобильный бизнес':
      i18n.underUnderSubCategory.jobCategories.Automotive[locale],
    'Административная работа':
      i18n.underUnderSubCategory.jobCategories.Administrative[locale],
    'Без опыта': i18n.underUnderSubCategory.jobCategories.NoExperience[locale],
    'Бухгалтерия, финансы':
      i18n.underUnderSubCategory.jobCategories.AccountingFinance[locale],
    'Домашний персонал':
      i18n.underUnderSubCategory.jobCategories.HouseholdStaff[locale],
    'Искусство, развлечения':
      i18n.underUnderSubCategory.jobCategories.ArtsEntertainment[locale],
    Консультирование:
      i18n.underUnderSubCategory.jobCategories.Consulting[locale],
    'Маркетинг реклама, PR':
      i18n.underUnderSubCategory.jobCategories.MarketingPR[locale],
    'Образование, наука':
      i18n.underUnderSubCategory.jobCategories.EducationScience[locale],
    'Охрана, безопасность':
      i18n.underUnderSubCategory.jobCategories.Security[locale],
    Продажи: i18n.underUnderSubCategory.jobCategories.Sales[locale],
    'Туризм, рестораны':
      i18n.underUnderSubCategory.jobCategories.TourismRestaurants[locale],
    'Управление персоналом':
      i18n.underUnderSubCategory.jobCategories.PersonnelManagement[locale],
    'Фитнес, салоны красоты':
      i18n.underUnderSubCategory.jobCategories.FitnessBeauty[locale],
    Юриспруденция: i18n.underUnderSubCategory.jobCategories.Legal[locale],
    Концерты: i18n.underUnderSubCategory.interests.Concerts[locale],
    Путешествия: i18n.underUnderSubCategory.interests.Travel[locale],

    'Бильярд и боулинг':
      i18n.underUnderSubCategory.interests.BilliardsBowling[locale],
    'Дайвинг и водный спорт':
      i18n.underUnderSubCategory.interests.DivingWaterSports[locale],
    Единоброства: i18n.underUnderSubCategory.interests.MartialArts[locale],
    'Игры с мячом': i18n.underUnderSubCategory.interests.BallGames[locale],
    'Настольные игры': i18n.underUnderSubCategory.interests.BoardGames[locale],
    'Ролики и скейтбординг':
      i18n.underUnderSubCategory.interests.RollerSkatingSkateboarding[locale],
    'Теннис, бадминтон, пинг-понг':
      i18n.underUnderSubCategory.interests.TennisBadmintonPingPong[locale],
    Туризм: i18n.underUnderSubCategory.interests.Tourism[locale],
    'Фитнес и тренажеры':
      i18n.underUnderSubCategory.interests.FitnessExerciseMachines[locale],
    'Спортивное питание':
      i18n.underUnderSubCategory.interests.SportsNutrition[locale],
    'Мобильные телефоны':
      i18n.underUnderSubCategory.interests.MobilePhones[locale],
    'Системные блоки': i18n.underUnderSubCategory.interests.SystemUnits[locale],
    Моноблоки: i18n.underUnderSubCategory.interests.Monoblocks[locale],
    'Оборудование и аксессуары':
      i18n.underUnderSubCategory.interests.EquipmentAccessories[locale],
    Объективы: i18n.underUnderSubCategory.interests.Lenses[locale],
    'Компактные фотоаппараты':
      i18n.underUnderSubCategory.interests.CompactCameras[locale],
    'Пленочные фотоаппараты':
      i18n.underUnderSubCategory.interests.FilmCameras[locale],
    'Зеркальные фотоаппараты':
      i18n.underUnderSubCategory.interests.DSLRCameras[locale],
    'Бинокли и телескопы':
      i18n.underUnderSubCategory.interests.BinocularsTelescopes[locale],
    Планшеты: i18n.underUnderSubCategory.interests.Tablets[locale],
    'Организация праздников и мероприятий': i18n.categories.holidays[locale],
    'Няни, сиделки': i18n.underUnderSubCategory.interests.Nannies[locale],
    'Услуги эвакуатора': i18n.categories.Tow[locale],
  };
  return translations[categoryTitle] || categoryTitle;
};

import React, { useMemo } from 'react';
import s from '@/styles/uiComp/Select.module.scss';
import Options from './Options';

function Select({
  selected,
  onSelect,
  toggleVisibility,
  showOptions,
  options,
  disabled,
  outside,
}) {
  const currentLabel = useMemo(
    () => options.find(({ value }) => value === selected)?.label || '',
    [options, selected],
  );

  return (
    <div className={s.container_select}>
      <div className={s.select_title} onClick={toggleVisibility} ref={outside}>
        <p>{currentLabel}</p>
        <img
          src="/assets/icons/icon_select.svg"
          alt="icon_error"
          className={showOptions ? s.show : s.not_show}
        />
      </div>
      {showOptions && (
        <Options
          onSelect={onSelect}
          selected={selected}
          options={options}
          disabled={disabled}
          outside={outside}
        />
      )}
    </div>
  );
}

export default Select;

import { useState, useEffect } from 'react';
import { last } from 'lodash';
import { api } from '@/modules/shared/api';


function useDetailAdv(slug) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const id = last(last(slug)?.split('_') || []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(
          `/api/announcement/detail/${id}`,
        );
        setData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    if (id) {
      fetchData();
    }
  }, [id]);

  return { data, loading, error };
}

export default useDetailAdv;

import React from 'react';
import s from '@/styles/uiComp/Options.module.scss';

function Options({
  onSelect, selected, options, outside,
}) {
  return (
    <div className={s.container_options} ref={outside}>
      <div className={s.options_list}>
        {options.map(({ value, label }) => (
          <label key={value}>
            <input
              type="radio"
              name="radioGroup"
              value={value}
              defaultChecked={selected === value}
              onChange={() => onSelect(value)}
            />
            {label}
          </label>
        ))}
      </div>
    </div>
  );
}

export default Options;

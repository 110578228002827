import React from 'react';
import LocationSubCate from '@/components/uiComp/catalog/LocationSubCate';
import MoreParametres from '@/components/uiComp/catalog/MoreParametres';
import PriceSlider from '@/components/uiComp/catalog/PriceSlider';
import FilterBtn from '@/components/uiComp/catalog/FilterBtn';
import i18n from '@/lib/i18n';
import { useRouter } from 'next/router';
import { useFilterFormState } from '@/modules/shared/hooks';
import s from '../auto/AutoFilters.module.scss';
import DescriptionSubCate from '../../uiComp/catalog/DescriptionSubCate';
import FilterSubCategoryCloth from '../../uiComp/catalog/FilterSubCategoryCloth';

function AccessoriesFilter({
  category,
  subcategory,
  underSubCategory: undersubcategory,
  getFilteringData,
  defaultValue,
}) {
  const [form] = useFilterFormState([
    'search',
    'district',
    'state',
    'min_price',
    'max_price',
    'city',
  ], defaultValue, getFilteringData);
  const { locale } = useRouter();

  const stateList = [
    i18n.filters.default.Excellent[locale],
    i18n.filters.default.good[locale],
    i18n.filters.default.Satisfactory[locale],
  ];
  const stateTitle = i18n.filters.default.States[locale];

  const handleBtnFiltering = () => {
    getFilteringData(form.query);
  };

  return (
    <section className={s.container}>
      <div className={s.filter_block}>
        <FilterSubCategoryCloth
          category={category}
          subcategory={subcategory}
          undersubcategory={undersubcategory}
        />
        <LocationSubCate
          selectedDistrict={form.value.district}
          onSelectDistrict={form.setter.district}
          onSelectCity={form.setter.city}
          selectedCity={form.value.city}
        />
        <MoreParametres
          title={stateTitle}
          selected={form.value.state}
          options={stateList}
          onSelect={form.setter.state}
        />
        <PriceSlider
          minPrice={form.value.min_price}
          maxPrice={form.value.max_price}
          setMinPrice={form.setter.min_price}
          setMaxPrice={form.setter.max_price}
        />
        <DescriptionSubCate description={form.value.search} setDescription={form.setter.search} />
        <FilterBtn handleBtnFiltering={handleBtnFiltering} />
      </div>
    </section>
  );
}

export default AccessoriesFilter;

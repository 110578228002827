import React from 'react';
import FilterSubCategory from '@/components/uiComp/catalog/FilterSubCategory';
import LocationSubCate from '@/components/uiComp/catalog/LocationSubCate';
import PriceSlider from '@/components/uiComp/catalog/PriceSlider';
import DescriptionSubCate from '@/components/uiComp/catalog/DescriptionSubCate';
import FilterBtn from '@/components/uiComp/catalog/FilterBtn';
import { useFilterFormState } from '@/modules/shared/hooks';
import s from '../auto/AutoFilters.module.scss';

function DynamicService({
  category, subcategory, getFilteringData, defaultValue,
}) {
  const [form] = useFilterFormState([
    'search',
    'district',
    'min_price',
    'max_price',
    'city',
  ], defaultValue, getFilteringData);
  const handleBtnFiltering = () => {
    getFilteringData(form.query);
  };

  return (
    <section className={s.container}>
      <div className={s.filter_block}>
        <FilterSubCategory category={category} subcategory={subcategory} />
        <LocationSubCate
          selectedDistrict={form.value.district}
          onSelectDistrict={form.setter.district}
          onSelectCity={form.setter.city}
          selectedCity={form.value.city}
        />
        <PriceSlider
          minPrice={form.value.min_price}
          maxPrice={form.value.max_price}
          setMinPrice={form.setter.min_price}
          setMaxPrice={form.setter.max_price}
        />
        <DescriptionSubCate description={form.value.search} setDescription={form.setter.search} />
        <FilterBtn handleBtnFiltering={handleBtnFiltering} />
      </div>
    </section>
  );
}

export default DynamicService;

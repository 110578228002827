import React from 'react';
import FilterSubCategory from '@/components/uiComp/catalog/FilterSubCategory';
import LocationSubCate from '@/components/uiComp/catalog/LocationSubCate';
import ToggleButtons from '@/components/uiComp/createProduct/ToggleButtons';
import Colors from '@/components/uiComp/createProduct/Colors';
import MoreParametres from '@/components/uiComp/catalog/MoreParametres';
import PriceSlider from '@/components/uiComp/catalog/PriceSlider';
import DescriptionSubCate from '@/components/uiComp/catalog/DescriptionSubCate';
import FilterBtn from '@/components/uiComp/catalog/FilterBtn';
import CheckboxInputGroup from '@/components/uiComp/createProduct/CheckboxInput';
import i18n from '@/lib/i18n';
import { useRouter } from 'next/router';
import { useFilterFormState } from '@/modules/shared/hooks';
import s from '../moto/MotoFilters.module.scss';

function MopedFilters({
  category, subcategory, getFilteringData, defaultValue,
}) {
  const [filterFormState] = useFilterFormState([
    'color',
    'deposit',
    'rental_type',
    'delivery',
    'phone_mount',
    'search',
    'district',
    'min_price',
    'max_price',
    'city',
  ], defaultValue, getFilteringData);
  const { locale } = useRouter();

  const handleBtnFiltering = () => {
    getFilteringData(filterFormState.query);
  };

  return (
    <section className={s.container}>
      <div className={s.filter_block}>
        <FilterSubCategory category={category} subcategory={subcategory} />
        <LocationSubCate
          selectedDistrict={filterFormState.value.district}
          onSelectDistrict={filterFormState.setter.district}
          onSelectCity={filterFormState.setter.city}
          selectedCity={filterFormState.value.city}
        />
        <MoreParametres
          title={i18n.createAdvFilds.default.rentalType[locale]}
          options={[
            i18n.filters.default.hour[locale],
            i18n.filters.default.Daily[locale],
            i18n.filters.default.Long[locale],
          ]}
          selected={filterFormState.value.rental_type}
          onSelect={filterFormState.setter.rental_type}
        />
        <ToggleButtons
          title={i18n.createAdvFilds.default.depo[locale]}
          selectedStatus={filterFormState.value.deposit}
          onSelectStatus={filterFormState.setter.deposit}
        />
        <Colors selectedColor={filterFormState.value.color} onColorSelect={filterFormState.setter.color} />
        <ToggleButtons
          title={i18n.createAdvFilds.default.delivery[locale]}
          selectedStatus={filterFormState.value.delivery}
          onSelectStatus={filterFormState.setter.delivery}
        />
        <CheckboxInputGroup
          options={[
            {
              label: i18n.createAdvFilds.default.PhoneMount[locale],
              isChecked: filterFormState.value.phone_mount,
              onChange: () => filterFormState.setter.phone_mount(!filterFormState.value.phone_mount),
            },
          ]}
        />
        <PriceSlider
          minPrice={filterFormState.value.min_price}
          maxPrice={filterFormState.value.max_price}
          setMinPrice={filterFormState.setter.min_price}
          setMaxPrice={filterFormState.setter.max_price}
        />
        <DescriptionSubCate description={filterFormState.value.search} setDescription={filterFormState.setter.search} />
        <FilterBtn handleBtnFiltering={handleBtnFiltering} />
      </div>
    </section>
  );
}

export default MopedFilters;

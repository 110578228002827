import React from 'react';
import CreateMoto from '@/components/filters/moto/CreateMoto';
import CreateAuto from '@/components/filters/auto/CreateAuto';
import Transfers from '@/components/filters/auto/Transfers';
import RentEstate from '@/components/filters/estate/RentEstate';
import BuyEstate from '@/components/filters/estate/BuyEstate';
import SearchWork from '@/components/filters/works/SearchWork';
import SearchWorker from '@/components/filters/works/SearchWorker';
import { Telephone } from '@/components/filters/electronics/Telephone';
import Computer from '@/components/filters/electronics/Computer';
import Transportation from '@/components/filters/services/Transportation';
import Clothing from '@/components/filters/things/Clothing';
import Shoes from '@/components/filters/things/Shoes';
import Accessories from '@/components/filters/things/Accessories';
import Watches from '@/components/filters/things/Watches';
import Jewelry from '@/components/filters/things/Jewelry';
import CreateMoped from '../../filters/moped/CreateMoped';
import Toys from '../../filters/things/Toys';

function CategoryComponent({
  currentCategory,
  currentSubCategory,
  currentUnderSubCategory,
  onAutoDataChange,
  onMopedDataChange,
  onMotoDataChange,
  onRentEstateDataChange,
  onClothesDataChange,
  onShoesDataChange,
  onAccessoriesDataChange,
  onWatchesDataChange,
  onJewerlyDataChange,
  onChildClothesDataChange,
  onToysAndBeautyDataChange,
  onByEstateDataChange,
  onTransfersDataChange,
  onSearchWorkDataChange,
  onSearchWorkerDataChange,
  onTransportationDataChange,
  onElectronicDataChange,
  onTelephoneDataChange,
  defaultValue,
}) {
  if (currentCategory && currentSubCategory) {
    if (currentCategory.codename === 'vehicles-rental') {
      switch (currentSubCategory.codename) {
        case 'cars':
          return (
            <CreateAuto
              defaultValue={defaultValue}
              onAutoDataChange={onAutoDataChange}
            />
          );
        case 'motorbikes':
          return (
            <CreateMoped
              defaultValue={defaultValue}
              onMopedDataChange={onMopedDataChange}
            />

          );
        case 'motorcycles':
          return (
            <CreateMoto
              defaultValue={defaultValue}
              onMotoDataChange={onMotoDataChange}
            />
          );
        case 'transfer':
          return (
            <Transfers
              defaultValue={defaultValue}
              onTransfersDataChange={onTransfersDataChange}
            />
          );
        default:
          break;
      }
    }

    if (currentCategory.codename === 'real-estate') {
      switch (currentSubCategory.codename) {
        case 'rent-daily':
          return (
            <RentEstate
              defaultValue={defaultValue}
              onRentEstateDataChange={onRentEstateDataChange}
            />
          );
        case 'rent-longterm':
          return (
            <RentEstate
              defaultValue={defaultValue}
              onRentEstateDataChange={onRentEstateDataChange}
            />
          );
        case 'buy':
          return (
            <BuyEstate
              defaultValue={defaultValue}
              onByEstateDataChange={onByEstateDataChange}
            />
          );
        default:
          break;
      }
    }

    if (currentCategory.codename === 'services') {
      if (currentSubCategory.codename === 'cargo-transportation') {
        return (
          <Transportation
            onTransportationDataChange={onTransportationDataChange}
            defaultValue={defaultValue}
          />
        );
      }
    }

    if (currentCategory.codename === 'job') {
      switch (currentSubCategory.codename) {
        case 'resumes':
          return (
            <SearchWork
              defaultValue={defaultValue}
              onSearchWorkDataChange={onSearchWorkDataChange}
            />
          );
        case 'vacancies':
          return (
            <SearchWorker
              defaultValue={defaultValue}
              onSearchWorkerDataChange={onSearchWorkerDataChange}
            />
          );
        default:
          break;
      }
    }

    if (currentCategory.codename === 'electronics') {
      switch (currentSubCategory.codename) {
        case 'phones':
          return (
            <Telephone
              defaultValue={defaultValue}
              onTelephoneDataChange={onTelephoneDataChange}
            />
          );
        case 'tablets':
        case 'consoles-sotfware':
        case 'photo':
        case 'computer-goods':
        case 'desktops':
        case 'audio-video':
        case 'laptops':
          return (
            <Computer
              defaultValue={defaultValue}
              onElectronicDataChange={onElectronicDataChange}
            />
          );
        default:
          break;
      }
    }

    switch (currentCategory.codename) {
      case 'personal-items':
        switch (currentSubCategory?.codename) {
          case 'men-clothes':
          case 'women-clothes':
            return (
              <Clothing
                defaultValue={defaultValue}
                subCategoryCodename={currentSubCategory?.codename}
                onClothesDataChange={onClothesDataChange}
              />
            );
          case 'men-shoes':
          case 'women-shoes': {
            return (
              <Shoes
                defaultValue={defaultValue}
                onShoesDataChange={onShoesDataChange}
                underSubCategoryCodename={currentUnderSubCategory?.codename}
              />
            );
          }
          case 'accessories':
            if (
              currentUnderSubCategory
              && currentUnderSubCategory?.codename
            ) {
              const underSubCategoryCodename = currentUnderSubCategory?.codename;
              switch (underSubCategoryCodename) {
                case 'headwear':
                case 'glasses':
                case 'belts-suspenders':
                case 'umbrellas-raincoats':
                case 'hair-accessories':
                case 'ties-bowties':
                case 'socks-stockings-tights':
                case 'other':
                  return (
                    <Accessories
                      defaultValue={defaultValue}
                      onAccessoriesDataChange={onAccessoriesDataChange}
                    />
                  );
                case 'watches':
                  return (
                    <Watches
                      defaultValue={defaultValue}
                      onWatchesDataChange={onWatchesDataChange}
                    />
                  );
                case 'jewelry':
                  return (
                    <Jewelry
                      defaultValue={defaultValue}
                      onJewerlyDataChange={onJewerlyDataChange}
                    />
                  );
              }
            }
            break;
          case 'children-clothes':
            return (
              <Clothing
                defaultValue={defaultValue}
                onChildClothesDataChange={onChildClothesDataChange}
              />
            );
          case 'children-goods':
            return (
              <Toys
                defaultValue={defaultValue}
                onToysAndBeautyDataChange={onToysAndBeautyDataChange}
              />
            );
          case 'beauty-health':
            return (
              <Toys
                defaultValue={defaultValue}
                onToysAndBeautyDataChange={onToysAndBeautyDataChange}
              />
            );
        }
        break;
    }
  }
  return null;
}

export default CategoryComponent;

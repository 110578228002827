import React, { useState, useEffect } from 'react';
import i18n from '@/lib/i18n';
import { useRouter } from 'next/router';
import s from '../../catalog/auto/AutoFilters.module.scss';
import Colors from '../../uiComp/createProduct/Colors';
import SelectOptions from '../../uiComp/createProduct/SelectOptions';

function Shoes({ onShoesDataChange, defaultValue, underSubCategoryCodename }) {
  const [color, setColor] = useState('');
  const [state, setState] = useState('');
  const [brand, setBrand] = useState('');
  const { locale } = useRouter();
  const [selectedSize, setSelectedSize] = useState([]);

  useEffect(() => {
    if (!defaultValue) return;

    setColor(defaultValue.color);
    setState(defaultValue.state);
    setBrand(defaultValue.brand);
  }, [defaultValue]);

  useEffect(() => {
    const formData = {
      state,
      brand,
      color,
    };

    onShoesDataChange(formData);
  }, [state, brand, color]);

  const brands = [
    i18n.createAdvFilds.things.Otherbrand[locale],
    'Adidas',
    'Alexander McQueen',
    'Alexander Wang',
    'Anya Hindmarch',
    'ASICS',
    'Balenciaga',
    'Balmain',
    'Birkenstock',
    'Bottega Veneta',
    'Brooks',
    'Burberry',
    'Céline',
    'Chanel',
    'Christian Louboutin',
    'Clarks',
    'Coach',
    'Cole Haan',
    'Converse',
    'Dolce & Gabbana',
    'Dolce Vita',
    'Dr. Martens',
    'Fendi',
    'Fila',
    'Giuseppe Zanotti',
    'Givenchy',
    'Gucci',
    'Hugo Boss',
    'Isabel Marant',
    'Jil Sander',
    'Jimmy Choo',
    'Keds',
    'Kenzo',
    'Lacoste',
    'Lanvin',
    'Loeffler Randall',
    'Loewe',
    'Marni',
    'Marc Jacobs',
    'Max Mara',
    'Michael Kors',
    'Miu Miu',
    'Moncler',
    'Moschino',
    'New Balance',
    'Nike',
    'Off-White',
    'Phillip Lim',
    'Prabal Gurung',
    'Prada',
    'Proenza Schouler',
    'Puma',
    'Rag & Bone',
    'Ralph Lauren',
    'Rebecca Minkoff',
    'Reebok',
    'Roger Vivier',
    'Saint Laurent',
    'Salvatore Ferragamo',
    'Sergio Rossi',
    'Skechers',
    'Sophie Theallet',
    'Stella McCartney',
    'Steve Madden',
    'Stuart Weitzman',
    'Superga',
    'The Row',
    'Thom Browne',
    'Timberland',
    'Tod’s',
    'Tory Burch',
    'UGG',
    'Under Armour',
    'Valentino',
    'Valentino Garavani',
    'Vans',
    'Vera Wang',
    'Versace',
    'Victoria Beckham',
    'Vivienne Westwood',
    'Yeezy',
    'Zara',
  ];
  const manSizes = [
    '40 (7)',
    '40.5 (7.5)',
    '41 (8)',
    '41.5 (8.5)',
    '42 (9)',
    '42.5 (9.5)',
    '43 (10)',
    '43.5 (10.5)',
    '44 (11)',
    '44.5 (11.5)',
    '45 (12)',
    '46 (13)',
    '47 (14)',
  ];
  const womenSizes = [
    '35 (5)',
    '35.5 (5.5)',
    '36 (6)',
    '36.5 (6.5)',
    '37 (7)',
    '37.5 (7.5)',
    '38 (8)',
    '39 (9)',
    '39.5 (9.5)',
    '40/41 (10)',
    '41 (10.5)',
    '41/42 (11)',
    '42 (11.5)',
    '42/43 (12)',
  ];

  return (
    <section className={s.container}>
      <div className={s.filter_block}>
        <SelectOptions
          title={i18n.createAdvFilds.default.state[locale]}
          options={[
            i18n.createAdvFilds.default.Excellent[locale],
            i18n.createAdvFilds.default.Good[locale],
            i18n.createAdvFilds.default.Satisfactory[locale],
          ]}
          selectedOption={state}
          onSelectOption={setState}
        />
        {underSubCategoryCodename === 'men-shoes' && (
          <SelectOptions
            title={i18n.createAdvFilds.things.size[locale]}
            options={manSizes}
            selectedOption={selectedSize}
            onSelectOption={setSelectedSize}
          />
        )}
        {underSubCategoryCodename === 'women-shoes' && (
          <SelectOptions
            title={i18n.createAdvFilds.things.size[locale]}
            options={womenSizes}
            selectedOption={selectedSize}
            onSelectOption={setSelectedSize}
          />
        )}
        <Colors selectedColor={color} onColorSelect={setColor} />
        <SelectOptions
          title={i18n.createAdvFilds.things.brand[locale]}
          options={brands}
          selectedOption={brand}
          onSelectOption={setBrand}
        />
      </div>
    </section>
  );
}

export default Shoes;

import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';
import s from '@/styles/pages/catalog/SubCategory.module.scss';
import Select from '@/components/uiComp/select/Select';
import i18n from '@/lib/i18n';
import useOutsideClick from '@/hooks/shared/useOutsideClick';
import { useRouter } from 'next/router';

const getOptions = (locale) => [
  {
    value: '-created_at',
    label: i18n.sort.newest[locale],
  },
  {
    value: 'created_at',
    label: i18n.sort.oldest[locale],
  },
  {
    value: 'price',
    label: i18n.sort.cheapest[locale],
  },
  {
    value: '-price',
    label: i18n.sort.expensive[locale],
  },
];

export function OrderSelector({ value, onChange: onChangeProps }) {
  const [isVisible, setIsVisible] = useState(false);
  const outsideRef = useRef();
  const insideRef = useRef();
  useOutsideClick(outsideRef, insideRef, () => {
    setIsVisible(false);
  });
  const toggleVisibility = useCallback(() => {
    setIsVisible((current) => !current);
  }, [setIsVisible]);

  const { locale } = useRouter();
  const options = useMemo(() => getOptions(locale), [locale]);
  const onChange = useCallback((value) => {
    setIsVisible(false);
    onChangeProps(value);
  }, [setIsVisible, onChangeProps]);

  return (
    <div className={s.subcategory_title}>
      <p>{i18n.sort.sortby[locale]}</p>
      <div className={s.subcategory_input}>
        <Select
          showOptions={isVisible}
          selected={value}
          onSelect={onChange}
          toggleVisibility={toggleVisibility}
          options={options}
          outside={outsideRef}
        />
      </div>
    </div>
  );
}

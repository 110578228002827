import React from 'react';
import LocationSubCate from '@/components/uiComp/catalog/LocationSubCate';
import PriceSlider from '@/components/uiComp/catalog/PriceSlider';
import DescriptionSubCate from '@/components/uiComp/catalog/DescriptionSubCate';
import FilterBtn from '@/components/uiComp/catalog/FilterBtn';
import ToggleButtons from '@/components/uiComp/createProduct/ToggleButtons';
import { useRouter } from 'next/router';
import i18n from '@/lib/i18n';
import { useFilterFormState } from '@/modules/shared/hooks';
import s from '../auto/AutoFilters.module.scss';
import FilterSubCategoryCloth from '../../uiComp/catalog/FilterSubCategoryCloth';

function Trucking({
  category,
  subcategory,
  underSubCategory: undersubcategory,
  getFilteringData,
  defaultValue,
}) {
  const [form] = useFilterFormState([
    'search',
    'district',
    'min_price',
    'max_price',
    'city',
    'availability_of_loaders',
  ], defaultValue, getFilteringData);
  const { locale } = useRouter();

  const handleBtnFiltering = () => {
    getFilteringData(form.query);
  };

  return (
    <section className={s.container}>
      <div className={s.filter_block}>
        <FilterSubCategoryCloth
          category={category}
          subcategory={subcategory}
          undersubcategory={undersubcategory}
        />
        <LocationSubCate
          selectedDistrict={form.value.district}
          onSelectDistrict={form.setter.district}
          onSelectCity={form.setter.city}
          selectedCity={form.value.city}
        />
        <ToggleButtons
          title={i18n.createAdvFilds.services[locale]}
          selectedStatus={form.value.availability_of_loaders}
          onSelectStatus={form.setter.availability_of_loaders}
        />
        <PriceSlider
          minPrice={form.value.min_price}
          maxPrice={form.value.max_price}
          setMinPrice={form.setter.min_price}
          setMaxPrice={form.setter.max_price}
        />
        <DescriptionSubCate description={form.value.search} setDescription={form.setter.search} />
        <FilterBtn handleBtnFiltering={handleBtnFiltering} />
      </div>
    </section>
  );
}

export default Trucking;

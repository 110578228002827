import SelectOptions from '@/components/uiComp/createProduct/SelectOptions';
import React, { useEffect, useState } from 'react';
import Colors from '@/components/uiComp/createProduct/Colors';
import { useRouter } from 'next/router';
import s from './Electronics.module.scss';
import i18n from '../../../lib/i18n';

export function Telephone({ onTelephoneDataChange, defaultValue }) {
  const [manufacturer, setManufacturer] = useState('');
  const [color, setColor] = useState('');
  const { locale } = useRouter();
  useEffect(() => {
    if (defaultValue) {
      setManufacturer(defaultValue.manufacturer);
      setColor(defaultValue?.color);
    }
  }, [defaultValue]);
  useEffect(() => {
    const formData = {
      manufacturer,
      color,
    };

    onTelephoneDataChange(formData);
  }, [
    manufacturer,
    color,
  ]);
  const telephone = [
    'AGM',
    'Amazon',
    'Alcatel',
    'Apple',
    'Appleton',
    'Armor',
    'ASUS',
    'Black Fox',
    'Black Shark',
    'BlackBerry',
    'Bluboo',
    'CAT',
    'Conquest',
    'Coolpad',
    'Cubot',
    'Cubot',
    'Desire',
    'Digma',
    'Doogee',
    'Doov',
    'ECHO',
    'Elephone',
    'Energizer',
    'Essential',
    'Evolveo',
    'Firefly Mobile',
    'General Mobile',
    'Google',
    'Haier',
    'Homtom',
    'HTC',
    'Honor',
    'Huawei',
    'Impression',
    'Infinix',
    'iNew',
    'Land Rover',
    'Lumigon',
    'LG',
    'Lenovo',
    'LeEco',
    'MaxCom',
    'Maxtron',
    'Maze',
    'Maze',
    'Meizu',
    'Micromax',
    'Micronova',
    'Microsoft',
    'Moto',
    'Motorola',
    'MyPhone',
    'Neffos',
    'Nokia',
    'NUU Mobile',
    'Oppo',
    'Panasonic',
    'Philips',
    'Prestigio',
    'QMobile',
    'Realme',
    'Redmi',
    'Samsung',
    'Sharp',
    'Smartisan',
    'Sony',
    'Teclast',
    'Timmy',
    'TCL',
    'UHANS',
    'Uhooo',
    'Ulefone',
    'UMIDIGI',
    'Vernee',
    'Verykool',
    'Vivo',
    'Xiaomi',
    'Yezz',
    'Yota',
    'Zeblaze',
    'Zopo',
    'ZTE',
    'другой бренд',
  ];
  return (
    <div className={s.telephone}>
      <SelectOptions
        title={i18n.createAdvFilds.electronics.owner[locale]}
        options={telephone}
        selectedOption={manufacturer}
        onSelectOption={setManufacturer}
      />
      <Colors selectedColor={color} onColorSelect={setColor} />
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import CheckboxInputGroup from '@/components/uiComp/createProduct/CheckboxInput';
import Colors from '@/components/uiComp/createProduct/Colors';
import SelectOptions from '@/components/uiComp/createProduct/SelectOptions';
import ToggleButtons from '@/components/uiComp/createProduct/ToggleButtons';
import TypeRent from '@/components/uiComp/createProduct/TypeRent';
import { useRouter } from 'next/router';
import s from './CreateAuto.module.scss';
import i18n from '../../../lib/i18n';

function CreateAuto({ onAutoDataChange, defaultValue }) {
  const [color, setColor] = useState('');
  const [deposit, setDeposit] = useState(false);
  const [rental_type, setRental_type] = useState('');
  const [delivery, setDelivery] = useState(false);
  const [childSeat, setChildSeat] = useState(false);
  const [phoneCharger, setPhoneCharger] = useState(false);
  const [audioSystem, setAudioSystem] = useState(false);
  const { locale } = useRouter();
  useEffect(() => {
    if (defaultValue) {
      setColor(defaultValue.color);
      setDeposit(defaultValue.deposit);
      setRental_type(defaultValue.rental_type);
      setDelivery(defaultValue.delivery);
      setChildSeat(defaultValue.child_seat);
      setPhoneCharger(defaultValue.phone_charger);
      setAudioSystem(defaultValue.audio_system);
    }
  }, [defaultValue]);

  const getCheckboxData = () => ({
    child_seat: childSeat, // Булево значение
    phone_charger: phoneCharger, // Булево значение
    audio_system: audioSystem, // Булево значение
  });

  // Обновление formData при изменении состояний
  useEffect(() => {
    const formData = {
      color,
      deposit,
      rental_type,
      delivery,
      ...getCheckboxData(),
    };

    onAutoDataChange(formData);
  }, [color, deposit, rental_type, delivery, childSeat, phoneCharger, audioSystem]);

  return (
    <div className={s.container}>
      <div className={s.container_box}>
        <Colors selectedColor={color} onColorSelect={setColor} />
        <ToggleButtons
          title={i18n.createAdvFilds.default.depo[locale]}
          selectedStatus={deposit}
          onSelectStatus={setDeposit}
        />
        <ToggleButtons
          title={i18n.createAdvFilds.default.delivery[locale]}
          selectedStatus={delivery}
          onSelectStatus={setDelivery}
        />
      </div>
      <TypeRent
        title={i18n.createAdvFilds.default.rentalType[locale]}
        options={[
          i18n.filters.default.hour[locale],
          i18n.filters.default.Daily[locale],
          i18n.filters.default.Long[locale],
        ]}
        onSelect={setRental_type}
      />
      <CheckboxInputGroup
        options={[
          {
            label: i18n.createAdvFilds.default.Baby[locale],
            isChecked: childSeat,
            onChange: () => setChildSeat(!childSeat),
          },
          {
            label: i18n.createAdvFilds.default.Charger[locale],
            isChecked: phoneCharger,
            onChange: () => setPhoneCharger(!phoneCharger),
          },
          {
            label: i18n.createAdvFilds.default.Audio[locale],
            isChecked: audioSystem,
            onChange: () => setAudioSystem(!audioSystem),
          },
        ]}
      />
    </div>
  );
}

export default CreateAuto;

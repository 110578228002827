import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import s from '@/styles/pages/UserAdvs.module.scss';
import i18n from '@/lib/i18n';
import SearchTable from '@/components/shared/layout/Inputs/SearchTable';
import { UserAdsTable } from '../user-ads-table';
import { ActionColumn } from './action-column.component';
import { MyInactiveAdsPageProvider } from './my-inactive-ads-page.provider';

export const MyInactiveAds = () => {
  const { locale } = useRouter();
  const [search, setSearch] = useState('');

  return (
    <MyInactiveAdsPageProvider>
      <div className={s.block_personal_advs}>
        <NextSeo title={i18n.user.deactivateAdv[locale]} />
        <div className={s.personal_advs_title}>
          <h2>{i18n.user.deactivateAdv[locale]}</h2>
          <SearchTable value={search} onSearch={setSearch} />
        </div>
        <UserAdsTable
          search={search}
          mode="inactive"
          ActionColumnComponent={ActionColumn}
        />
      </div>
    </MyInactiveAdsPageProvider>
  );
};

import Colors from '@/components/uiComp/createProduct/Colors';
import SelectOptions from '@/components/uiComp/createProduct/SelectOptions';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import s from './Things.module.scss';
import i18n from '../../../lib/i18n';

export default function Watches({ onWatchesDataChange, defaultValue }) {
  const [state, setState] = useState('');
  const [color, setColor] = useState('');
  const [gender, setGender] = useState('');
  const [brand, setBrand] = useState('');
  const { locale } = useRouter();
  useEffect(() => {
    if (defaultValue) {
      setState(defaultValue.state);
      setGender(defaultValue.gender);
      setColor(defaultValue.color);
      setBrand(defaultValue.brand);
    }
  }, [defaultValue]);

  useEffect(() => {
    const formData = {
      state,
      color,
      gender,
      brand,
    };
    onWatchesDataChange(formData);
  }, [state, color, brand, gender]);

  const states = [i18n.createAdvFilds.default.Excellent[locale], i18n.createAdvFilds.default.Good[locale], i18n.createAdvFilds.default.Satisfactory[locale]];
  const watchBrands = [
    i18n.createAdvFilds.things.Otherbrand[locale],
    'A. Lange & Söhne',
    'Alpina',
    'Armani',
    'Audemars Piguet',
    'Balenciaga',
    'Baume & Mercier',
    'Bell & Ross',
    'Bucherer',
    'Bulgari',
    'Bulova',
    'Calvin Klein',
    'Cartier',
    'Casio',
    'Certina',
    'Chanel',
    'Chopard',
    'Christopher Ward',
    'Chronoswiss',
    'Citizen',
    'Coach',
    'D&G',
    'Daniel Wellington',
    'Diesel',
    'Dior',
    'DKNY',
    'Esprit',
    'Fendi',
    'Fortis',
    'Fossil',
    'Frederique Constant',
    'Frédérique Constant',
    'Girard-Perregaux',
    'Glycine',
    'Gucci',
    'Guess',
    'GUESS',
    'Hamilton',
    'Hanhart',
    'Hermes',
    'Hublot',
    'IWC Schaffhausen',
    'Invicta',
    'Jaeger-LeCoultre',
    'Junghans',
    'Kate Spade',
    'Lacoste',
    'Luminox',
    'Louis Vuitton',
    'Marc Jacobs',
    'Maurice de Mauriac',
    'Maurice Lacroix',
    'MeisterSinger',
    'Michael Kors',
    'Michel Herbelin',
    'Mido',
    'Mühle Glashütte',
    'Movado',
    'Nixon',
    'NOMOS Glashütte',
    'Omega',
    'Orient',
    'Panerai',
    'Patek Philippe',
    'Perrelet',
    'Pierre Cardin',
    'Pulsar',
    'Rado',
    'Raymond Weil',
    'Salvatore Ferragamo',
    'Seiko',
    'Sevenfriday',
    'Sinn',
    'Skagen',
    'Swarovski',
    'Swatch',
    'TAG Heuer',
    'The Row',
    'Timex',
    'Tissot',
    'Tommy Hilfiger',
    'Tutima',
    'Ulysse Nardin',
    'Union Glashütte',
    'Vacheron Constantin',
    'Van Cleef & Arpels',
    'Versace',
    'Victorinox Swiss Army',
    'Zenith',
  ];

  const genders = [i18n.createAdvFilds.things.Male[locale], i18n.createAdvFilds.things.Female[locale], i18n.createAdvFilds.things.Unisex[locale]];

  return (
    <div className={s.grid}>
      <SelectOptions
        title={i18n.createAdvFilds.default.state[locale]}
        options={states}
        selectedOption={state}
        onSelectOption={setState}
      />
      <Colors selectedColor={color} onColorSelect={setColor} />
      <SelectOptions
        title={i18n.createAdvFilds.things.brand[locale]}
        options={watchBrands}
        selectedOption={brand}
        onSelectOption={setBrand}
      />
      <SelectOptions
        title={i18n.createAdvFilds.things.gender[locale]}
        options={genders}
        selectedOption={gender}
        onSelectOption={setGender}
      />
    </div>
  );
}

export const categoryPaths = {
  Транспорт: 'transport',
  Работа: 'job',
  Услуги: 'services',
  'Личные вещи': 'personal',
  'Для дома': 'at-home',
  'Запчасти и аксессуары': 'spare-parts',
  Электроника: 'electronics',
  'Хобби и отдых': 'hobbies',
  Животные: 'animals',
  'Бизнес и оборудование': 'business',
  Недвижимость: 'property',
};

export const subCategoryPaths = {
  Автомобили: 'transport/auto',
  'Мотоциклы и мототехника': 'transport/moto',
  Мопеды: 'transport/moped',
  'Грузовики и спецтехника': 'transport/specialvehicle',
  'Водный транспорт': 'transport/water-vehicle',
  'Воздушный транспорт': 'transport/air-vehicle',
  Трансферы: 'transport/transfers',

  Вакансии: 'job/vacancy',
  Резюме: 'job/resume',

  'Аксессуары Аксессуары': 'personal/Accessories',

  Грузоперевозки: 'services/logistic',
  Строительство: 'services/building',
  'Обучение и курсы': 'services/education',
  'Бытовые услуги': 'services/domestic',
  'Мастер на час': 'services/handyworker',
  'Фото и видеосъемка': 'services/photo-video',

  'Деловые услуги': 'services/business-services',
  'Праздники и мероприятия': 'services/holidays',
  'Организация праздников и мероприятий': 'services/holidays',
  'Пассажирские перевозки': 'services/passenger-transportation',
  'Сад, благоустройство': 'services/gardening',
  'IT, интернет, телеком': 'services/communications-services',
  'Охрана, безопасность': 'services/security',
  'Грузчики, складские услуги': 'services/loader',
  'Компьютерная помощь': 'services/computer-support',
  'Реклама, полиграфия': 'services/advertising-printing',
  'Фото и видеосъемка': 'services/photo-video',
  'Услуги эвакуатора': 'services/wrecker',
  'Установка техники': 'services/technician',
  Искусство: 'services/art',
  'Няни, сиделки': 'services/nannies',
  'IT, маркетинг': 'services/marketing',
  'Ремонт и отделка': 'services/repair',
  'Ремонт и обслуживание техники': 'services/repair-tech',
  'Оборудование, производство': 'services/machinery',
  Уборка: 'services/cleaning',
  'Уход за животными': 'services/nimal-car',
  Другое: 'services/other',
  Одежда: 'personal/clothes',
  Обувь: 'personal/shoes',
  'Мужская одежда': 'personal/mens-clothing',
  'Женская одежда': 'personal/womens-clothing',
  'Женская обувь': 'personal/womens-shoes',
  'Мужская обувь': 'personal/mens-shoes',
  'Красота и здоровье Красота и здоровье': 'personal/beauty',
  'Аксессуары Аксессуары': 'personal/Accessories',
  'Детская одежда и обувь': 'personal/childrens-clothing',
  'Товары для детей и игрушки': 'personal/childrens-goods',

  'Красота, здоровье': 'services/Beauty-Health',
  Accessories12: ' Аксессуары Запчасти и аксессуары',
  'Часы и украшения': 'personal/jewelry',
  'Ремонт и строительство': 'at-home/repair',
  'Мебель и интерьер': 'at-home/furniture',
  'Бытовая техника': 'at-home/appliances',
  'Продукты питания': 'at-home/food',
  Растения: 'at-home/plants',
  'Кровати, диваны и кресла': 'furniture/Beds-sofas-and-armchairs',
  'Посуда и товары для кухни': 'at-home/kitchenware',
  Запчасти: 'spare-parts/parts',
  Инструменты: 'spare-parts/tools',
  'Шины, диски и колёса': 'spare-parts/tires',
  Прицепы: 'spare-parts/trailers',
  'Аудио и видео': 'spare-parts/audio-video',
  'Аудио- и видеотехника': 'spare-parts/audio-video',
  Экипировка: 'spare-parts/equipment',
  Аксессуары: 'spare-parts/accessories',
  'Масла и автохимия': 'spare-parts/oils',
  Тюнинг: 'spare-parts/tuning',
  'GPS-навигаторы': 'spare-parts/gps',
  'Багажники и фаркопы': 'spare-parts/luggage',
  ' Аксессуары Запчасти и аксессуары': 'spare-parts/Accessories12',
  Телефоны: 'electronics/phones',
  Фототехника: 'electronics/photo',
  'Аудио и видео': 'electronics/audio-video',
  'Планшеты и электронные книги': 'electronics/tablets-ebooks',
  'Товары для компьютера': 'electronics/computer-goods',
  'Оргтехника и расходники': 'electronics/consumables',
  'Игры, приставки и программы': 'electronics/consoles',
  Ноутбуки: 'electronics/notebooks',
  'Настольные компьютеры': 'electronics/desktops',
  'Билеты и путешествия': 'hobbies/tickets',
  'Спорт и отдых': 'hobbies/sports',
  Велосипеды: 'hobbies/bikes',
  'Книги и журналы': 'hobbies/books',
  Коллекционирование: 'hobbies/collecting',
  'Музыкальные инструменты': 'hobbies/musical-instruments',
  'Охота и рыбалка': 'hobbies/hunting-fishing',
  Собаки: 'animals/dogs',
  Кошки: 'animals/cats',
  Птицы: 'animals/birds',
  Аквариум: 'animals/aquarium',
  'Другие животные': 'animals/other-pets',
  'Товары для животных': 'animals/pet-supplies',
  'Готовый бизнес': 'business/ready-business',
  'Оборудование для бизнеса': 'business/equipment',
  'Купить жильё': 'property/buy',
  'Снять посуточно': 'property/rent-daily',
  'Снять долгосрочно': 'property/rent-longterm',
  'Коммерческая недвижимость': 'property/commercial',
  'Доставка еды и продуктов': 'services/delivery',
  'Фото и видио съемка': 'services/shooting',
  'Телевизоры и проекторы': 'electronics/TVs-and-projectors',
  'Для автотранспорта': 'spare-parts/for-vehicles',
  'Для мототранспорта': 'spare-parts/for-motor-vehicles',
  'Водоемы и фонтаны': 'services/gardening/fountains',
};

export const getRoutesUnderSubCategory = (subCategory) => {
  const underSubCategories = {
    Украшения: 'Accessories/decoration',
    Часы: 'Accessories/watches',
    'Головные уборы': 'Accessories/hats',
    Очки: 'Accessories/glasses',
    'Ремни, пояса, подтяжки': 'Accessories/belts-belts-suspenders',
    Ювелирные: 'Accessories/jewelry',
    'Зонты и дождевики': 'Accessories/umbrellas-and-raincoats',
    'Аксессуары для волос': 'Accessories/hair-accessories',
    'Галстуки и бабочки': 'Accessories/ties-and-bow-ties',
    'Носки, чулки, колготки': 'Accessories/socks-stockings-tights',
    'Слипоны и эспадрильи': 'Accessories/loafers-espadrilles',
    'Другое Аксессуары': 'Accessories/other',
    Все: 'buy/all',
    Вторичка: 'buy/secondary',
    Новостройка: 'buy/newbuilding',
    'По городу': 'logistic/aroundtown',
    'Между городами': 'logistic/betweencities',

    // услуги Ремонт и отделка
    'Ремонт квартир и домов под ключ': 'repair/renovation',
    Сантехника: 'repair/Plumbing',
    Электрика: 'repair/Electrics',
    'Сборка и ремонт мебели': 'repair/furniture',
    'Поклейка обоев и малярные работы': 'repair/painting',
    'Полы и напольные покрытия': 'repair/coatings',
    'Штукатурные работы': 'repair/Plastering',
    'Высотные работы': 'repair/High-rise',
    'Металлоконструкции и кованные изделия': 'repair/forged',
    Изоляция: 'repair/Insulation',
    'Ремонт офиса': 'repair/office',
    Вентиляция: 'repair/Ventilation',
    'Другое Ремонт и строительство': 'repair/Other',
    'Другое Мебель и интерьер': 'furniture/Other',
    'Другое Билеты и путешествия': 'tickets/Other',
    'Строительство домов под ключ': 'building/construction',
    'Кладочные работы': 'building/Masonrywork',
    'Кровельные работы': 'building/Roofing',
    'Сварочные работы': 'building/Weldingwork',
    'Бетонные работы': 'building/Concrete',
    'Фундаментальные работы': 'building/Fundamental',
    'Снос и демонтаж': 'building/dismantling',
    'Фасадные работы': 'building/Facade',
    'Проектирование и сметы': 'building/Design',

    // услуги Сад, благоустройство
    'Водоемы и фонтаны': 'gardening/fountains',
    'Дорожное строительство': 'gardening/construction',
    'Заборы, ограждения, навесы': 'gardening/canopies',
    'Земляные работы': 'gardening/excavation',
    'Озеленение, уход за садом и огородом': 'gardening/Landscaping',
    'Рольставни и ворота': 'gardening/shutters',
    Другое: 'gardening/Other',

    // услуги Ремонт и обслуживание техники
    Телевизоры: 'repair-tech/tvs',
    'Мобильные устройства': 'repair-tech/mobile',
    'Фото, аудио, видеотехника': 'repair-tech/Photo',
    'Кондиционеры, вентиляция': 'repair-tech/conditioners',
    'Стиральные, сушильные машины': 'repair-tech/Washing',
    'Посудомоечные машины': 'repair-tech/Dishwashers',
    'Холодильники, морозильные камеры': 'repair-tech/refrigerators',
    'Варочные панели, духовые шкафы': 'repair-tech/cooktops',
    Водонагреватели: 'repair-tech/Waterheaters',
    Кофемашины: 'repair-tech/Coffeemachines',
    'Швейные машины, оверлоки': 'repair-tech/Sewing',
    Другое: 'repair-tech/Other',

    // услуги Компьютерная помощь
    Компьютеры: 'computer-support/Computers',
    Принтеры: 'computer-support/Printers',
    'Игровые приставки': 'computer-support/game-1111consoles',
    'ОС и программы': 'computer-support/programs',
    'Интернет и другие сети': 'computer-support/Internet',
    Другое: 'computer-support/Other',

    // услуги Оборудование, производство
    // услуги Деловые услуги
    'Бухгалтерия, финансы Деловые услуги': 'business-services/finance',
    Перевод: 'business-services/Translation',
    Страхование: 'business-services/Insurance',
    'Юридические услуги': 'business-services/Legal',
    Другое: 'business-services/Other',

    // услуги IT, маркетинг
    // услуги Реклама, полиграфия
    'Маркетинг, реклама, PR': 'advertising-printing/Marketing',
    'Полиграфия, дизайн': 'advertising-printing/Printing',

    // услуги Уборка
    'Генеральная уборка': 'cleaning/spring-cleaning',
    'Дезинфекция, дезинсекция': 'cleaning/Disinfection',
    'Мойка окон': 'cleaning/Window',
    'Простая уборка': 'cleaning/Easy',
    'Уборка после ремонта': 'cleaning/renovation',
    'Чистка ковров': 'cleaning/Carpet',
    'Чиста мягкой мебели': 'cleaning/upholstered',
    Другое: 'cleaning/Other',

    // услуги Бытовые услуги
    'Изготовление ключей': 'domestic/Production',
    'Пошив и ремонт одежды': 'domestic/Tailoring',
    'Ремонт часов': 'domestic/Watch',
    'Химчистка, стирка': 'domestic/washing',
    'Ювелирные услуги': 'domestic/Jewelry',
    Другое: 'domestic/Other',

    // Недвижимость Купить
    Все: 'buy/all',
    Вторичка: 'buy/Secondary',
    Новостройка: 'buy/New-building',

    // Хобби и отдых Билеты и путешествия
    Концерты: 'tickets/Concerts',
    Путешествия: 'tickets/Trips',
    Спорт: 'tickets/Sport',
    Концерты: 'tickets/Concerts',

    // Хобби и отдых Спорт и отдых
    'Бильярд и боулинг': 'sports/Billiards',
    'Дайвинг и водный спорт': 'sports/Diving',
    Единоброства: 'sports/Unity',
    'Игры с мячом': 'sports/Ball-games',
    'Настольные игры': 'sports/Board',
    'Ролики и скейтбординг': 'sports/Rollerblading',
    'Теннис, бадминтон, пинг-понг': 'sports/Tennis',
    Туризм: 'sports/Tourism',
    'Фитнес и тренажеры': 'sports/Fitness',
    'Спортивное питание': 'sports/nutrition',
    Другое: 'sports/Other',

    // Электроника Телефоны
    'Мобильные телефоны': 'phones/Cell',
    Аксессуары: 'phones/Accessories',

    // Электроника Аудио и видео
    'Телевизоры и проекторы': 'audio-video/projects',
    Наушники: 'audio-video/Headphones',
    'Акустика, колонки, сабвуферы': 'audio-video/Acoustics',
    Другое: 'audio-video/other',

    // Электроника Товары для компьютера
    Комплектующие: 'computer-goods/Accessories-computer',
    Мониторы: 'computer-goods/Monitors',
    'Клавиатуры и мыши': 'computer-goods/Keyboards',
    'Другое Товары для компьютера': 'computer-goods/Other',

    // Электроника Игры, приставки и программы
    'Игровые приставки': 'consoles/Gaming',
    'Игры для приставок': 'consoles/Console-games',
    'Компьютерные игры': 'consoles/Computer',
    'Другое Игры, приставки и программы': 'consoles/Other',

    // Электроника Настольные компьютеры
    'Системные блоки': 'desktops/System',
    Моноблоки: 'desktops/Monoblocks',
    'Другое Настольные компьютеры': 'desktops/Other',

    // Электроника Фототехника
    'Оборудование и аксессуары': 'photo/Equipment',
    Объективы: 'photo/Lenses',
    'Компактные фотоаппараты': 'photo/Compact',
    'Пленочные фотоаппараты': 'photo/Film',
    'Зеркальные фотоаппараты': 'photo/Cameras',
    'Бинокли и телескопы': 'photo/Binoculars',
    'Другое Фототехника': 'photo/Other',

    // Электроника Планшеты и электронные книги
    Планшеты: 'tablets-ebooks/Tablets',
    Аксессуары: 'tablets-ebooks/Accessories',
    'Электронные книги': 'tablets-ebooks/E-books',

    // Работа Резюме summary
    'IT Резюме': 'resume/it',
    'Автомобильный бизнес Резюме': 'resume/Car-business',
    'Административная работа Резюме': 'resume/Administrative-work',
    'Без опыта Резюме': 'resume/Without',
    'Бухгалтерия, финансы Резюме': 'resume/Accounting',
    'Домашний персонал Резюме': 'resume/Household',
    'Искусство, развлечения Резюме': 'resume/entertainment',
    'Консультирование Резюме': 'resume/Consulting',
    'Маркетинг реклама, PR Резюме': 'resume/PR',
    'Образование, наука Резюме': 'resume/Education',
    'Охрана, безопасность Резюме': 'resume/Security',
    'Продажи Резюме': 'resume/Sales',
    'Туризм, рестораны Резюме': 'resume/Tourism',
    'Управление персоналом Резюме': 'resume/Personnel',
    'Фитнес, салоны красоты Резюме': 'resume/Fitness,beautysalons',
    'Юриспруденция Резюме': 'resume/Jurisprudence',
    'Другое Резюме': 'resume/Other',

    // Работа Вакансии vacancies
    IT: 'vacancy/it',
    'Автомобильный бизнес': 'vacancy/Car-business',
    'Административная работа': 'vacancy/Administrative-work',
    'Без опыта': 'vacancy/Without',
    'Бухгалтерия, финансы': 'vacancy/Accounting',
    'Домашний персонал': 'vacancy/Household',
    'Искусство, развлечения': 'vacancy/entertainment',
    Консультирование: 'vacancy/Consulting',
    'Маркетинг реклама, PR': 'vacancy/PR',
    'Образование, наука': 'vacancy/Education',
    'Охрана, безопасность': 'vacancy/Security',
    Продажи: 'vacancy/Sales',
    'Туризм, рестораны': 'vacancy/Tourism',
    'Управление персоналом': 'vacancy/Personnel',
    'Фитнес, салоны красоты': 'vacancy/Fitness,beautysalons',
    Юриспруденция: 'vacancy/Jurisprudence',
    Другое: 'vacancy/Other',

    // Для дома Ремонт и строительство
    Стройматериалы: 'repair/Construction',
    Инструменты: 'repair/Tools',
    'Сантехника, водоснабжение и сауна': 'repair/water-supply',
    Двери: 'repair/Doors',
    'Садовая техника': 'repair/Garden',
    Другое: 'repair/Other',

    // Для дома Мебель и интерьер
    Платья: 'womens-clothing/dresses',
    Босоножки: 'womens-clothing/sandals',
    'Ботинки и полуботинки': 'womens-clothing/boots-and-low-shoes',
    'Кроссовки и кеды': 'womens-clothing/Sneakers-and-Canvas-Shoes',
    Балетки: 'womens-clothing/ballcitieset-shoes',
    'Сабо и мюли': 'womens-clothing/clogs-and-mules',
    'Резиновая обувь': 'womens-clothing/rubber-shoes',
    'Мокасины и лоферы': 'womens-clothing/moccasins-and-loafers',
    Сандалии: 'womens-clothing/sandals',
    'Шлёпанцы и сланцы': 'womens-clothing/flip-flops-and-slates',
    'Домашняя обувь': 'womens-clothing/slippers',
    'Спортивная обувь': 'womens-clothing/sport-shoes',
    'Слипоны и эспадрильи': 'womens-clothing/slip-ons-and-espadrilles',
    'Уход за обувью': 'womens-clothing/shoe-care',
    Другое: 'womens-clothing/Other',
    Юбки: 'womens-clothing/skirts',
    'Джемперы, свитеры, кардиганы':
      'womens-clothing/jumpers-sweaters-cardigans',
    'Свадебные платья': 'womens-clothing/wedding-dresses',
    'Рубашки и блузки': 'womens-clothing/shirts-and-blouses',
    'Топы и футболки': 'womens-clothing/tops-and-t-shirts',
    'Брюки Женская одежда': 'womens-clothing/trousers',
    'Пиджаки и костюмы Женская одежда': 'womens-clothing/jackets-and-suits',
    'Джинсы Женская одежда': 'womens-clothing/jeans',
    Купальники: 'womens-clothing/swimwear',
    'Нижнее бельё Женская одежда': 'womens-clothing/underwear',
    'Кофты и футболки': 'mens-clothing/sweatshirts-and-t-shirts',
    'Пиджаки и костюмы': 'mens-clothing/jackets-and-suits',
    Джинсы: 'mens-clothing/jeans',
    Брюки: 'mens-clothing/trousers',
    Рубашки: 'mens-clothing/shirts',
    'Спортивные костюмы': 'mens-clothing/tracksuits',
    Шорты: 'mens-clothing/shorts',
    'Нижнее бельё': 'mens-clothing/underwear',
    Другое: 'mens-clothing/Other',
    'Другое Мужская обувь': 'mens-shoes/Other',
    'Другое Женская одежда': 'womens-clothing/Other',
    'Другое Женская обувь': 'womens-shoes/Other',
    'Другое Мужская одежда': 'mens-clothing/Other',
    'Другое Бытовая техника': 'appliances/other',
    'Другое  Ремонт и отделка': 'repair/other',
    'Другое Строительство': 'building/other',
    'Другое Сад, благоустройство': 'gardening/other',
    'Другое Красота, здоровье': 'Beauty-Health/other',
    'Другое Компьютерная помощь': 'computer-support/other',
    'Другое Оборудование, производство': 'machinery/other',
    'Другое Обучение и курсы': 'education/other',
    'Другое Деловые услуги': 'business-services/other',
    'Другое IT, интернет, телеком': 'communications-services/other',
    'Другое Уборка': 'cleaning/other',
    'Другое Ремонт и обслуживание техники': 'repair-tech/other',
    'Другое Бытовые услуги': 'domestic/other',
    'Другое Бытовая техника': 'appliances/other',
    Кроссовки: 'mens-shoes/sneakers',
    'Туфли Мужская обувь': 'mens-shoes/shoes',
    Кеды: 'mens-shoes/sneakers',
    'Аренда оборудования': 'machinery/rental',
    'Монтаж и обслуживание оборудования': 'machinery/installation-maintenance',
    Телевизоры: 'repair-tech/tvs',
    'Мобильные устройства': 'repair-tech/mobile-devices',
    'Фото, аудио, видеотехника': 'repair-tech/photo-audio-video',
    'Кондиционеры, вентиляция': 'repair-tech/air-conditioning-ventilation',
    'Стиральные, сушильные машины': 'repair-tech/washing-drying-machines',
    'Посудомоечные машины': 'repair-tech/dishwashers',
    'Холодильники, морозильные камеры': 'repair-tech/refrigerators-freezers',
    'Варочные панели, духовые шкафы': 'repair-tech/cooktops-ovens',
    Водонагреватели: 'repair-tech/water-heaters',
    Кофемашины: 'repair-tech/coffee-machines',
    'Швейные машины, оверлоки': 'repair-tech/sewing-machines-overlocks',
    'Мокасины и лоферы Мужская обувь': 'mens-shoes/moccasins-and-loafers',
    'Спортивная обувь Мужская обувь': 'mens-shoes/sport-shoes',
    'Сандалии Мужская обувь': 'mens-shoes/sandals',
    'Шлёпанцы и сланцы Мужская обувь': 'mens-shoes/flip-flops-and-slates',
    'Домашняя обувь Мужская обувь': 'mens-shoes/slippers',
    'Слипоны и эспадрильи Мужская обувь': 'mens-shoes/slip-ons-and-espadrilles',
    'Уход за обувью Мужская обувь': 'mens-shoes/shoe-care',
    Другое: 'mens-shoes/Other',
    Туфли: 'womens-shoes/shoes',
    Босоножки: 'womens-shoes/sandals',
    'Ботинки и полуботинки': 'womens-shoes/boots-and-low-shoes',
    'Кроссовки и кеды': 'womens-shoes/Sneakers-and-Canvas-Shoes',
    Балетки: 'womens-shoes/ballcitieset-shoes',
    'Сабо и мюли': 'womens-shoes/clogs-and-mules',
    'Резиновая обувь': 'womens-shoes/rubber-shoes',
    'Мокасины и лоферы': 'womens-shoes/moccasins-and-loafers',
    Сандалии: 'womens-shoes/sandals',
    'Шлёпанцы и сланцы': 'womens-shoes/flip-flops-and-slates',
    'Домашняя обувь': 'womens-shoes/slippers',
    'Спортивная обувь': 'womens-shoes/sport-shoes',
    'Слипоны и эспадрильи': 'womens-shoes/slip-ons-and-espadrilles',
    'Уход за обувью': 'womens-shoes/shoe-care',
    Другое: 'womens-shoes/Other',
    'Кровати, диваны и кресла': 'furniture/Beds-sofas-and-armchairs',
    'Для дома': 'appliances/homes',
    'Для кухни': 'appliances/Kitchen',
    'Климатические оборудование': 'appliances/Climate-Control-Equipment',
    'Для индивидуального ухода': 'appliances/Personal-Care',
    'Столы и стулья': 'furniture/Tables-and-Chairs',
    'Текстиль и ковры': 'furniture/Textiles-and-Carpets',
    Освещение: 'furniture/Lighting',
    'Компьютерные столы и кресла': 'furniture/Computer-Desks-and-Chairs',
    'Шкафы, комоды и стеллажи': 'furniture/Wardrobes-chests',
    'Сервировка стола': 'kitchenware/Serving',
    'Приготовление пищи': 'kitchenware/Cooking-food',
    'Хранение продуктов': 'kitchenware/Food-storage',
    'Приготовление напитков': 'kitchenware/Preparing-drinks',
    'Хозяйственные товары': 'kitchenware/Household-Goods',
    'Кухонные товары': 'kitchenware/Kitchenware',
    'Иностранные языки': 'education/Foreign-Languages',
    'Детское развитие, логопеды': 'education/child-development-speech',
    'IT, бизнес': 'education/IT-Business',
    'Дизайн, рисование': 'education/Design-Drawing',
    'Красота, здоровье': 'education/Beauty-Health',
    'Спорт, танцы': 'education/Sports-Dance',
    Вождение: 'education/Driving',
    'Музыка, театр': 'education/Music-Theater',
    'Создание и продвижение сайтов': 'communications-services/wertyui',
    'Создание и продвижение сайтов':
      'communications-services/Website-Creation-and-Promotion',
    'Установка и настройка ПО':
      'communications-services/Software-Installation-and-Configuration',
    'Настройка интернета и сетей':
      'communications-services/Internet-and-Network-Configuration',
    'Реклама, маркетинг, продвижение':
      'communications-services/Advertising-Marketing-Promotion',
    'Программирование, настройка CRM':
      'communications-services/Programming-CRM-Configuration',
    'Создание сайтов и приложений':
      'communications-services/Website-and-App-Creation',
    'Установки и настройка ПО': 'communications-services/Website-PO',
    Другое: 'communications-services/Other',
    Рыбалка: 'hobbies/Fishing',
    'Маникюр, педикюр': 'Beauty-Health/Manicure-Pedicure',
    'Услуги, парикмахера': 'Beauty-Health/Hairdresser-Services',
    'Ресницы, брови': 'Beauty-Health/Eyelashes-Eyebrows',
    Косметология: 'Beauty-Health/Cosmetology',
    Эпиляция: 'Beauty-Health/Epilation',
    'СПА-услуги, массаж': 'Beauty-Health/Spa-Massage',
    'Тату, пирсинг': 'Beauty-Health/Tattoo-Piercing',
    Психология: 'Beauty-Health/Psychology',
    Диетология: 'Beauty-Health/Dietetics',
    'Аренда рабочего места': 'Beauty-Health/Workspace-Rental',
    Другое: 'Beauty-Health/Other',
    'Детские коляски': 'childrens-goods/Strollers',
    'Детская мебель': 'childrens-goods/Childrens-Furniture',
    'Велосипеды и самокаты': 'childrens-goods/Bicycles-and-Scooters',
    'Товары для кормления': 'childrens-goods/Feeding-Goods',
    'Автомобильные кресла': 'childrens-goods/Car-Seats',
    Игрушки: 'childrens-goods/Toys',
    'Товары для купания': 'childrens-goods/Bathing-Goods',
    'Товары для школы': 'childrens-goods/School-Goods',
    'Другое Товары для детей и игрушки': 'childrens-goods/Other',
    'Приборы и аксессуары Красота и здоровье':
      'beauty/Appliances-and-Accessories',
    'Парфюмерия Красота и здоровье': 'beauty/Fragrances',
    'Средства гигиены Красота и здоровье': 'beauty/Hygiene-Products',
    'Косметика Красота и здоровье': 'beauty/Cosmetics',
    'Средства для волос Красота и здоровье': 'beauty/Hair-Care-Products',
    'Медицинские изделия Красота и здоровье': 'beauty/Medical-Products',
    'Другое Красота и здоровье': 'beauty/Other',
    'Для мальчиков': 'childrens-clothing/for-boys',
    'Для девочек': 'childrens-clothing/for-girls',
  };
  return underSubCategories[subCategory] || subCategory;
};

export const getRouteNameForCategory = (russianName) => categoryPaths[russianName] || russianName;

export const getRoutesSubCategory = (subCategory) => {
  const categoryPaths = {
    'Няни, сиделки': 'nannies',
    'Фото и видеосъемка': 'photo-video',
    Автомобили: 'auto',
    'Мотоциклы и мототехника': 'moto',
    Мопеды: 'moped',
    'Грузовики и спецтехника': 'specialvehicle',
    'Водный транспорт': 'water-vehicle',
    'Воздушный транспорт': 'air-vehicle',
    Трансферы: 'transfers',
    'Ищу работу': 'vacancy',
    'Ищу сотрудника': 'offer',
    Грузоперевозки: 'logistic',
    Строительство: 'building',
    'Обучение и курсы': 'education',
    'Бытовые услуги': 'domestic',
    'Мастер на час': 'handyworker',
    'Деловые услуги': 'business-services',
    'Праздники и мероприятия': 'holidays',
    'Пассажирские перевозки': 'passenger-transportation',
    'Сад, благоустройство': 'gardening',
    'IT, интернет, телеком': 'communications-services',
    'Охрана, безопасность': 'security',
    'Грузчики, складские услуги': 'loader',
    'Компьютерная помощь': 'computer-support',
    'Реклама, полиграфия': 'advertising-printing',
    'Фото и видеосъемка': 'photo-video',
    'Услуги эвакуатора': 'wrecker',
    'Установка техники': 'technician',
    Искусство: 'art',
    'Организация праздников и мероприятий': 'holidays',
    'Ремонт и отделка': 'repair',

    'Оборудование и производство': 'equipment',
    Уборка: 'cleaning',
    'Уход за животными': 'nimal-car',
    Другое: 'other',
    'Другое Ремонт и обслуживание техники': 'other',
    Одежда: 'clothes',
    Обувь: 'shoes',

    'Мужская одежда': 'mens-clothing',
    'Женская одежда': 'womens-clothing',
    'Женская обувь': 'womens-shoes',
    'Мужская обувь': 'mens-shoes',
    'Аксессуары Аксессуары': 'Accessories',
    'Детская одежда и обувь': 'childrens-clothing',
    'Товары для детей и игрушки': 'childrens-goods',
    'Красота и здоровье Красота и здоровье': 'beauty',
    'Красота, здоровье': 'Beauty-Health',
    'Часы и украшения': 'jewelry',
    'Ремонт и строительство': 'repair',
    'Мебель и интерьер': 'furniture',
    'Бытовая техника': 'appliances',
    'Продукты питания': 'food',
    Растения: 'plants',
    'Посуда и товары для кухни': 'kitchenware',
    // Запчасти: 'spare-parts/parts',
    Запчасти: 'parts',
    Инструменты: 'tools',
    ' Аксессуары Запчасти и аксессуары': 'Accessories12',

    'Шины, диски и колёса': 'tires',
    Прицепы: 'trailers',
    'Для автотранспорта': 'for-vehicles',
    'Для мототранспорта': 'For-motor-vehicles',
    'Аудио- и видеотехника': 'audio-video',
    Экипировка: 'equipment',
    // Аксессуары: "accessories",

    'Масла и автохимия': 'oils',
    Тюнинг: 'tuning',
    'GPS-навигаторы': 'gps',
    'Багажники и фаркопы': 'luggage',
    Телефоны: 'phones',
    Фототехника: 'photo',
    'Аудио и видео': 'audio-video',
    'Планшеты и электронные книги': 'tablets-ebooks',
    'Товары для компьютера': 'computer-goods',
    'Оргтехника и расходники': 'consumables',
    'Игры, приставки и программы': 'consoles',
    Ноутбуки: 'notebooks',
    'Телевизоры и проекторы': 'TVs-and-projectors',
    'Настольные компьютеры': 'desktops',
    'Билеты и путешествия': 'tickets',
    'Спорт и отдых': 'sports',
    Велосипеды: 'bikes',
    'Книги и журналы': 'books',
    Коллекционирование: 'collecting',
    'Музыкальные инструменты': 'musical-instruments',
    'Охота и рыбалка': 'hunting-fishing',
    Собаки: 'dogs',
    Кошки: 'cats',
    Птицы: 'birds',
    Аквариум: 'aquarium',
    'Другие животные': 'other-pets',
    'Товары для животных': 'pet-supplies',
    'Готовый бизнес': 'ready-business',
    'Оборудование для бизнеса': 'equipment',
    'Купить жильё': 'buy',
    'Снять посуточно': 'rent-daily',
    'Снять долгосрочно': 'rent-longterm',
    'Коммерческая недвижимость': 'commercial',
    'Ремонт и обслуживание техники': 'repair-tech',
    'Оборудование, производство': 'machinery',
    'Доставка еды и продуктов': 'delivery',
    'Фото и видио съемка': 'shooting',
    Резюме: 'resume',
    Вакансии: 'vacancy',
    'Кровати, диваны и кресла': 'at-home/Beds-sofas-and-armchairs',
    'Красота, здоровье': 'Beauty-Health',
  };
  return categoryPaths[subCategory] || subCategory;
};

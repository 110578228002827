import React, {
  ChangeEventHandler, FC, useCallback, useMemo, useState,
} from 'react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { toast } from 'react-toastify';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useConvertHeicToPng } from '@/modules/shared/helpers';
import { ItemLoader } from '@/modules/shared/components';
import clsx from 'clsx';
import styles from './ad-form.module.scss';
import { FormImage } from './ad-form.types';


interface IImagesFieldProps {
  value: FormImage[];
  onChange: (value: FormImage[]) => void;
  error?: { [key: string]: string };
}
export const ImagesField: FC<IImagesFieldProps> = ({ value, onChange, error = null }) => {
  const [loading, setLoading] = useState(false);
  const convertHeicToPng = useConvertHeicToPng();

  const { locale } = useRouter();
  const { t } = useTranslation('forms');

  const removeImage = useCallback(
    (index: number) => {
      const newValue = [...value];
      newValue.splice(index, 1);
      onChange(newValue);
    },
    [value, onChange],
  );

  const handleChooseImg = useCallback<ChangeEventHandler<HTMLInputElement>>(
    async (e) => {
      setLoading(true);
      const maxSizeInBytes = 40 * 1024 * 1024; // 40 MB in bytes
      const minSizeInBytes = 40 * 1024; // 40 KB in bytes

      if (!e.target.files) {
        setLoading(false);
        return;
      }

      const filteredImages = Array.from(e.target.files).filter((file) => {
        if (!file.type.includes('image')) {
          return false;
        }
        if (file.size < minSizeInBytes) {
          toast.error(t('forms:validationErrors.photoBelowMinSize', { minSize: 40 }));
          return false;
        }
        if (file.size > maxSizeInBytes) {
          toast.error(t('forms:validationErrors.photoExceedsMaxSize', { maxSize: 40 }));
          return false;
        }
        return true;
      });

      const newValue = [...value];
      for (const file of filteredImages) {
        if (file.type === 'image/heic') {
          const newFile = await convertHeicToPng(file);
          newValue.push(newFile);
        } else if (!['image/jpeg', 'image/png', 'image/heic', 'image/webp'].includes(file.type)) {
          const extension = file.name.split('.').pop()?.toLowerCase();
          toast.error(`${t('forms:validationErrors.unsupportedImageFormat')} ${extension}`);
        } else {
          newValue.push(file);
        }
      }
      onChange(newValue);
      setLoading(false);
      e.target.value = ''; // обнуляем значение инпута, чтобы можно было загрузить одну и ту же картинку
    },
    [onChange, value, t],
  );

  const previewImagesSrc = useMemo(
    () => value.map((img) => (img instanceof File ? URL.createObjectURL(img) : img.thumbnail || img.image)),
    [value],
  );

  return (
    <div className={clsx({ [styles.error]: error })}>
      <div className={styles.form_choose_img}>
        <div className={styles.choose_img_title}>
          <h4>{t('forms:images.imgField')}</h4>
          <p>{t('forms:images.firstImage')}</p>
        </div>
        <div className={styles.choose_img_field}>
          <label htmlFor="images" className={styles.choose_img}>
            {t('forms:images.addPhoto')}
            <input
              type="file"
              id="images"
              name="images"
              hidden
              multiple
              accept="image/jpeg, image/png, image/heic, image/webp"
              onChange={handleChooseImg}
            />
          </label>
          {previewImagesSrc.map((img, index) => (
            <div className={styles.choosed_images} key={index}>
              <img
                src={img}
                alt={`Preview ${index + 1}`}
                className={index === 0 ? styles.first_image_img : ''}
              />
              <span onClick={() => removeImage(index)}>
                <AiFillCloseCircle
                  style={{ width: '24px', height: '24px', fill: 'gray' }}
                />
              </span>
            </div>
          ))}
          {!loading ? null : (
            <div className={styles.choosed_images}>
              <div className={styles.loader}>
                <ItemLoader isLoading size="50px" />
              </div>
            </div>
          )}
        </div>
        {error && <p style={{ color: 'red' }}>{error[locale as string]}</p>}
      </div>
    </div>
  );
};

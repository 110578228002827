import CheckboxInputGroup from '@/components/uiComp/createProduct/CheckboxInput';
import Colors from '@/components/uiComp/createProduct/Colors';
import SelectOptions from '@/components/uiComp/createProduct/SelectOptions';
import ToggleButtons from '@/components/uiComp/createProduct/ToggleButtons';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Dropdown, useDropdownOnChange, useDropdownValue } from '@/modules/shared/components';
import { useRouter } from 'next/router';
import style from '@/styles/uiComp/State.module.scss';
import useTranslation from 'next-translate/useTranslation';
import s from './CreateMoto.module.scss';
import i18n from '../../../lib/i18n';

function CreateMoto({ onMotoDataChange, defaultValue }) {
  const { locale } = useRouter();
  const { t } = useTranslation('');
  const [color, setColor] = useState('');
  const [deposit, setDeposit] = useState(false);
  const [rental_type, setRental_type] = useState('');
  const [engine_capacity, setEngine_capacity] = useState(null);
  const [delivery, setDelivery] = useState(false);

  const [childSeat, setChildSeat] = useState(false);
  const [phoneCharger, setPhoneCharger] = useState(false);
  const [audioSystem, setAudioSystem] = useState(false);

  const engineCapacityOptions = useMemo(() => ([
    { value: null, label: i18n.select[locale] },
    { value: '125', label: '125' },
    { value: '250', label: '250' },
    { value: '300', label: '300' },
    { value: '450', label: '450' },
    { value: '500', label: '500' },
    { value: '600', label: '600' },
    { value: '1000', label: t('common:lessThan', { number: 1000 }) },
  ]), [locale, t]);

  const handleChangeEngineCapacity = useCallback((value) => {
    setEngine_capacity(value);
  }, [setEngine_capacity]);

  useEffect(() => {
    if (defaultValue) {
      setColor(defaultValue.color);
      setDeposit(defaultValue.deposit);
      setRental_type(defaultValue.rental_type);
      setEngine_capacity(defaultValue.engine_capacity);
      setDelivery(defaultValue.delivery);
      setChildSeat(defaultValue.child_seat);
      setPhoneCharger(defaultValue.phone_charger);
      setAudioSystem(defaultValue.audio_system);
    }
  }, [defaultValue]);
  const getCheckboxData = () => ({
    child_seat: childSeat,
    phone_charger: phoneCharger,
    audio_system: audioSystem,
  });
  useEffect(() => {
    const formData = {
      color,
      deposit,
      rental_type,
      delivery,
      engine_capacity,
      ...getCheckboxData(),
    };

    onMotoDataChange(formData);
  }, [
    color,
    deposit,
    rental_type,
    delivery,
    childSeat,
    phoneCharger,
    audioSystem,
    engine_capacity,
  ]);

  return (
    <div className={s.container}>
      <div className={s.block}>
        <Colors selectedColor={color} onColorSelect={setColor} />
        <ToggleButtons
          title={i18n.createAdvFilds.default.depo[locale]}
          selectedStatus={deposit}
          onSelectStatus={setDeposit}
        />
        <ToggleButtons
          title={i18n.createAdvFilds.default.delivery[locale]}
          selectedStatus={delivery}
          onSelectStatus={setDelivery}
        />
      </div>
      <div className={s.block}>
        <div className={style.block}>
          <div className={style.block_box}>
            <h2>{i18n.createAdvFilds.auto[locale]}</h2>
            <Dropdown
              options={engineCapacityOptions}
              value={useDropdownValue(engine_capacity, engineCapacityOptions)}
              onChange={useDropdownOnChange(handleChangeEngineCapacity)}
            />
          </div>
        </div>
        <SelectOptions
          title={i18n.createAdvFilds.default.rentalType[locale]}
          options={[i18n.createAdvFilds.default.rentalTypeHourly[locale], i18n.createAdvFilds.default.rentalTypeDaily[locale], i18n.createAdvFilds.default.rentalTypeLongTerm[locale]]}
          selectedOption={rental_type}
          onSelectOption={setRental_type}
        />
      </div>
      <CheckboxInputGroup
        options={[
          {
            label: i18n.createAdvFilds.default.Baby[locale],
            isChecked: childSeat,
            onChange: () => setChildSeat(!childSeat),
          },
          {
            label: i18n.createAdvFilds.default.Charger[locale],
            isChecked: phoneCharger,
            onChange: () => setPhoneCharger(!phoneCharger),
          },
          {
            label: i18n.createAdvFilds.default.Audio[locale],
            isChecked: audioSystem,
            onChange: () => setAudioSystem(!audioSystem),
          },
        ]}
      />
    </div>
  );
}
export default CreateMoto;

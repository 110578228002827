import React from 'react';
import LocationSubCate from '@/components/uiComp/catalog/LocationSubCate';
import Colors from '@/components/uiComp/createProduct/Colors';
import PriceSlider from '@/components/uiComp/catalog/PriceSlider';
import DescriptionSubCate from '@/components/uiComp/catalog/DescriptionSubCate';
import FilterBtn from '@/components/uiComp/catalog/FilterBtn';
import { useRouter } from 'next/router';
import i18n from '@/lib/i18n';
import { useFilterFormState } from '@/modules/shared/hooks';
import s from '../auto/AutoFilters.module.scss';
import SelectOptions from '../../uiComp/createProduct/SelectOptions';
import FilterSubCategoryCloth from '../../uiComp/catalog/FilterSubCategoryCloth';

function TelephoneFilter({
  category,
  subcategory,
  underSubCategory: undersubcategory,
  getFilteringData,
  defaultValue,
}) {
  const [form] = useFilterFormState([
    'color',
    'search',
    'min_price',
    'max_price',
    'district',
    'city',
    'manufacturer',
  ], defaultValue, getFilteringData);
  const { locale } = useRouter();

  const handleBtnFiltering = () => {
    getFilteringData(form.query);
  };

  const telephone = [
    'Apple',
    'Samsung',
    'Huawei',
    'Xiaomi',
    'Google',
    'OnePlus',
    'Motorola',
    'LG',
    'Sony',
    'Nokia',
    'Lenovo',
    'ASUS',
    'HTC',
    'BlackBerry',
    'Oppo',
    'Vivo',
    'Realme',
    'Meizu',
    'ZTE',
    'Sharp',
    'Alcatel',
    'TCL',
    'LeEco',
    'Micromax',
    'Panasonic',
    'Philips',
    'Microsoft',
    'Essential',
    'Redmi',
    'Black Shark',
    'Honor',
    'Yota',
    'General Mobile',
    'Energizer',
    'CAT',
    'Amazon',
    'Neffos',
    'Prestigio',
    'Evolveo',
    'MaxCom',
    'AGM',
    'Appleton',
    'Verykool',
    'QMobile',
    'Digma',
    'Evertek',
    'Haier',
    'Micronova',
    'Zopo',
    'Lumigon',
    'Smartisan',
    'Vernee',
    'Yezz',
    'Plum',
    'Firefly Mobile',
    'Vertex',
    'Land Rover',
    'Doogee',
    'Black Fox',
    'Maze',
    'Teclast',
    'Elephone',
    'Bluboo',
    'Armor',
    'Conquest',
    'UHANS',
    'Timmy',
    'Infinix',
    'Impression',
    'Maxtron',
    'Cubot',
    'Energy Sistem',
    'Ulefone',
    'UMIDIGI',
    'Coolpad',
    'Doov',
    'NUU Mobile',
    'Homtom',
    'Desire',
    'Zeblaze',
    'Uhooo',
    'Blu',
    'MyPhone',
    'iNew',
    'Cubot',
    'ECHO',
    'Maze',
    'другой бренд',
  ];

  return (
    <section className={s.container}>
      <div className={s.filter_block}>
        <FilterSubCategoryCloth
          category={category}
          subcategory={subcategory}
          undersubcategory={undersubcategory}
        />
        <LocationSubCate
          selectedDistrict={form.value.district}
          onSelectDistrict={form.setter.district}
          onSelectCity={form.setter.city}
          selectedCity={form.value.city}
        />

        {/* Цвет */}
        <Colors selectedColor={form.value.color} onColorSelect={form.setter.color} />
        {/* Производитель */}
        <SelectOptions
          title={i18n.createAdvFilds.electronics.owner[locale]}
          options={telephone}
          selectedOption={form.value.manufacturer}
          onSelectOption={form.setter.manufacturer}
        />
        {/* Стоимость */}
        <PriceSlider
          minPrice={form.value.min_price}
          maxPrice={form.value.max_price}
          setMinPrice={form.setter.min_price}
          setMaxPrice={form.setter.max_price}
        />

        <DescriptionSubCate description={form.value.search} setDescription={form.setter.search} />
        <FilterBtn handleBtnFiltering={handleBtnFiltering} />
      </div>
    </section>
  );
}

export default TelephoneFilter;

import React, { useCallback, useEffect } from 'react';
import s from '@/styles/uiComp/catalog/DescrSubCate.module.scss';
import { useRouter } from 'next/router';
import i18n from '@/lib/i18n';

function DescriptionSubCate({ description, setDescription }) {
  const { locale } = useRouter();

  const handleChangeDescription = useCallback((event) => {
    const { value } = event.target;
    const newValue = value == '' ? null : value;
    setDescription(newValue);
  }, [setDescription]);

  return (
    <div className={s.description_subcategory}>
      <h4>{i18n.filters.default.desc[locale]}</h4>
      <textarea
        name="description"
        id="descr"
        maxLength={100}
        rows="5"
        cols="40"
        placeholder={i18n.filters.default.writesomething[locale]}
        value={description || ''}
        onChange={handleChangeDescription}
      />
    </div>
  );
}

export default DescriptionSubCate;

import React from 'react';
import s from '@/styles/uiComp/catalog/PriceSlider.module.scss';
import { useRouter } from 'next/router';
import i18n from '@/lib/i18n';
import {
  cleanPriceValue,
  validateInputKeyPress,
} from '@/modules/shared/helpers';

function PriceSlider({
  minPrice = '',
  maxPrice = '',
  setMinPrice,
  setMaxPrice,
}) {
  const { locale } = useRouter();

  const handleChangePrice = (event) => {
    const { name, value } = cleanPriceValue(event);
    if (name === 'min') {
      setMinPrice(value);
    } else if (name === 'max') {
      setMaxPrice(value);
    }
  };

  return (
    <div className={s.price_slider_subcategory}>
      <h4>{i18n.filters.default.price[locale]}</h4>
      <label className={s.form_price}>
        <p>{i18n.filters.default.min[locale]}</p>
        <span>
          <input
            type="number"
            min="0"
            name="min"
            value={minPrice}
            placeholder={i18n.filters.default.writeСost[locale]}
            onChange={handleChangePrice}
            onKeyDown={validateInputKeyPress}
          />
          <strong>THB</strong>
        </span>
      </label>
      <label className={s.form_price}>
        <p>{i18n.filters.default.max[locale]}</p>
        <span>
          <input
            type="number"
            min="0"
            name="max"
            value={maxPrice}
            placeholder={i18n.filters.default.writeСost[locale]}
            onChange={handleChangePrice}
            onKeyDown={validateInputKeyPress}
          />
          <strong>THB</strong>
        </span>
      </label>
    </div>
  );
}

export default PriceSlider;
